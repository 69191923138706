import { Ref, useContext, useEffect, useRef, useState } from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { useQuery } from 'react-query';
import { IMAGE_FILTER, IMAGE_OVERLAY, OverlayBoxType, Query } from '@gather/constants';
import { LocationContext } from '@gather/contexts';
import { IGalaryParams, LocationService } from '@gather/services';
import { IImageGaleryItem, IBBItem } from '@gather/types';
import Raphael, { RaphaelElement, RaphaelPaper, RaphaelPath } from 'raphael';
import { getPath, drawPath, rectToPath, drawCodeText } from '@gather/utils';
import {
    MdFullscreen,
    MdOutlineBorderColor,
    MdOutlineLayers,
    MdOutlineLibraryAdd,
    MdOutlineWbSunny,
    MdZoomIn,
    MdZoomOut,
} from 'react-icons/md';
import { Popover, Skeleton, Slider } from 'antd';
import brokenImage from '@gather/assets/images/broken-image.svg';
import { IDropdownOption, PopoverList } from '@gather/components';
import { CACHE } from '@gather/configs';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';

interface IProps {
    thumbnail?: boolean;
    filters?: IMAGE_FILTER[];
}

export function ImagePreviewer(props: IProps) {
    const {
        thumbnail = false,
        filters = [
            IMAGE_FILTER.brightness,
            IMAGE_FILTER.createOverlay,
            IMAGE_FILTER.editOverlay,
            IMAGE_FILTER.fullScreen,
            IMAGE_FILTER.overlay,
            IMAGE_FILTER.zoom,
        ],
    } = props;

    const sliderRef = useRef<ImageGallery | null>(null);
    const zoomRef = useRef<ReactZoomPanPinchRef | null>(null);

    const [fullScreen, setFullScreen] = useState(false);
    const { locationInfo, selectedProduct, overlayItems, showOverlay, setShowOverlay } = useContext(LocationContext);
    const [images, setImages] = useState<ReactImageGalleryItem[]>([]);
    const [imageLoaded, setImageLoaded] = useState<boolean>(false);
    const [params, setParams] = useState<IGalaryParams>({
        appMissionId: '',
        binId: 0,
        missionId: 0,
        warehouseId: 0,
    });

    const { data = [], isLoading } = useQuery<IImageGaleryItem[]>(
        [Query.gallery, params],
        () => fetchGalleryData(params),
        {
            cacheTime: CACHE.time['5mins'],
            enabled: params.binId ? true : false,
        }
    );

    useEffect(() => {
        const { appMissionId, binId, missionId, warehouseId } = locationInfo || {};
        if (locationInfo) {
            setParams({
                appMissionId: appMissionId || '',
                binId: binId || 0,
                missionId: missionId || 0,
                warehouseId: warehouseId || 0,
            });
        } else {
            setImages([]);
        }
    }, [locationInfo]);

    useEffect(() => {
        function slideToImage(imageId?: number) {
            if (imageId) {
                sliderRef.current?.slideToIndex(getImageIndex(imageId));
            }
        }

        function getImageIndex(imageId: number): number {
            return data.findIndex((image) => image.id === imageId);
        }

        if (selectedProduct) {
            const imageId = selectedProduct.items[0]?.imgId;
            slideToImage(imageId);
        }
    }, [selectedProduct, data]);

    useEffect(() => {
        setImages(
            data.map((_image, i) => ({
                original: _image.url,
                thumbnail: _image.thumbnailUrl,
                description: `${i}`,
            }))
        );
    }, [data]);

    function fetchGalleryData(params: IGalaryParams) {
        return LocationService.fetchGallery(params);
    }
    function onBrightnessChange(brightness: number) {
        brightness = brightness / 25;
        const canvasImages = document.getElementsByClassName(`raphael-canvas-image`);
        for (let i = 0; i < canvasImages.length; i++) {
            const element = canvasImages.item(i);
            if (element) {
                element.setAttribute(
                    'style',
                    'filter:brightness(' +
                        brightness +
                        '); -webkit-filter:brightness(' +
                        brightness +
                        '); -moz-filter:brightness(' +
                        brightness +
                        ')'
                );
            }
        }
    }

    function onError() {
        setImageLoaded(false);
    }

    function onLoad() {
        setImageLoaded(true);
    }

    function renderBrightnessControl() {
        return (
            <div className="brightness-control-popover">
                <Slider onChange={onBrightnessChange} vertical defaultValue={0} />
            </div>
        );
    }

    function renderEditOverlayOptions() {
        function onSelect(option: IDropdownOption) {
            console.log({ option });
        }
        return (
            <PopoverList
                options={[
                    {
                        label: 'Edit Product Bounding Box',
                        value: 1,
                        disabled: true,
                    },
                    {
                        label: 'Edit Code Bounding Box',
                        value: 2,
                        disabled: true,
                    },
                    {
                        label: 'Edit Region of Interest (ROI)',
                        value: 3,
                        disabled: true,
                    },
                ]}
                onSelect={onSelect}
            />
        );
    }

    function renderCreateOverlayOptions() {
        function onSelect(option: IDropdownOption) {
            console.log({ option });
        }
        return (
            <PopoverList
                options={[
                    {
                        label: 'Create Product Bounding Box',
                        value: 1,
                        disabled: true,
                    },
                    {
                        label: 'Create Code Bounding Box',
                        value: 2,
                        disabled: true,
                    },
                ]}
                onSelect={onSelect}
            />
        );
    }

    function renderCustomControls(onClick: React.MouseEventHandler<HTMLElement>, isFullscreen: boolean) {
        return (
            <>
                <div className="image-previewer-custom-controls">
                    {filters.includes(IMAGE_FILTER.editOverlay) && (
                        <Popover content={renderEditOverlayOptions} trigger="click" placement="topRight">
                            <ControlButton active={false} icon={<MdOutlineBorderColor />} />
                        </Popover>
                    )}

                    {filters.includes(IMAGE_FILTER.createOverlay) && (
                        <Popover content={renderCreateOverlayOptions} trigger="click" placement="topRight">
                            <ControlButton active={false} icon={<MdOutlineLibraryAdd />} />
                        </Popover>
                    )}

                    {filters.includes(IMAGE_FILTER.overlay) && (
                        <ControlButton
                            active={showOverlay}
                            onClick={() => setShowOverlay(!showOverlay)}
                            icon={<MdOutlineLayers />}
                            disabled={!imageLoaded}
                        />
                    )}

                    {filters.includes(IMAGE_FILTER.brightness) && (
                        <Popover content={renderBrightnessControl} trigger="click" placement="top">
                            <ControlButton active={false} icon={<MdOutlineWbSunny />} />
                        </Popover>
                    )}

                    {/* {filters.includes(IMAGE_FILTER.zoom) && (
                        <ControlButton active={false} onClick={() => zoomRef.current?.zoomIn()} icon={<MdZoomIn />} />
                    )}

                    {filters.includes(IMAGE_FILTER.zoom) && (
                        <ControlButton active={false} onClick={() => zoomRef.current?.zoomOut()} icon={<MdZoomOut />} />
                    )} */}

                    {filters.includes(IMAGE_FILTER.fullScreen) && (
                        <ControlButton active={isFullscreen} onClick={onClick} icon={<MdFullscreen />} />
                    )}
                </div>
            </>
        );
    }

    function onScreenChange(fullScreen: boolean) {
        setFullScreen(fullScreen);
    }

    function onBeforeSlide(index: number) {
        document.getElementById(`image-preview-thumbnail-${index}`)?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    }

    function renderItem(imageData: ReactImageGalleryItem) {
        return (
            <ImageWithVectorDrawing
                image={imageData}
                rawData={data[+`${imageData.description}`] || {}}
                boxes={overlayItems?.[data[+`${imageData.description}`]?.id] || []}
                overlay={showOverlay && imageLoaded}
                onError={onError}
                onLoad={onLoad}
                zoomRef={(_ref) => (zoomRef.current = _ref)}
            />
        );
    }

    function renderThumbInner(image: ReactImageGalleryItem) {
        return <ImageThumbnail image={image} />;
    }

    function renderImageGallery() {
        if (isLoading) {
            return <ImageSkeleton haveThumbnail={thumbnail} />;
        }
        return (
            <ImageGallery
                ref={(_ref) => (sliderRef.current = _ref)}
                additionalClass={`${fullScreen ? 'full-screen' : ''} ${
                    thumbnail || fullScreen ? 'have-thumbnail' : ''
                }`}
                showBullets
                showFullscreenButton
                renderFullscreenButton={renderCustomControls}
                showThumbnails={thumbnail || fullScreen}
                showIndex
                showNav
                thumbnailPosition="left"
                showPlayButton={false}
                onScreenChange={onScreenChange}
                renderItem={renderItem}
                onBeforeSlide={onBeforeSlide}
                renderThumbInner={renderThumbInner}
                lazyLoad
                disableThumbnailScroll={true}
                items={images}
            />
        );
    }

    return (
        <div className={`image-previewer-section`}>
            {renderImageGallery()}
            <Legends />
        </div>
    );
}

interface IImageThumbnailProps {
    image: ReactImageGalleryItem;
}

function ImageThumbnail(props: IImageThumbnailProps) {
    const { image } = props;
    const { thumbnail, description } = image;
    const [imageLoaded, setImageLoaded] = useState(true);

    useEffect(() => {
        setImageLoaded(true);
    }, [thumbnail]);

    if (!imageLoaded) {
        return <Skeleton.Image className="error-thumbnail" />;
    }

    return (
        <img
            id={`image-preview-thumbnail-${description}`}
            src={thumbnail}
            alt=""
            onError={() => setImageLoaded(false)}
        />
    );
}

function Legends() {
    return (
        <div className="image-preview-legends">
            <div className="lengend-wrapper">
                <span className="legend-icon roi-legend"></span>
                <span className="legend-label">Region of Interest (ROI)</span>
            </div>
            <div className="lengend-wrapper">
                <span className="legend-icon product-legend"></span>
                <span className="legend-label">Product</span>
            </div>
            <div className="lengend-wrapper">
                <span className="legend-icon code-legend"></span>
                <span className="legend-label">Code</span>
            </div>
        </div>
    );
}

interface IImageSkeletonProps {
    haveThumbnail: boolean;
}
function ImageSkeleton(props: IImageSkeletonProps) {
    const { haveThumbnail } = props;
    if (!haveThumbnail) {
        return <Skeleton.Input className="image-preview-thumbnail" active />;
    }
    return (
        <div className="image-skeleton-wrapper">
            <div className="thumbnail-container-scroll">
                <div className="skeleton-thumbnail-wrapper">
                    <Skeleton.Input className="thumbnail-skeleton" active />
                    <Skeleton.Input className="thumbnail-skeleton" active />
                    <Skeleton.Input className="thumbnail-skeleton" active />
                    <Skeleton.Input className="thumbnail-skeleton" active />
                    <Skeleton.Input className="thumbnail-skeleton" active />
                    <Skeleton.Input className="thumbnail-skeleton" active />
                    <Skeleton.Input className="thumbnail-skeleton" active />
                </div>
            </div>

            <Skeleton.Input className="image-preview-thumbnail" active />
        </div>
    );
}

interface IImageWithVectorDrawingProps {
    rawData: IImageGaleryItem;
    image: ReactImageGalleryItem;
    boxes: IBBItem[];
    overlay: boolean;
    onError: () => void;
    onLoad: () => void;
    zoomRef?: Ref<ReactZoomPanPinchRef> | undefined;
}

interface IOverlayNodes {
    ROIs: RaphaelPath<'SVG' | 'VML'>[];
    boxes: RaphaelPath<'SVG' | 'VML'>[];
    barcodeBoxes: RaphaelPath<'SVG' | 'VML'>[];
    labels: RaphaelElement<'SVG' | 'VML', Element | SVGTextElement>[];
}

function ImageWithVectorDrawing(props: IImageWithVectorDrawingProps) {
    const {
        image: { original: src },
        rawData: { width, height, id, rois },
        boxes,
        overlay,
        onError: onImageLoadError,
        onLoad,
        zoomRef,
    } = props;
    const paper = useRef<RaphaelPaper<'SVG' | 'VML'>>();
    const nodes = useRef<IOverlayNodes>({
        ROIs: [],
        boxes: [],
        barcodeBoxes: [],
        labels: [],
    });
    const canvasRef = useRef<HTMLDivElement | null>();

    useEffect(() => {
        function onError(element: Element | SVGImageElement) {
            element.setAttribute('src', brokenImage);
            element.setAttribute('href', brokenImage);
            element.setAttribute('class', `raphael-canvas-image canvas-error-image`);
            paper.current?.canvas.setAttribute('class', 'canvas-with-empty-image');
            onImageLoadError();
        }
        if (canvasRef.current) {
            paper.current?.remove();
            paper.current = Raphael(canvasRef.current, '100%' as any, '100%' as any);
            paper.current.setViewBox(0, 0, width, height, true);
            paper.current.setSize('100%' as any, '100%' as any);
            const image = paper.current.image(src, 0, 0, width, height);
            image.node.setAttribute('class', `raphael-canvas-image`);
            image.node.addEventListener('error', () => onError(image.node));
            image.node.addEventListener('load', (el) => {
                const href = image.node.getAttribute('href');
                if (href && href !== brokenImage) {
                    onLoad();
                }
            });
            renderROI();
            renderBoxesAndCodes();
        }
    }, [width, height, id, canvasRef, src]);

    useEffect(() => {
        if (canvasRef.current) {
            toggleNodeVisibility(overlay);
        }
    }, [overlay, canvasRef]);

    function toggleNodeVisibility(show: boolean) {
        nodes.current.ROIs.forEach((_node) => (show ? _node.show() : _node.hide()));
        nodes.current.boxes.forEach((_node) => (show ? _node.show() : _node.hide()));
        nodes.current.barcodeBoxes.forEach((_node) => (show ? _node.show() : _node.hide()));
        nodes.current.labels.forEach((_node) => (show ? _node.show() : _node.hide()));
    }

    function renderROI() {
        if (paper.current && rois) {
            const path = getPath(rois[0].polygon);
            const ROI = drawPath(paper.current, path, {
                stroke: '#FF6D01',
                'stroke-width': IMAGE_OVERLAY.stroke.width,
                fill: 'none',
            });
            !overlay && ROI.hide();
            nodes.current.ROIs = [ROI];
        }
    }

    function renderBox(rect: [[number, number], [number, number]]) {
        const path = rectToPath(rect, width, height);
        const boxNode =
            paper.current &&
            drawPath(paper.current, path, {
                stroke: IMAGE_OVERLAY.color.box,
                'stroke-width': IMAGE_OVERLAY.stroke.width,
                fill: 'none',
            });
        !overlay && boxNode && boxNode.hide();
        boxNode && (nodes.current.boxes = [...nodes.current.boxes, boxNode]);
    }

    function renderBarCode(code: [[number, number], [number, number]], box: IBBItem) {
        const path = rectToPath(code, width, height);
        const barcodeBox =
            paper.current &&
            drawPath(paper.current, path, {
                stroke: IMAGE_OVERLAY.color.barcode,
                'stroke-width': IMAGE_OVERLAY.stroke.width,
            });
        !overlay && barcodeBox && barcodeBox.hide();
        barcodeBox && (nodes.current.labels = [...nodes.current.barcodeBoxes, barcodeBox]);
        const [codeNode, codeBackgroundNode] = drawCodeText(
            paper.current,
            barcodeBox,
            box.box.label.code,
            IMAGE_OVERLAY.stroke.width
        );
        !overlay && codeNode?.hide();
        codeNode && (nodes.current.labels = [...nodes.current.labels, codeNode]);
        !overlay && codeBackgroundNode?.hide();
        codeBackgroundNode && (nodes.current.labels = [...nodes.current.labels, codeBackgroundNode]);
    }

    function renderBoxesAndCodes() {
        if (paper.current) {
            boxes.forEach((box) => {
                const rect = box?.box?.boundingBox?.rect;
                const code = box?.box?.label?.bbox?.rect;
                if (rect) {
                    renderBox(rect);
                } else if (code) {
                    renderBarCode(code, box);
                }
            });
        }
    }

    return (
        <div className="custom-image-wrapper">
            <TransformWrapper ref={src ? zoomRef : undefined}>
                <TransformComponent>
                    <div
                        className="image-canvas"
                        ref={(_ref) => (canvasRef.current = _ref)}
                        id={`raphael-paper-${id}`}
                    ></div>
                </TransformComponent>
            </TransformWrapper>
        </div>
    );
}
interface IControlButtonProps {
    active: boolean;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    icon: React.ReactNode;
    className?: string;
    disabled?: boolean;
}

function ControlButton(props: IControlButtonProps) {
    const { active, className = '', onClick, icon, disabled } = props;
    return (
        <div
            className={`control-button ${className} ${active ? 'active' : ''} ${disabled ? 'disabled-button' : ''}`}
            onClick={onClick}
        >
            {icon}
        </div>
    );
}

function getOverlayBoxTypeColor(type: OverlayBoxType): string {
    switch (type) {
        case OverlayBoxType.roi:
            return IMAGE_OVERLAY.color.roi;
        case OverlayBoxType.box:
            return IMAGE_OVERLAY.color.box;
        case OverlayBoxType.barcode:
            return IMAGE_OVERLAY.color.barcode;
        default:
            return IMAGE_OVERLAY.color.box;
    }
}

export interface IOverlayDrawing {
    start: {
        x: number;
        y: number;
    };
    end: {
        x: number;
        y: number;
    };
    box: any;
    color: string;
}
