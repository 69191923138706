import { API } from '@gather/constants';
import { AXIOS } from '@gather/middlewares';
import { getSortKey, getSortOrder } from '@gather/utils';

export interface IDroneParams {
    warehouses: number;

    pagenumber: number;
    pagesize: number;
    sortby: string;
    sortdirection: 'ascend' | 'descend';

    startDate?: string;
    endDate?: string;
}

export interface IUpdateExpireResponse {
    code: number;
    message: string;
}

async function fetchDrones(params: IDroneParams) {
    const { pagenumber, pagesize, sortby, sortdirection, startDate = '', endDate = '', warehouses } = params;

    const response = await AXIOS.get(
        API.format(
            API.key.drone.drones,
            warehouses,
            pagenumber,
            pagesize,
            getSortOrder(sortdirection),
            getSortKey(sortby),
            startDate,
            endDate
        )
    );
    return response.data;
}

async function updateExpireTime(expireTime: number) {
    const response = await AXIOS.put(API.format(API.key.drone.expireTime.update, expireTime));
    return response.data;
}

export const DroneService = Object.freeze({
    fetchDrones,
    updateExpireTime,
});
