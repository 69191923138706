import { Table } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Query } from '@gather/constants';
import { IDroneParams, DroneService } from '@gather/services';
import './styles.less';
import { droneColumns, DroneContext } from '@gather/contexts';
import { Pagination } from '@gather/components';
import { TableSkeletonLoader } from '@gather/components';
import { ColumnsType } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { IDroneItem } from '@gather/types';
import { DateTime, filterColumns } from '@gather/utils';
import { CACHE, queryClient } from '@gather/configs';
import { DroneFilter } from './filters';

interface IProps {}

export function DroneList(props: IProps) {
    const { displayOptions, selectedDrone, warehouse, date, setSelectedDrone } = useContext(DroneContext);
    const [params, setParams] = useState<IDroneParams>({
        pagenumber: 1,
        pagesize: 30,
        sortby: 'status',
        sortdirection: 'descend',
        warehouses: warehouse?.value || 0,
    });
    const [tableColumns, setTableColumns] = useState(droneColumns);

    useEffect(() => {
        const _tableColumns: ColumnsType<IDroneItem> = filterColumns(
            droneColumns,
            displayOptions,
            params.sortby,
            params.sortdirection
        ) as ColumnsType<IDroneItem>;

        setTableColumns(_tableColumns);
    }, [displayOptions, params.sortby, params.sortdirection]);

    const { data, isLoading } = useQuery([Query.drones, params], () => fetchData(params), {
        cacheTime: CACHE.time['2hrs'],
        enabled: !!warehouse,
    });

    useEffect(() => {
        setParams({
            ...params,
            startDate: DateTime.format.date(date?.date?.[0]),
            endDate: DateTime.format.date(date?.date?.[1]),
            warehouses: warehouse?.value || 0,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, warehouse]);

    useEffect(() => {
        if (data?.response?.drones && data.response.drones.length > 0 && !selectedDrone) {
            setSelectedDrone(data.response.drones[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.response?.drones]);

    function fetchData(_params: IDroneParams) {
        return DroneService.fetchDrones(_params);
    }

    function onExpireTimeChange(expireTime: number) {
        const key = [Query.drones, params];
        queryClient.setQueryData(key, (oldData: any) => {
            return {
                ...oldData,
                additionalData: `${expireTime}`,
            };
        });
    }

    function onPaginationChange(page: number, pageSize: number) {
        setParams({
            ...params,
            pagenumber: page,
            pagesize: pageSize,
        });
    }

    function onSortOrderChange(sortOrder: any) {
        const _sortOrder: SorterResult<IDroneItem> = sortOrder.length >= 1 ? sortOrder[1] : sortOrder;
        if (_sortOrder) {
            setParams({
                ...params,
                pagenumber: 1,
                sortby: (_sortOrder.field as string) || '',
                sortdirection: _sortOrder.order || 'ascend',
            });
        }
    }

    const drones = (!isLoading && data && data && data.data) || [];
    const totalRecords = data && data && data.totalCount;
    const defaultExpiryTime = +(data && data && data.additionalData) || 0;

    return (
        <div className="table-container-wrapper drone-list">
            <Table
                className="drone-list-table"
                columns={tableColumns}
                dataSource={drones}
                rowKey="droneId"
                rowSelection={{
                    selectedRowKeys: selectedDrone?.droneId ? [selectedDrone?.droneId] : undefined,
                }}
                onRow={(row) => {
                    return {
                        onClick: (event) => {
                            setSelectedDrone(row);
                        },
                    };
                }}
                components={{
                    body: isLoading
                        ? {
                              wrapper: () => <TableSkeletonLoader />,
                          }
                        : undefined,
                }}
                onChange={(_: any, __: any, sorter: any) => {
                    onSortOrderChange(sorter);
                }}
                sortDirections={['ascend', 'descend', 'ascend']}
                pagination={false}
                showSorterTooltip={false}
            />
            <div className="table-footer">
                <DroneFilter.ExpriyTime defaultExpiryTime={defaultExpiryTime} onExpireTimeChange={onExpireTimeChange} />
                <div className="flex-1"></div>
                <div className="pagination-wrapper">
                    <Pagination
                        disabled={isLoading}
                        currentCount={drones.length || 0}
                        current={params.pagenumber}
                        pageSize={params.pagesize}
                        total={totalRecords}
                        onChange={onPaginationChange}
                    />
                </div>
            </div>
        </div>
    );
}
