import { Table } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { excludedProductTypes, Query } from '@gather/constants';
import { IInventoriesParams, LocationService } from '@gather/services';
import './styles.less';
import { productListColumns, LocationContext, IMissionInfo } from '@gather/contexts';
import { Pagination } from '@gather/components';
import { TableSkeletonLoader } from '@gather/components';
import { ILocationProductItem, IOverlayItems } from '@gather/types';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { filterColumns } from '@gather/utils';
import moment from 'moment-timezone';
import { CACHE } from '@gather/configs';

export function ProductListSection() {
    return <ProductsList queryKey={Query.products} />;
}

interface IProps {
    queryKey: Query;
}

export function ProductsList({ queryKey }: IProps) {
    const {
        displayOptions,
        selectedProduct,
        locationInfo,
        params,
        setSelectedProduct,
        setParams,
        setMissionInfo,
        setOverlayItems,
        setLocationInfo,
    } = useContext(LocationContext);

    const [tableColumns, setTableColumns] = useState(productListColumns);
    const [products, setProducts] = useState<ILocationProductItem[]>([]);

    const { data, isLoading } = useQuery([queryKey, params], () => fetchData(params), {
        cacheTime: CACHE.time['5mins'],
        enabled: locationInfo.binId ? true : false,
    });

    useEffect(() => {
        const _tableColumns: ColumnsType<ILocationProductItem> = filterColumns(
            productListColumns,
            displayOptions,
            params.sortby,
            params.sortdirection
        ) as ColumnsType<ILocationProductItem>;

        setTableColumns(_tableColumns);
    }, [displayOptions, params.sortby, params.sortdirection]);

    useEffect(() => {
        let _products: ILocationProductItem[] = data?.response?.products;
        if (_products) {
            const items: IOverlayItems = getOverlayItems(_products);
            setOverlayItems(items);
            _products = getFilteredProducts(_products);
            setProducts(_products);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (products && !selectedProduct) {
            const _selectedProduct: ILocationProductItem = getProductById(locationInfo.itemId) || products[0];
            if (_selectedProduct && !locationInfo.appMissionId) {
                setLocationInfo({
                    ...locationInfo,
                    appMissionId: _selectedProduct.missionAppId,
                    warehouseId: _selectedProduct.warehouseId,
                });
            }
            if (_selectedProduct) {
                setDefaultSelectedProduct(_selectedProduct);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products]);

    useEffect(() => {
        if (products) {
            const _selectedProduct: ILocationProductItem | undefined = getProductById(locationInfo.itemId);
            if (_selectedProduct) {
                setDefaultSelectedProduct(_selectedProduct);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationInfo.itemId]);

    function getProductById(id: string | undefined) {
        return products.find((product: ILocationProductItem) => product.items?.[0]?.itemId === id);
    }

    function setDefaultSelectedProduct(_selectedProduct: ILocationProductItem) {
        setSelectedProduct(_selectedProduct);
        const missionInfo: IMissionInfo = getMissionInfoFromProduct(_selectedProduct);
        setMissionInfo(missionInfo);
    }

    function onSortOrderChange(sortOrder: any) {
        const _sortOrder: SorterResult<ILocationProductItem> = sortOrder.length >= 1 ? sortOrder[1] : sortOrder;
        if (_sortOrder) {
            setParams({
                ...params,
                pagenumber: 1,
                sortby: (_sortOrder.field as string) || '',
                sortdirection: _sortOrder.order || 'ascend',
            });
        }
    }

    function fetchData(_params: IInventoriesParams) {
        return LocationService.fetchProducts(_params);
    }

    function onPaginationChange(page: number, pageSize: number) {
        setParams({
            ...params,
            pagenumber: page,
            pagesize: pageSize,
        });
    }

    const totalRecords = data && data.response && data.response.totalRecords;
    return (
        <div className="product-list-section table-container-wrapper products-list">
            <Table
                className="products-list-table"
                columns={tableColumns}
                dataSource={products}
                rowKey="id"
                rowSelection={{
                    selectedRowKeys: selectedProduct?.id ? [selectedProduct?.id] : undefined,
                }}
                onRow={(row) => {
                    return {
                        onClick: (event) => {
                            setSelectedProduct(row);
                        },
                    };
                }}
                components={{
                    body: isLoading
                        ? {
                              wrapper: () => <TableSkeletonLoader />,
                          }
                        : undefined,
                }}
                onChange={(_: any, __: any, sorter: any) => {
                    onSortOrderChange(sorter);
                }}
                sortDirections={['ascend', 'descend', 'ascend']}
                pagination={false}
                showSorterTooltip={false}
            />
            <div className="table-footer">
                <div className="flex-1"></div>
                <div className="pagination-wrapper">
                    <Pagination
                        disabled={isLoading}
                        currentCount={products.length || 0}
                        current={params.pagenumber}
                        pageSize={params.pagesize}
                        total={totalRecords}
                        onChange={onPaginationChange}
                    />
                </div>
            </div>
        </div>
    );
}

function getOverlayItems(products: ILocationProductItem[]): IOverlayItems {
    const items: IOverlayItems =
        products &&
        products.reduce((acc: IOverlayItems, _product: ILocationProductItem) => {
            if (_product.items) {
                const _items = _product.items;
                _items.forEach((item) => {
                    acc[item.imgId] = acc[item.imgId] ? [...acc[item.imgId], item] : [item];
                });
            }
            return acc;
        }, {});
    return items;
}

function getFilteredProducts(products: ILocationProductItem[]): ILocationProductItem[] {
    return products.filter(
        (_product: ILocationProductItem) =>
            !excludedProductTypes.some((_type) => _type.toLocaleLowerCase() === `${_product.productName}`.toLowerCase())
    );
}

function getMissionInfoFromProduct(product: ILocationProductItem): IMissionInfo {
    const missionInfo: IMissionInfo = {
        warehouseId: product.warehouseId,
        appMissionId: product.missionAppId,
        start: moment(product.createdAt),
        end: moment(product.endDate),
        id: product.missionId,
        name: product.missionName,
    };
    return missionInfo;
}
