import { CACHE } from '@gather/configs';
import { Query } from '@gather/constants';
import { IMMissionsParams, MissionService } from '@gather/services';
import { IMissionItem } from '@gather/types';
import { useQuery } from 'react-query';

export function useMissionsQuery(
    queryKey: Query,
    params: IMMissionsParams
): [missions: IMissionItem[], totalRecords: number, isLoading: boolean] {
    const { data, isLoading } = useQuery([queryKey, params], () => fetchData(params), {
        cacheTime: CACHE.time['2hrs'],
        enabled: !!params.warehouses,
    });

    function fetchData(_params: IMMissionsParams) {
        return MissionService.fetchMissions(_params);
    }

    const missions = (!isLoading && data && data.response && data.response.missions) || [];
    const totalRecords = data && data.response && data.response.totalRecords;

    return [missions, totalRecords, isLoading];
}
