export enum Query {
    missions = 'QUERY/MISSION_DASHBOARD/INVENTORIES',
    missionInventories = 'QUERY/MISSION_DASHBOARD/INVENTORIES',

    inventories = 'QUERY/INVENTORIES/INVENTORIES',

    insights = 'QUERY/INSIGHTS/IFRAMES',

    exceptions = 'QUERY/INVENTORIES/EXCEPTIONS',

    warehouses = 'QUERY/GLOBAL/WAREHOUSES',

    products = 'QUERY/LOCATION_DETAILS/PRODUCTS',
    updateProductCode = 'QUERY/LOCATION_DETAILS/PRODUCTS/PRODUCT_CODE/UPDATE',
    tiHi = 'QUERY/LOCATION_DETAILS/TI_HI',
    locationHistory = 'QUERY/LOCATION_DETAILS/HISTORY',
    gallery = 'QUERY/LOCATION_DETAILS/GALLERY',
    createProduct = 'QUERY/LOCATION_DETAILS/PRODUCT/CREATE',

    profile = 'QUERY/GENERAL_INFO/PROFILE',

    members = 'QUERY/MANAGE_MEMBERS/MEMBERS',

    drones = 'QUERY/DRONES/DRONES',
    updateExpireTime = 'QUERY/DRONES/EXPIRY_TIME/UPDATE',
}
