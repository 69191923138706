const baseURL = process.env.REACT_APP_SERVER_URL;
const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;

const key = Object.freeze({
    auth0: {
        userInfo: `https://${auth0Domain}/userinfo`,
    },
    mission: {
        missions: `${baseURL}/missions?isCurrent={0}&pagenumber={1}&pagesize={2}&sortby={3}&sortdirection={4}&start={5}&end={6}&warehouses={7}`,
        inventories: `${baseURL}/missions/inventory/aggregate?isCurrent={0}&missionId={1}&pagenumber={2}&pagesize={3}&sortby={4}&sortdirection={5}&missionId={1}&warehouses={6}`,
        cumulativeCSV: `${baseURL}/inventoryItems/download/cumulative_csv`,
        warehouseCSV: `${baseURL}/wms/csv/download?file_name={0}`,
        delete: `${baseURL}/missions/{0}`,
    },
    insights: {
        iframes: {
            get: `${baseURL}/dashboard/iframeurl`,
        },
    },
    inventories: {
        list: `${baseURL}/missions/inventory/aggregate?isCurrent={0}&pagenumber={1}&pagesize={2}&sortby={3}&sortdirection={4}&start={5}&end={6}&status={7}&aggregateby={8}&missionId={9}&warehouses={10}&exceptionOnly={11}&binId={12}&search={13}`,
        delete: `${baseURL}/inventoryItems/changeRecord`,
    },
    drone: {
        drones: `${baseURL}/drone?warehouses[]={0}&pagenumber={1}&pagesize={2}&sortdirection={3}&sortby={4}&start={5}&end={6}`,
        expireTime: {
            update: `${baseURL}/drone/expire-time?value={0}`,
        },
    },
    location: {
        products: {
            create: `${baseURL}/inventoryItems`,
            update: {
                code: `${baseURL}/inventoryItems/changeRecord`,
            },
        },
        gallery: `${baseURL}/inventoryItems/inventory_gallery/bin?binId={0}&missionId={1}&AppMissionId={2}&warehouseId={3}`,
        tiHi: `${baseURL}/tihi/mission_bin?binId={0}&missionId={1}&binCode={2}`,
        history: {
            item: `${baseURL}/history`,
            bin: `${baseURL}/history/bin/{0}/mission/{1}`,
        },
    },
    global: {
        warehouse: `${baseURL}/warehouse`,
        tenant: {
            get: `${baseURL}/tenant-domain/getTenant`,
        },
    },
    profile: {
        get: `${baseURL}/profile/user`,
        update: `${baseURL}/user/profile`,
    },
    members: {
        list: `${baseURL}/members/getList`,
    },
});

function format(apiKey: string, ...val: any[]): string {
    let removedKeyCount = 0;
    for (let index = 0; index < val.length; index++) {
        if (val[index] === '' || val[index] === undefined || val[index] === null) {
            let firstIndex = 0;
            if (index === 0) {
                firstIndex = apiKey.indexOf('?');
            } else {
                firstIndex = getNthIndex(apiKey, '&', index - removedKeyCount);
            }
            const lastIndex = apiKey.indexOf(`{${index}}`) + `{${index}}`.length;
            if (firstIndex && lastIndex) {
                const subString = apiKey.substring(firstIndex, lastIndex);
                removedKeyCount += 1;
                apiKey = apiKey.replace(subString, '');
            }
        } else {
            apiKey = apiKey.replace(`{${index}}`, val[index]);
        }
    }
    return apiKey;
}

export const API = Object.freeze({
    key,
    format,
});
function getNthIndex(str: string, subString: string, index: number): number {
    return str.split(subString, index).join(subString).length;
}
