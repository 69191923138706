import { useContext, useEffect, useState } from 'react';
import { IDropdownOption, MultiSelectBox, SelectBox, RangePicker } from '@gather/components';
import { aggregateOptions, inventoriesColumns, InventoryContext, verificationOptions } from '@gather/contexts';
import { MdOutlineSettings, MdOutlineFilterAlt } from 'react-icons/md';
import { Moment } from 'moment-timezone';

function Display() {
    const { displayOptions, setDisplayOptions } = useContext(InventoryContext);
    const [options, setOptions] = useState<IDropdownOption[]>(
        inventoriesColumns.map<IDropdownOption>((_inv, index) => ({
            label: `${_inv.title || ''}`,
            value: _inv.key,
            hidden: _inv.key === 'action-item',
        }))
    );

    useEffect(() => {
        // write logic to implement wms columns options by using -> setOptions
    });

    function onChange(values: IDropdownOption[]) {
        setDisplayOptions(values);
    }

    return (
        <MultiSelectBox
            onChange={onChange}
            options={options}
            value={displayOptions}
            placeholder="Display"
            icon={<MdOutlineSettings />}
        ></MultiSelectBox>
    );
}

function Verification() {
    const { verification, setVerification } = useContext(InventoryContext);

    function onChange(value: IDropdownOption | null) {
        setVerification(value);
    }

    return (
        <SelectBox
            onChange={onChange}
            options={verificationOptions}
            value={verification || null}
            placeholder="Verification"
            isClearable
            icon={<MdOutlineFilterAlt />}
        ></SelectBox>
    );
}

function AggregatedBy() {
    const { aggregateBy, setAggregateBy } = useContext(InventoryContext);

    function onChange(value: IDropdownOption | null) {
        setAggregateBy(value);
    }

    return (
        <SelectBox
            onChange={onChange}
            options={aggregateOptions}
            value={aggregateBy || null}
            placeholder="Aggregated By"
            isClearable={true}
            icon={<MdOutlineFilterAlt />}
        ></SelectBox>
    );
}

function Date() {
    const { date, setDate } = useContext(InventoryContext);

    function onChange(values: { date: Moment[] | null; quickSelect?: number }) {
        setDate(values || undefined);
    }

    return <RangePicker placeholder={'Date'} value={date} onChange={onChange} />;
}

export const InventoryFilter = {
    Display,
    Verification,
    AggregatedBy,
    Date,
};
