import { DatePicker } from 'antd';
import moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { useEffect, useState } from 'react';
import { MdOutlineCalendarToday } from 'react-icons/md';
import './styles.less';
import { ReactComponent as ClearIcon } from '@gather/assets/images/clear.svg';
import { ReactComponent as ArrowDownIcon } from '@gather/assets/images/arrow-down.svg';
const { RangePicker: AntRangePicker } = DatePicker;

interface IProps {
    value?: { date: Moment[] | null; quickSelect?: number };
    onChange: (date: { date: Moment[] | null; quickSelect?: number }) => void;
    placeholder?: string;
}

interface IQuickSelect {
    label: string;
    value: number;
}

const quickSelects: IQuickSelect[] = [
    {
        label: 'Last 30 Days',
        value: 30,
    },
    {
        label: 'Last 10 Days',
        value: 10,
    },
    {
        label: 'Last 3 Days',
        value: 3,
    },
    {
        label: 'Today',
        value: 1,
    },
];

export function RangePicker({ value, onChange, placeholder = '' }: IProps) {
    const [date, setDate] = useState<Moment[] | null>();
    const [showRangePicker, setShowRangePicker] = useState<boolean>(false);
    const [quickSelect, setQuickSelect] = useState<number | undefined>();

    useEffect(() => {
        if (value?.date) {
            if (value?.date?.[0]) value.date[0] = moment(value?.date[0]);
            if (value?.date?.[1]) value.date[1] = moment(value?.date[1]);
            setDate(value?.date);
        } else {
            setDate(null);
        }
        setQuickSelect(value?.quickSelect);
    }, [value]);

    function onValueChange(values: Moment[]) {
        onChange({ date: values, quickSelect: undefined });
        setQuickSelect(undefined);
    }

    function onQuickSelectChange(item: IQuickSelect) {
        const from = item.value === 1 ? moment().startOf('day') : moment().subtract(item.value, 'days');
        const _dateRange = [from, moment()];
        setDate(_dateRange);
        setQuickSelect(item.value);
        onChange({ date: _dateRange, quickSelect: item.value });
    }

    function onClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.currentTarget.focus();
        setShowRangePicker(true);
    }

    function onClear(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.stopPropagation();
        onChange({ date: null });
        setShowRangePicker(false);
    }

    function renderQuickSelectItem(item: IQuickSelect) {
        return (
            <div
                onClick={() => onQuickSelectChange(item)}
                className={`quick-select-item ${quickSelect === item.value ? 'active-item' : ''}`}
            >
                {item.label}
            </div>
        );
    }

    function getQuickSelectByValue(value: number) {
        return quickSelects.find((_item) => _item.value === value);
    }

    function renderQuickSelects() {
        return (
            <div className="quick-select">
                <div className="quick-select-title">Quick Select</div>
                {quickSelects.map(renderQuickSelectItem)}
            </div>
        );
    }

    function renderPannel(pannelNode: React.ReactNode) {
        return (
            <div className="custom-range-picker-pannel">
                <>{pannelNode}</>
                {renderQuickSelects()}
            </div>
        );
    }

    function renderValueLabel() {
        if (quickSelect) return getQuickSelectByValue(quickSelect)?.label;
        if (date && date[0] && date[1] && date[0].format('MM/DD/YYYY') === date[1].format('MM/DD/YYYY')) {
            return date[0].format('MM/DD/YYYY');
        }
        if (date && date[0] && date[1]) {
            return `${date[0].format('MM/DD/YYYY')} - ${date[1].format('MM/DD/YYYY')}`;
        }
        return '';
    }

    return (
        <div
            className={`range-picker-container app-input-container ${date ? 'input-have-value' : ''}`}
            onClick={onClick}
            tabIndex={0}
        >
            <div className="calendar-icon">
                <MdOutlineCalendarToday />
            </div>
            <div className="date-placeholder">{placeholder}</div>
            <div className="date-value">{date && renderValueLabel()}</div>
            <AntRangePicker
                value={date as any}
                panelRender={renderPannel}
                onChange={onValueChange as any}
                className="custom-range-picker"
                placement={'bottomRight'}
                open={showRangePicker}
                onPanelChange={() => setShowRangePicker(false)}
                onOpenChange={(open) => setShowRangePicker(open)}
            />
            {date && (
                <div className="clear-cion" onClick={onClear}>
                    <ClearIcon className="dropdown-icon clear-icon" />
                </div>
            )}
            <div className={`range-dropdown-icon ${showRangePicker ? 'dropdown-open' : ''}`} onClick={onClear}>
                <ArrowDownIcon className="dropdown-icon" />
            </div>
        </div>
    );
}
