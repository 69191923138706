import { Skeleton } from 'antd';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { MdCached, MdFlipToFront, MdLabelOutline, MdOutlineAssignment } from 'react-icons/md';
import { useQuery } from 'react-query';
import { SlideModal } from '@gather/components';
import { CACHE } from '@gather/configs';
import { CHANGE_EVENT, Query, UPDATE_SOURCE } from '@gather/constants';
import { IHistoryParams, LocationService } from '@gather/services';
import { IHistoryItem } from '@gather/types';
import { DateTime } from '@gather/utils';
import './styles.less';

interface ILocationHistroyProps extends IHistoryParams {
    onClose?: () => void;
}

export function LocationHistory(props: ILocationHistroyProps) {
    const { onClose, missionAppId, itemIds } = props;
    const [showModal, setShowModal] = useState(false);

    const { data = [], isLoading } = useQuery([Query.locationHistory, props], () => fetchData(props), {
        cacheTime: CACHE.time['5mins'],
        enabled: showModal && (missionAppId || itemIds) ? true : false,
    });

    useEffect(() => {
        if (missionAppId || itemIds) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [missionAppId, itemIds, onClose]);

    function fetchData(_params: IHistoryParams) {
        return LocationService.fetchLocationHistory(_params);
    }

    function renderContent() {
        if (isLoading) {
            return <LocationHistorySkeleton />;
        }
        return (
            <div className="location-history-modal-content">
                {(!data || data.length <= 0) && <NoRecordsFound />}
                {data.map((historyItem: IHistoryItem) => {
                    const { changeEvent, changeNote, date, productCode, productName, source, userName } = historyItem;
                    return (
                        <HistoryCard
                            code={productCode}
                            productName={productName}
                            date={date}
                            description={changeNote}
                            event={changeEvent}
                            source={source}
                            user={userName}
                        />
                    );
                })}
            </div>
        );
    }

    function renderModal() {
        return (
            <SlideModal
                // prefixActions={[<PrefixActions />]}
                // postfixActions={[<PostfixActions />]}
                show={showModal}
                onClose={() => onClose && onClose()}
                className="location-history-slide-modal"
                title="Location History"
            >
                {renderContent()}
            </SlideModal>
        );
    }
    return (
        <div className="location-history">
            {renderModal()}
        </div>
    );
}

interface IHistoryCardProps {
    code: string;
    productName: string;
    description: string;
    user: string;
    source: string;
    event: string;
    date: Date;
}

function HistoryCard(props: IHistoryCardProps) {
    const { code, productName, description, user, source, event, date } = props;
    function renderStatus() {
        return (
            <div className="history-card-status">
                {getIcon(event)}
                <div className="status-label">{getTitle(event)}</div>
                <div className="flex-1" />
                <div className="history-status-name">{productName}</div>
            </div>
        );
    }
    function renderHeader() {
        return (
            <div className="history-card-header">
                <div className="history-header-code">Product Code: {code}</div>
            </div>
        );
    }
    function renderContent() {
        return <div className="history-card-content">{description}</div>;
    }
    function renderFooter() {
        return (
            <div className="history-card-footer">
                <div className="history-footer-username">By {user}</div>
                <div className="footer-break">-</div>
                <div className="history-footer-source">Via {getSourceName(source)}</div>
                <div className="footer-break">-</div>
                <div className="history-footer-date">
                    <DateTimeCounter date={date} />
                </div>
            </div>
        );
    }
    return (
        <div className="location-history-card">
            {renderStatus()}
            {renderHeader()}
            {renderContent()}
            {renderFooter()}
        </div>
    );
}

interface IUpdateTimeProps {
    date: Date;
}

function DateTimeCounter(props: IUpdateTimeProps) {
    const { date } = props;
    const [dateTime, setDateTime] = useState(getDateString(date));
    useEffect(() => {
        const interval = setInterval(() => {
            setDateTime(getDateString(date));
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [date]);
    return <>{dateTime}</>;
}

const getDateString = (date: Date) => {
    const years = moment().diff(moment(date), 'years');
    const months = moment().diff(moment(date), 'months');
    const days = moment().diff(moment(date), 'days');
    const hours = moment().diff(moment(date), 'hours');
    const minutes = moment().diff(moment(date), 'minutes');
    const seconds = moment().diff(moment(date), 'seconds');
    if (years || months || days) {
        return DateTime.format.dateTime(date);
    }
    if (hours) {
        return hours + ` hour${hours === 1 ? '' : 's'} ago`;
    }
    if (minutes) {
        return minutes + ` minute${minutes === 1 ? '' : 's'} ago`;
    }
    if (seconds) {
        return seconds + ' seconds ago';
    }
};

const getIcon = (event: CHANGE_EVENT | string) => {
    if (!event) return;
    switch (event) {
        case CHANGE_EVENT.CHANGE:
        case CHANGE_EVENT.ADD:
            return <MdCached className="history-item-icon history-change-icon" />;
        case CHANGE_EVENT.BOX:
        case CHANGE_EVENT.ROI:
            return <MdFlipToFront className="history-item-icon history-roi-icon" />;
        default:
            return <MdLabelOutline className="history-item-icon history-label-icon" />;
    }
};

const getTitle = (event: CHANGE_EVENT | string) => {
    if (!event) return;
    switch (event) {
        case CHANGE_EVENT.CHANGE:
        case CHANGE_EVENT.ADD:
            return 'Product Change';
        case CHANGE_EVENT.BOX:
        case CHANGE_EVENT.ROI:
            return 'Bounding Box';
        default:
            return 'Product Status';
    }
};

const getSourceName = (source: UPDATE_SOURCE | string) => {
    if (!source) return '';
    switch (source) {
        case UPDATE_SOURCE.APP_AUTO:
            return 'App Automatic';
        case UPDATE_SOURCE.WEB_AUTO:
            return 'Web Automatic';
        case UPDATE_SOURCE.APP_USER:
            return 'App User';
        case UPDATE_SOURCE.WEB_USER:
            return 'Web User';
        case UPDATE_SOURCE.UNKNOWN:
        default:
            return 'Unknown';
    }
};

function LocationHistorySkeleton() {
    function renderHistoryTile(_: any, index: number) {
        return (
            <div key={`history-skeleton-${index}`} className="history-skeleton-tile">
                <Skeleton loading={true} active></Skeleton>
            </div>
        );
    }
    return <div className="history-skeleton">{Array(50).fill('').map(renderHistoryTile)}</div>;
}

function NoRecordsFound() {
    return (
        <div className="no-records-found">
            <MdOutlineAssignment className="no-records-icon" />
            <div className="no-records-title">Empty List!</div>
            <div className="no-records-description">You have no change history here.</div>
        </div>
    );
}
