import { useContext } from 'react';
import { MdOutlineSettings } from 'react-icons/md';
import { IDropdownOption, MultiSelectBox } from '@gather/components';
import { LocationContext } from '@gather/contexts';
import { DateTime } from '@gather/utils';
import { AddProductForm } from './add-product-form';

export function LocationDetailsHeader() {
    return (
        <div className="location-details-header">
            <MissionDetails />
            <div className="flex-1" />
            <Display />
            <AddProductButton />
        </div>
    );
}

function MissionDetails() {
    const { missionInfo } = useContext(LocationContext);
    return (
        <div className="location-mission-details">
            <div>
                <span className="mission-details-label">Mission</span>
                <span className="mission-details-value">{missionInfo.name}</span>
            </div>
            <div>
                <span className="mission-details-label">Start Date</span>
                <span className="mission-details-value">
                    {missionInfo.start && missionInfo.start.format('MM/DD/YYYY')}
                </span>
            </div>
            <div>
                <span className="mission-details-label">Start Time</span>
                <span className="mission-details-value">{DateTime.format.time(missionInfo.start)}</span>
            </div>
            <div>
                <span className="mission-details-label">End Date</span>
                <span className="mission-details-value">{missionInfo.end && missionInfo.end.format('MM/DD/YYYY')}</span>
            </div>
            <div>
                <span className="mission-details-label">End Time</span>
                <span className="mission-details-value">{DateTime.format.time(missionInfo.end)}</span>
            </div>
        </div>
    );
}

function AddProductButton() {
    return <AddProductForm />;
}

const displayOptions: IDropdownOption[] = [
    {
        label: 'Background Items',
        value: 1,
    },
    {
        label: 'Foreground Items',
        value: 2,
    },
];

function Display() {
    const { displayOptions: value, setDisplayOptions } = useContext(LocationContext);

    function onChange(value: IDropdownOption[]) {
        setDisplayOptions(value);
    }

    return (
        <MultiSelectBox
            onChange={onChange}
            options={displayOptions}
            value={value || null}
            placeholder="Display"
            icon={<MdOutlineSettings />}
        ></MultiSelectBox>
    );
}
