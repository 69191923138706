import { ColumnsType } from 'antd/lib/table';
import { IDropdownOption } from '@gather/components';
import { IDroneItem, IInventoryItem, ILocationProductItem, IMissionItem } from '@gather/types';

export function getSortOrder(sortOrder: 'ascend' | 'descend') {
    return sortOrder === 'ascend' ? 'asc' : 'desc';
}

export function getSortKey(key: string): string {
    switch (key) {
        case 'createdOn':
            return 'date';
        case 'scannedOn':
            return 'scan-on';
        case 'warehouse':
            return 'warehouse';
        case 'productName':
            return 'product';
        case 'missionName':
            return 'mission';
        case 'productCode':
            return 'code';
        case 'binCode':
            return 'location';
        default:
            return key;
    }
}

type IList =
    | ColumnsType<ILocationProductItem>
    | ColumnsType<IMissionItem>
    | ColumnsType<IInventoryItem>
    | ColumnsType<IDroneItem>;

export function filterColumns(
    list: IList,
    filtered: IDropdownOption[],
    sortKey: string,
    sortOrder: 'ascend' | 'descend'
): IList {
    const tableColumns: ColumnsType<ILocationProductItem> = [];
    list.forEach((_col) => {
        if (filtered.some((_opt) => _opt.value === _col.key)) {
            const col = { ..._col };
            if (sortKey === (col as any).dataIndex) {
                col.sortOrder = sortOrder;
            } else {
                col.sortOrder = null;
            }
            tableColumns.push(col as any);
        }
    });

    return tableColumns;
}
