import { Tabs } from 'antd';
import { ITab, Tabs as CustomTab } from '@gather/components';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageHeader } from '@gather/components';
import { ROUTES } from '@gather/routes';
import './styles.less';
import { MdInfoOutline, MdOutlineGroups, MdOutlineHome } from 'react-icons/md';
import { GeneralInfo } from './general-info';
import { ManageMembers } from './manage-members';
import { WarehouseManagement } from './warehouse-management';

const { TabPane } = Tabs;

export function Profile() {
    const location = useLocation();
    const navigate = useNavigate();
    const [activeKey, setActiveKey] = useState(ROUTES.profile.generalInfo);

    useEffect(() => {
        const _activeKey = getActiveRoute(location.pathname);
        setActiveKey(_activeKey);
    }, [location]);

    function renderTabs() {
        return (
            <Tabs onChange={navigate} tabPosition={'left'} activeKey={activeKey}>
                <TabPane
                    className="profile-tab"
                    tab={<TabName label="General Information" icon={<MdInfoOutline />} />}
                    key={ROUTES.profile.generalInfo}
                >
                    <GeneralInfo />
                </TabPane>
                <TabPane
                    className="profile-tab"
                    tab={<TabName label="Warehouse Management" icon={<MdOutlineHome />} />}
                    key={ROUTES.profile.warehouseManagement}
                >
                    <WarehouseManagement />
                </TabPane>
                <TabPane
                    className="profile-tab"
                    tab={<TabName label="Manage Members" icon={<MdOutlineGroups />} />}
                    key={ROUTES.profile.manageMembers}
                >
                    <ManageMembers />
                </TabPane>
            </Tabs>
        );
    }
    return (
        <div className="profile-page dashboard-section">
            <div className="profile-page-content">{renderTabs()}</div>
        </div>
    );
}

function getActiveRoute(path: string): string {
    const key = path.split('/').pop();
    switch (key) {
        case ROUTES.profile.generalInfo:
            return ROUTES.profile.generalInfo;
        case ROUTES.profile.warehouseManagement:
            return ROUTES.profile.warehouseManagement;
        case ROUTES.profile.manageMembers:
            return ROUTES.profile.manageMembers;
        default:
            return ROUTES.profile.generalInfo;
    }
}

interface ITabNameProps {
    label: string;
    icon: React.ReactNode;
}
function TabName(props: ITabNameProps) {
    const { label, icon } = props;
    return (
        <div className="tab-name">
            {icon && <div className="tab-name-icon">{icon}</div>}
            <div className="tab-name-title">{label}</div>
        </div>
    );
}
