import { API } from '@gather/constants';
import { AXIOS } from '@gather/middlewares';
import { IWarehouseParams } from '@gather/types';

async function getWarehouses(params: IWarehouseParams) {
    const response = await AXIOS.post(API.key.members.list, params);
    return response.data;
}

export const WarehouseService = Object.freeze({
    getMembers: getWarehouses,
});
