import { API } from '@gather/constants';
import { AXIOS } from '@gather/middlewares';
import { IUser } from '@gather/types';

async function getUserInfo() {
    const response = await AXIOS.get(API.key.profile.get);
    return response.data;
}

async function updateUserInfo(user: IUser) {
    const response = await AXIOS.put(API.key.profile.update, user);
    return response.data;
}

export const ProfileService = Object.freeze({
    getUserInfo,
    updateUserInfo,
});
