import { API } from '@gather/constants';
import { AXIOS } from '@gather/middlewares';
import { getSortKey, getSortOrder } from '@gather/utils';
import qs from 'qs';

export interface IMMissionsParams {
    isCurrent: boolean;
    pagenumber: number;
    pagesize: number;
    sortby: string;
    sortdirection: 'ascend' | 'descend';

    startDate?: string;
    endDate?: string;
    warehouses: number;
}

export interface IDownloadCumulativeCSVParams {
    warehouses: number[];
    missionId?: number;
}

async function fetchMissions(params: IMMissionsParams) {
    const { isCurrent, pagenumber, pagesize, sortby, sortdirection, startDate = '', endDate = '', warehouses } = params;

    const response = await AXIOS.get(
        API.format(
            API.key.mission.missions,
            isCurrent,
            pagenumber,
            pagesize,
            getSortKey(sortby),
            getSortOrder(sortdirection),
            startDate,
            endDate,
            warehouses
        )
    );
    return response.data;
}

function downloadInventoryReport(
    params: IDownloadCumulativeCSVParams,
    onDownloadProgress?: (progressEvent: any) => void
) {
    return AXIOS.get(API.key.mission.cumulativeCSV, {
        params: params,
        paramsSerializer: (param) => {
            return qs.stringify(param, { arrayFormat: 'repeat' });
        },
        responseType: 'blob',
        onDownloadProgress: onDownloadProgress,
    })
        .then((resp) => {
            if (resp.data) {
                return Promise.resolve(resp.data);
            } else {
                return Promise.reject(resp);
            }
        })
        .catch((err) => console.error(err.message));
}

function downloadWMSCSV(filename: string) {
    return AXIOS.get(API.format(API.key.mission.cumulativeCSV, filename), {
        responseType: 'blob',
    })
        .then((resp) => {
            if (resp.data) {
                return Promise.resolve(resp.data);
            } else {
                return Promise.reject(resp);
            }
        })
        .catch((err) => console.error(err.message));
}

function deleteMission(id: number) {
    return AXIOS.delete(API.format(API.key.mission.delete, id));
}

export const MissionService = Object.freeze({
    fetchMissions,
    downloadInventoryReport,
    downloadWMSCSV,
    deleteMission,
});
