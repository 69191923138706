import './styles.less';
import { Breadcrumb as AntDBreadcrumb } from 'antd';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import { MdChevronRight } from 'react-icons/md';
import { Link } from 'react-router-dom';

interface IProps {
    routes: Route[] | undefined;
    className?: string;
    actions?: React.ReactNode[];
    preActions?: React.ReactNode[];
}
export function Breadcrumb(props: IProps) {
    const { routes, className = '', actions = [], preActions } = props;
    return (
        <header className={`app-breadcrumb ${className}`}>
            <div className="breadcrumb-link-wrapper">
                <AntDBreadcrumb
                    className="app-antd-breadcrumb"
                    routes={routes}
                    itemRender={(route) => (
                        <Link className={route.path === '' ? 'disabled pointer-events-none' : ''} to={route.path}>
                            {route.breadcrumbName}
                        </Link>
                    )}
                    separator={<MdChevronRight className="breadcrumb-separator-icon" size={22} />}
                />
            </div>
            {preActions}
            <div className="breadcrumb-content"></div>
            {actions}
        </header>
    );
}
