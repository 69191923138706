import { useEffect, useState } from 'react';
import { DroneLoadingIndicator } from '@gather/components';
import './styles.less';
interface IProps {
    isAuthenticated: boolean;
}

const splashTime = 5000;

export function SplashScreen({ isAuthenticated }: IProps) {
    const [showSplash, setShowSplash] = useState(true);
    const [startFadeout, setStartFaddeout] = useState(false);

    useEffect(() => {
        let timeouts: (null | NodeJS.Timeout)[] = [null, null];
        if (isAuthenticated) {
            timeouts[0] = setTimeout(() => {
                setShowSplash(false);
            }, splashTime);
            timeouts[1] = setTimeout(() => {
                setStartFaddeout(true);
            }, splashTime - 2000);
        }
        return () => {
            if (timeouts[0]) {
                clearTimeout(timeouts[0]);
            }
            if (timeouts[1]) {
                clearTimeout(timeouts[1]);
            }
        };
    }, [isAuthenticated]);

    if (!showSplash) return null;
    return (
        <div className={`splash-screen-bg ${startFadeout ? 'fadeout-loader' : ''}`}>
            <div className={'splash-screen'}>
                <DroneLoadingIndicator size={150} />
            </div>
        </div>
    );
}
