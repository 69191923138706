import { ITiHiData } from '@gather/types';

export enum CHANGE_EVENT {
    CHANGE = 'changeInventoryItems',
    VERIFY = 'verifyInventoryItems',
    ADD = 'addInventoryItem',
    ROI = 'updateRoi',
    BOX = 'updateDetectionBox',
}
export enum UPDATE_SOURCE {
    APP_AUTO = 'AppAuto',
    WEB_AUTO = 'WebAuto',
    APP_USER = 'AppUser',
    WEB_USER = 'WebUser',
    UNKNOWN = 'Unknown',
}
export interface IProductForm {
    code: string;
    type: 'licensePlateNumber' | 'box' | 'sku' | 'lotNumber' | string;
}
export interface IProductFormResponse {
    code: number;
    message: string;
}
export const PRODUCT_FORM_CONTROLS: IProductForm = {
    code: 'code',
    type: 'type',
};

export const CASE_COUNT = {
    properties: [
        {
            id: 1,
            name: 'Visible Case Count',
            permissionKey: 'TIHI_VISIBLE_CASE_COUNT',
            accessor: (data: ITiHiData) => data.visible_box_count ?? '-',
        },
        {
            id: 2,
            name: 'Inferred Case Count',
            permissionKey: 'TIHI_INFERRED_CASE_COUNT',
            accessor: (data: ITiHiData) => data.inferred_box_count ?? '-',
        },
        {
            id: 3,
            name: 'Inferred Quantity',
            permissionKey: 'TIHI_TOTAL_QUANTITY',
            accessor: (data: ITiHiData) => data.piece_count ?? '-',
        },
        {
            id: 4,
            name: 'Cases on Hand (WMS)',
            permissionKey: 'TIHI_CASES_ON_HAND',
            accessor: (data: ITiHiData) => data.box_on_hand ?? '-',
        },
        {
            id: 5,
            name: 'Ti (WMS)',
            permissionKey: 'TIHI_TI',
            accessor: (data: ITiHiData) => data.ti ?? '-',
        },
        {
            id: 6,
            name: 'Hi (Drone)',
            permissionKey: 'TIHI_HI',
            accessor: (data: ITiHiData) => data.hi ?? '-',
        },
        {
            id: 7,
            name: 'Pieces Per Case (WMS)',
            permissionKey: 'TIHI_PIECE_PER_CASE',
            accessor: (data: ITiHiData) =>
                data.wms_details?.specialInfo && data.wms_details.specialInfo['PIECE PER CASE']
                    ? data.wms_details.specialInfo['PIECE PER CASE']
                    : '-',
        },
        {
            id: 8,
            name: 'Case Depth (WMS)',
            permissionKey: 'TIHI_CASE_DEPTH',
            accessor: (data: ITiHiData) =>
                data.wms_details?.specialInfo &&
                data.wms_details.specialInfo['CASE DEPTH'] &&
                data.wms_details.specialInfo['CASE DEPTH'] !== 'empty'
                    ? data.wms_details.specialInfo['CASE DEPTH']
                    : '-',
        },
        {
            id: 9,
            name: 'Available Stock (WMS)',
            permissionKey: 'TIHI_AVAILABLE_STOCK',
            accessor: (data: ITiHiData) => data.wms_details?.description ?? '-',
        },
    ],
    verticalSiblings: [
        {
            top: 2,
            bottom: 4,
        },
        {
            top: 5,
            bottom: 6,
        },
    ],
};

export const IMAGE_OVERLAY = {
    color: {
        roi: '#FF6D01',
        box: '#14E2AC',
        barcode: '#07446B',
    },
    stroke: {
        width: 20,
    },
};

export enum OverlayBoxType {
    roi = 'overlay/roi',
    box = 'overlay/box',
    barcode = 'overlay/barcode',
}

export enum IMAGE_FILTER {
    editOverlay = 'editOverlay',
    createOverlay = 'createOverlay',
    brightness = 'brightness',
    fullScreen = 'fullScreen',
    overlay = 'overlay',
    zoom = 'zoom',
}
