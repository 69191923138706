import { API } from '@gather/constants';
import { AXIOS } from '@gather/middlewares';
import { getSortKey, getSortOrder } from '@gather/utils';
export interface IInventoriesParams {
    isCurrent: boolean;
    pagenumber: number;
    pagesize: number;
    sortby: string;
    sortdirection: 'ascend' | 'descend';
    search: string;

    warehouses?: number;
    missionId?: number;
    binId?: number;

    aggregateBy?: string;
    startDate?: string;
    endDate?: string;
    status?: string;

    exceptionOnly?: boolean | string;
}

export interface IAggregateKey {
    key: string;
    value: any;
}

export interface IAggregatedInventoriesParams extends IInventoriesParams {
    [aggregateKey: string]: any;
}

export interface IDeleteInventoryParams {
    inventoryItemIds: string[];
    userDeleted: boolean;
}

async function fetchInventories(params: IInventoriesParams) {
    const {
        isCurrent,
        missionId = '',
        pagenumber,
        pagesize,
        sortby,
        sortdirection,
        warehouses = '',
        startDate = '',
        endDate = '',
        status = '',
        aggregateBy,
        exceptionOnly = '',
        binId = '',
        search = '',
    } = params;

    const response = await AXIOS.get(
        API.format(
            API.key.inventories.list,
            isCurrent,
            pagenumber,
            pagesize,
            getSortKey(sortby),
            getSortOrder(sortdirection),
            startDate,
            endDate,
            status,
            aggregateBy,
            missionId,
            warehouses || '',
            exceptionOnly,
            binId,
            search
        )
    );
    return response.data;
}

async function fetchAggregatedInventories(params: IAggregatedInventoriesParams, aggregateKey: IAggregateKey) {
    const {
        isCurrent,
        missionId = '',
        pagenumber,
        pagesize,
        sortby,
        sortdirection,
        warehouses = '',
        startDate = '',
        endDate = '',
        status = '',
        aggregateBy,
        exceptionOnly = '',
        binId = '',
        search = '',
    } = params;
    const { key, value } = aggregateKey;

    const response = await AXIOS.get(
        `${API.format(
            API.key.inventories.list,
            isCurrent,
            pagenumber,
            pagesize,
            getSortKey(sortby),
            getSortOrder(sortdirection),
            startDate,
            endDate,
            status,
            undefined,
            missionId,
            warehouses || '',
            exceptionOnly,
            binId,
            search
        )}&${key}=${value}`
    );
    return response.data;
}

function deleteInventory(params: IDeleteInventoryParams) {
    return AXIOS.put(API.format(API.key.inventories.delete), params);
}

export const InventoryService = Object.freeze({
    fetchInventories,
    fetchAggregatedInventories,
    deleteInventory,
});
