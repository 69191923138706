import { useContext, useEffect, useState } from 'react';
import { IDropdownOption, MultiSelectBox, SelectBox, RangePicker } from '@gather/components';
import { GlobalContext, droneColumns, DroneContext } from '@gather/contexts';
import { MdOutlineSettings, MdOutlineFilterAlt } from 'react-icons/md';
import { Moment } from 'moment-timezone';
import { useMutation, useQuery } from 'react-query';
import { Query } from '@gather/constants';
import { DroneService, GlobalService, IUpdateExpireResponse } from '@gather/services';
import { Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

function Display() {
    const { displayOptions, setDisplayOptions } = useContext(DroneContext);
    const [options, setOptions] = useState<IDropdownOption[]>(
        droneColumns.map<IDropdownOption>((_inv, index) => ({
            label: `${_inv.title || ''}`,
            value: _inv.key,
            hidden: _inv.key === 'action-item',
        }))
    );

    useEffect(() => {
        // write logic to implement wms columns options by using -> setOptions
    });

    function onChange(values: IDropdownOption[]) {
        setDisplayOptions(values);
    }

    return (
        <MultiSelectBox
            onChange={onChange}
            options={options}
            value={displayOptions}
            placeholder="Display"
            icon={<MdOutlineSettings />}
        ></MultiSelectBox>
    );
}

function Warehouse() {
    const [warehouses, setWarehouses] = useState<IDropdownOption[]>([]);
    const { warehouse, setWarehouse } = useContext(DroneContext);
    const GLOBAL_CONTEXT = useContext(GlobalContext);

    const { data, isLoading } = useQuery([Query.warehouses], () => fetchWarehouses(), {
        keepPreviousData: true,
        cacheTime: Infinity,
    });

    function getDefaultWarehouse(_warehouses: IDropdownOption[]) {
        return GLOBAL_CONTEXT.warehouse;
    }

    useEffect(() => {
        if (data) {
            const _warehouses: IDropdownOption[] = data.map(
                (_warehouse: any): IDropdownOption => ({
                    label: _warehouse.name,
                    code: _warehouse.code,
                    value: _warehouse.id,
                })
            );
            setWarehouses(_warehouses);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (warehouses && warehouses[0] && !warehouse) {
            // TO-DO: Set default warehouse with tenant name
            const defaultWarehouse = getDefaultWarehouse(warehouses);
            setWarehouse(defaultWarehouse);
            GLOBAL_CONTEXT.setWarehouse(defaultWarehouse);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouses, warehouse]);

    function fetchWarehouses() {
        return GlobalService.fetchWarehouses();
    }

    function onChange(value: IDropdownOption | null) {
        setWarehouse(value);
    }

    return (
        <SelectBox
            onChange={onChange}
            options={warehouses}
            value={warehouse || null}
            placeholder="Warehouse"
            isClearable={false}
            loading={isLoading}
            icon={<MdOutlineFilterAlt />}
        ></SelectBox>
    );
}

function Date() {
    const { date, setDate } = useContext(DroneContext);

    function onChange(values: { date: Moment[] | null; quickSelect?: number }) {
        setDate(values || undefined);
    }

    return <RangePicker placeholder={'Date'} value={date} onChange={onChange} />;
}

interface IExpiryTimeProps {
    defaultExpiryTime?: number;
    onExpireTimeChange: (expireTime: number) => void;
}

function ExpriyTime(props: IExpiryTimeProps) {
    const { defaultExpiryTime = 0, onExpireTimeChange } = props;
    const [expiryTime, setExpiryTime] = useState(defaultExpiryTime);

    useEffect(() => {
        setExpiryTime(defaultExpiryTime);
    }, [defaultExpiryTime]);

    const { isLoading, mutate } = useMutation<IUpdateExpireResponse, unknown, number>(updateExpiryTime, {
        mutationKey: Query.updateExpireTime,
        onSuccess: () => {
            onExpireTimeChange(expiryTime);
        },
        onError: () => {
            setExpiryTime(defaultExpiryTime);
        },
    });

    function updateExpiryTime(_expiryTime: number) {
        return DroneService.updateExpireTime(_expiryTime);
    }

    function triggerMutation() {
        if (expiryTime > 0) {
            mutate(expiryTime);
        }
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            triggerMutation();
        }
    }

    function hanldeBlur(event: React.FocusEvent<HTMLInputElement, Element>) {
        if (+event.currentTarget.value !== expiryTime) {
            triggerMutation();
        }
    }

    return (
        <div className="expiry-filter-wrapper">
            <div className="expiry-label">Expiry Time</div>
            <Input
                className="expiry-filter"
                type="number"
                placeholder="Enter expiry time"
                onChange={(e) => setExpiryTime(+(e.currentTarget.value || 0) || 0)}
                onBlur={hanldeBlur}
                onKeyDown={handleKeyDown}
                value={expiryTime}
                disabled={isLoading}
                addonAfter={isLoading && <LoadingOutlined />}
            />
        </div>
    );
}

export const DroneFilter = {
    Display,
    Warehouse,
    Date,
    ExpriyTime,
};
