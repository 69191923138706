import { queryClient } from '@gather/configs';
import { IProductForm, PRODUCT_FORM_CONTROLS, Query } from '@gather/constants';
import { LocationContext } from '@gather/contexts';
import { ICreateProductParams, LocationService } from '@gather/services';
import { Button, Form, Input, message, Modal, Radio } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { MdOutlineAdd } from 'react-icons/md';
import { useMutation } from 'react-query';

export function AddProductForm() {
    const { selectedProduct, params } = useContext(LocationContext);

    const [visible, showModal] = useState(false);
    const [form] = useForm<IProductForm>();

    useEffect(() => {
        if (!visible) {
            form.resetFields();
            form.setFieldsValue({});
        }
    }, [visible, form]);

    const { isLoading, mutate } = useMutation<any, unknown, ICreateProductParams>(createProduct, {
        mutationKey: Query.createProduct,
        onSuccess: () => {
            queryClient.invalidateQueries([Query.products, params])
            message.success('Product has been created successfully!');
            showModal(false);
        },
        onError: () => {
            message.error('Failed to create new product!');
        },
    });

    function createProduct(params: ICreateProductParams) {
        return LocationService.createProduct(params);
    }

    function onSubmit(form: IProductForm) {
        const params: ICreateProductParams = {
            attributedImage: selectedProduct?.items?.[0]?.imgId || 0,
            code: form.code,
            inventoryItemType: form.type as any,
            locatedInBin: {
                id: selectedProduct?.binLocationId || '',
            },
            missionId: selectedProduct?.missionAppId || '',
            recordType: 'userInput',
        };
        mutate(params);
    }

    function renderForm() {
        return (
            <div className="add-product-content">
                <Form.Item
                    label="Product Code"
                    name={PRODUCT_FORM_CONTROLS.code}
                    rules={[{ required: true, message: 'Please enter a product code!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item name={PRODUCT_FORM_CONTROLS.type} label="Code Type">
                    <Radio.Group>
                        <Radio defaultChecked value="licensePlateNumber">
                            Barcode
                        </Radio>
                        <Radio value="box">Box</Radio>
                        <Radio value="sku">Sku</Radio>
                        <Radio value="lotNumber">Lot</Radio>
                    </Radio.Group>
                </Form.Item>
            </div>
        );
    }

    function renderContent() {
        return (
            <Form
                className="add-product-form"
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={onSubmit}
                colon={false}
                labelAlign="left"
                autoComplete="off"
                requiredMark={false}
                form={form}
            >
                <div className="add-product-header">Add A Product</div>
                {renderForm()}
                <div className="add-product-footer">
                    <Button type="default" onClick={() => showModal(false)}>
                        Cancel
                    </Button>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Submit
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        );
    }
    return (
        <>
            <Button
                className="add-new-product-button"
                type="primary"
                icon={<MdOutlineAdd className="add-new-product-icon" />}
                size={'middle'}
                onClick={() => showModal(true)}
            >
                Add New Product
            </Button>
            <Modal
                title="Add A Product"
                visible={visible}
                footer={null}
                maskClosable
                className={'add-new-product-modal'}
                modalRender={renderContent}
                centered
                closable
            ></Modal>
        </>
    );
}
