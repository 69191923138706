export const setCookie = (cname: string, cvalue: string, expiresOn: Date) => {
    const expires = 'expires=' + expiresOn.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

export const clearCookie = (cname: string) => {
    const cvalue: string = '';
    const expiresOn: Date = new Date(Date.now() - 525600000);
    const expires = 'expires=' + expiresOn.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

export function getCookie(cname: string) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}
