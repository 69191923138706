import { Table } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { InventoryFilters, Query } from '@gather/constants';
import { IInventoriesParams } from '@gather/services';
import './styles.less';
import { IInventoryItem } from '@gather/types';
import { GlobalContext, inventoriesColumns, InventoryContext } from '@gather/contexts';
import { Pagination } from '@gather/components';
import { TableSkeletonLoader } from '@gather/components';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { filterColumns } from '@gather/utils';
import { DateTime } from '@gather/utils';
import { ExpandedList } from './expanded-sub-list';
import { useInventoriesQuery } from '@gather/queries';
import { useSearchParams } from 'react-router-dom';
import Mark from 'mark.js';

interface IProps {
    queryKey: Query;
    exceptions?: boolean;
    missionId?: number;
    missionInventories?: boolean;
    enabledFilters?: InventoryFilters[];
}

export function InventoriesList(props: IProps) {
    const [searchParams] = useSearchParams();

    const {
        exceptions,
        queryKey,
        missionId,
        missionInventories,
        enabledFilters = [
            InventoryFilters.display,
            InventoryFilters.date,
            InventoryFilters.verification,
            InventoryFilters.aggregate,
        ],
    } = props;
    const { displayOptions, date, aggregateBy, verification, selectedInventory, setSelectedInventory } =
        useContext(InventoryContext);

    const { warehouse } = useContext(GlobalContext);

    const [tableColumns, setTableColumns] = useState(inventoriesColumns);
    const [expandedKeys, setExpandedKeys] = useState<readonly React.Key[]>([]);
    const [params, setParams] = useState<IInventoriesParams>({
        isCurrent: true,
        pagenumber: 1,
        pagesize: 30,
        sortby: 'scannedOn',
        sortdirection: 'descend',
        warehouses: warehouse.value,
        missionId: missionId,
        aggregateBy: (enabled(InventoryFilters.aggregate) && aggregateBy?.value?.toLowerCase()) || '',
        startDate: enabled(InventoryFilters.date) ? DateTime.format.date(date?.date?.[0]) : '',
        endDate: enabled(InventoryFilters.date) ? DateTime.format.date(date?.date?.[1]) : '',
        status: (enabled(InventoryFilters.verification) && verification?.value?.toLowerCase()) || '',
        exceptionOnly: exceptions || '',
        search: searchParams.get('search') || '',
    });

    const [inverntories, totalRecords, isLoading] = useInventoriesQuery(queryKey, params, missionInventories);

    useEffect(() => {
        const _tableColumns: ColumnsType<IInventoryItem> = filterColumns(
            inventoriesColumns,
            displayOptions,
            params.sortby,
            params.sortdirection
        ) as ColumnsType<IInventoryItem>;

        setTableColumns(_tableColumns);
    }, [displayOptions, params.sortby, params.sortdirection]);

    useEffect(() => {
        const searchKey = searchParams.get('search') || '';
        console.log({ searchKey });
        setExpandedKeys([]);
        setParams({
            ...params,
            pagenumber: 1,
            search: searchKey,
        });
    }, [searchParams]);

    useEffect(() => {
        if (inverntories && params.search.length > 0 && inverntories.length > 0) {
            const nodeList = document.querySelectorAll('.ant-table-tbody .ant-table-cell');
            highlightSearchTerms(params.search, nodeList);
        }
    }, [inverntories]);

    useEffect(() => {
        setExpandedKeys([]);
        setParams({
            ...params,
            missionId: missionId,
            aggregateBy: (enabled(InventoryFilters.aggregate) && aggregateBy?.value?.toLowerCase()) || '',
            startDate: enabled(InventoryFilters.date) ? DateTime.format.date(date?.date?.[0]) : '',
            endDate: enabled(InventoryFilters.date) ? DateTime.format.date(date?.date?.[1]) : '',
            status: (enabled(InventoryFilters.verification) && verification?.value?.toLowerCase()) || '',
            exceptionOnly: exceptions || '',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [missionId, aggregateBy, date, verification, exceptions]);

    function highlightSearchTerms(term: string, context: string | HTMLElement | readonly HTMLElement[] | NodeList) {
        const instance = new Mark(context);
        term = term.trim();
        instance.unmark();
        instance.mark(term, {
            separateWordSearch: false,
            className: 'search-mark'
        });
    }

    function enabled(filter: InventoryFilters) {
        return enabledFilters.includes(filter);
    }

    function onPaginationChange(page: number, pageSize: number) {
        setExpandedKeys([]);
        setParams({
            ...params,
            pagenumber: page,
            pagesize: pageSize,
        });
    }

    function onSortOrderChange(sortOrder: any) {
        const _sortOrder: SorterResult<IInventoryItem> = sortOrder.length >= 1 ? sortOrder[1] : sortOrder;
        if (_sortOrder) {
            setParams({
                ...params,
                pagenumber: 1,
                sortby: (_sortOrder.field as string) || '',
                sortdirection: _sortOrder.order || 'ascend',
            });
        }
    }

    const expandedRowRender = (record: IInventoryItem, index: number, indent: number, expanded: boolean) => {
        return (
            <>
                <ExpandedList
                    columns={tableColumns}
                    params={params}
                    expanded={expanded}
                    inventory={record}
                    aggregateBy={aggregateBy}
                />
            </>
        );
    };

    return (
        <div className="table-container-wrapper inventories-list">
            <Table
                className="inventories-list-table"
                columns={tableColumns}
                dataSource={inverntories}
                rowKey="id"
                expandable={
                    aggregateBy && enabled(InventoryFilters.aggregate)
                        ? {
                              expandedRowRender: expandedRowRender,
                              rowExpandable: (record) => record?.count > 1,
                              onExpandedRowsChange: (_expandedKeys) => setExpandedKeys(_expandedKeys),
                              expandedRowKeys: expandedKeys,
                          }
                        : undefined
                }
                rowSelection={{
                    selectedRowKeys: selectedInventory?.id ? [selectedInventory?.id] : undefined,
                }}
                onRow={(row: IInventoryItem) => {
                    return {
                        onClick: (event) => {
                            const isPreSelected = selectedInventory?.id === row.id;
                            setSelectedInventory({
                                ...row,
                                selected: isPreSelected,
                            });
                        },
                    };
                }}
                onChange={(_: any, __: any, sorter: any) => {
                    onSortOrderChange(sorter);
                }}
                components={{
                    body: isLoading
                        ? {
                              wrapper: () => <TableSkeletonLoader />,
                          }
                        : undefined,
                }}
                sortDirections={['ascend', 'descend', 'ascend']}
                pagination={false}
                showSorterTooltip={false}
            />

            <div className="table-footer">
                <div className="flex-1" />
                <div className="pagination-wrapper">
                    <Pagination
                        disabled={isLoading}
                        current={params.pagenumber}
                        pageSize={params.pagesize}
                        currentCount={inverntories.length || 0}
                        total={totalRecords}
                        onChange={onPaginationChange}
                    />
                </div>
            </div>
        </div>
    );
}
