import { ActionMenu } from '@gather/components';
import { queryClient } from '@gather/configs';
import { Query } from '@gather/constants';
import { HistoryContext, LocationContext } from '@gather/contexts';
import { IInventoryItem } from '@gather/types';
import { deleteInventory } from '@gather/utils/inventory.utils';
import { useContext } from 'react';
import { MdOutlineHistory, MdOutlineDelete } from 'react-icons/md';

interface IProps {
    inventory: IInventoryItem;
}
export function InventoryListAction(props: IProps) {
    const { inventory } = props;

    const { showHistory } = useContext(HistoryContext);
    const { params } = useContext(LocationContext);

    function onDeleteSuccess() {
        queryClient.invalidateQueries([Query.products, params]);
        console.log("on delte success")
    }

    return (
        <ActionMenu
            options={[
                {
                    onClick: () => {
                        inventory.items?.[0]?.imgId && showHistory({ itemIds: [inventory.items[0].itemId] });
                    },
                    title: 'View History',
                    icon: <MdOutlineHistory />,
                },
                {
                    onClick: () => {
                        deleteInventory(inventory.items?.[0]?.itemId, onDeleteSuccess);
                    },
                    title: 'Delete Product',
                    icon: <MdOutlineDelete />,
                },
            ]}
        />
    );
}
