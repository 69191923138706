// import warehouse from './formatted.json';
import './styles.less';

interface IProps {
    show: boolean;
}
export function MapView(props: IProps) {
    function renderBin() {
        return (
            <div className="map-bin">
                <div className="bin-bar"></div>
                <img
                    src="https://gatheraisync.blob.core.windows.net/dnata-dfw-staging/20220607T084330-0400-123456ab--DnataDFW/thumbnails/LRes_DJI_0521.jpg_0025_undrawn.jpg?sv=2021-06-08&st=2022-06-17T12%3A43%3A36Z&se=2022-06-19T12%3A43%3A36Z&sr=b&sp=r&sig=cAArQJElNqwTeWCmd4tKiRzR%2B3oH5QnzadMkCWenzz8%3D"
                    alt=""
                />
            </div>
        );
    }
    function renderRow() {
        return (
            <div className="map-preview-row">
                {renderBin()}
                {renderBin()}
                {renderBin()}
                {renderBin()}
                {renderBin()}
                {renderBin()}
                {renderBin()}
                {renderBin()}
                {renderBin()}
                {renderBin()}
                {renderBin()}
                {renderBin()}
                {renderBin()}
            </div>
        );
    }
    const { show } = props;
    return (
        <div className={`map-view ${show ? 'show-map' : ''}`}>
            <div className="map-content">
                <div className="map-preview">
                    <div className="map-mask">
                        <div>A better map view is under development, stay tuned!</div>
                    </div>
                    {renderRow()}
                    {renderRow()}
                    {renderRow()}
                    {renderRow()}
                    {renderRow()}
                    {renderRow()}
                    {renderRow()}
                    {renderRow()}
                    {renderRow()}
                    {renderRow()}
                </div>
            </div>
        </div>
    );
}


// function MapView() {
//     return (
//         <div
//             style={{
//                 display: 'flex',
//                 flexDirection: 'column',
//                 overflow: 'auto',
//                 height: '100vh',
//             }}
//         >
//             {(warehouse as any).map((aisle: any) => {
//                 return (
//                     <div
//                         style={{
//                             border: '1px solid black',
//                             margin: '2px',
//                             display: 'flex',
//                             flexDirection: 'row',
//                             padding: '1px',
//                             backgroundColor: 'lightyellow',
//                             marginBottom: '5px',
//                         }}
//                     >
//                         {aisle.racks.reverse().map((rack: any) => {
//                             return (
//                                 <div
//                                     style={{
//                                         border: '1px solid blue',
//                                         margin: '1px',
//                                         display: 'flex',
//                                         flexDirection: 'column',
//                                         padding: '1px',
//                                     }}
//                                 >
//                                     <div
//                                         style={{
//                                             padding: '2px',
//                                             fontSize: '8px',
//                                         }}
//                                     >
//                                         {aisle.name}/{rack.name}
//                                     </div>

//                                     {/* {rack.bins.map((bin: any) => {
//                                         return (
//                                             <div
//                                                 style={{
//                                                     border: "1px solid red",
//                                                     margin: "2px",
//                                                     display: "flex",
//                                                     flexDirection: "row",
//                                                     padding: "2px",
//                                                 }}
//                                             >
//                                                 <div
//                                                     style={{
//                                                         fontSize: "8px",
//                                                         padding: "3px",
//                                                         aspectRatio: "2 / 1",
//                                                         display: "flex",
//                                                         alignItems: "center",
//                                                         justifyContent:
//                                                             "center",
//                                                     }}
//                                                 >
//                                                     {bin.name}
//                                                 </div>
//                                             </div>
//                                         );
//                                     })} */}
//                                 </div>
//                             );
//                         })}
//                     </div>
//                 );
//             })}
//         </div>
//     );
// }