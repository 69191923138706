import './styles.less';
import { Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import Select, {
    ActionMeta,
    ClearIndicatorProps,
    components,
    ContainerProps,
    DropdownIndicatorProps,
    GroupBase,
    MultiValue,
    OptionProps,
    ValueContainerProps,
} from 'react-select';
import { REACT_SELECT_CONFIGS } from '@gather/configs';
import { ReactComponent as ClearIcon } from '@gather/assets/images/clear.svg';

export interface IDropdownOption {
    label: string;
    value: any;
    icon?: React.ReactNode;
    disabled?: boolean;
    hidden?: boolean;
    [key: string]: any;
}

// Multi Select Dropdown
interface IMultiValueContainerProps
    extends ValueContainerProps<
        {
            label: string;
        },
        true,
        GroupBase<{
            label: string;
        }>
    > {}

export function MultiSelectOption(props: OptionProps<{ label: string }, true, GroupBase<{ label: string }>>) {
    if ((props.data as any)?.hidden) return null;
    return (
        <div>
            <components.Option
                {...props}
                className={
                    props.isSelected
                        ? 'react-select-selected-option react-multi-select-option'
                        : 'react-multi-select-option'
                }
            >
                <div className="flex flex-row">
                    <Checkbox
                        type="checkbox"
                        checked={props.isSelected}
                        onClick={() => props.selectOption(props.data)}
                    />
                    <div className="select-item-label">{props.label}</div>
                </div>
            </components.Option>
        </div>
    );
}

function MultiSelectValueContainer({ children, ...props }: IMultiValueContainerProps) {
    return (
        <components.ValueContainer {...props} className="value-container">
            <div className="w-0 h-0 overflow-hidden opacity-0">{children}</div>
            <div className="value-wrapper">
                {(props.selectProps as any).icon ? (
                    <div className="icon-wrapper">{(props.selectProps as any).icon}</div>
                ) : null}
                <div className="select-placeholder" {...props}>
                    {props.selectProps.placeholder}
                </div>
            </div>
        </components.ValueContainer>
    );
}

const DropdownIndicator = ({
    children,
    ...props
}: DropdownIndicatorProps<
    {
        label: string;
    },
    true,
    GroupBase<{
        label: string;
    }>
>) => {
    return (
        <components.DropdownIndicator
            {...props}
            className={`${
                props.selectProps.menuIsOpen ? 'rotate-icon-180' : ''
            } dropdown-indicator animated-dropdown-indicator`}
        ></components.DropdownIndicator>
    );
};

const ClearIndicator = ({
    children,
    ...props
}: ClearIndicatorProps<
    {
        label: string;
    },
    true,
    GroupBase<{
        label: string;
    }>
>) => {
    return (
        <components.ClearIndicator {...props} className={`clear-indicator`}>
            <ClearIcon className={`dropdown-icon clear-icon ${props.isFocused ? 'icon-focused' : ''}`} />
        </components.ClearIndicator>
    );
};

interface IMultiSelectProps {
    className?: string;
    placeholder?: string;
    options: IDropdownOption[];
    value: IDropdownOption[];
    icon?: React.ReactNode;
    loading?: boolean;
    disabled?: boolean;
    onChange: (selected: any[]) => void;
}

export function MultiSelectBox({
    className = '',
    placeholder = '',
    options,
    value,
    icon,
    onChange,
    loading,
    disabled,
}: IMultiSelectProps) {
    const [selectedOptions, setSelectedOptions] = useState<IDropdownOption[]>(value);

    useEffect(() => {
        setSelectedOptions(value);
    }, [value]);

    function onValueChange(
        newValue: MultiValue<{
            label: string;
            value: any;
        }>,
        actionMeta: ActionMeta<{
            label: string;
            value: any;
        }>
    ) {
        const _selectedOptions = [...newValue];
        setSelectedOptions(_selectedOptions);
        onChange(_selectedOptions);
    }
    return (
        <Select
            {...REACT_SELECT_CONFIGS}
            placeholder={placeholder}
            options={options}
            value={selectedOptions}
            onChange={onValueChange}
            components={{
                Option: MultiSelectOption,
                ValueContainer: MultiSelectValueContainer,
                IndicatorSeparator: () => null,
                DropdownIndicator: DropdownIndicator,
            }}
            className={`react-select react-multi-select ${className}`}
            isMulti={true}
            isSearchable={false}
            closeMenuOnSelect={false}
            isClearable={false}
            hideSelectedOptions={false}
            isLoading={loading}
            isDisabled={disabled}
            {...({ icon } as any)}
        ></Select>
    );
}
// END - Multi Select Dropdown

// Single Select Dropdown
interface ISingeSelectValueContainerProps
    extends ValueContainerProps<
        {
            label: string;
        },
        true,
        GroupBase<{
            label: string;
        }>
    > {}
export function SingleSelectOption(props: OptionProps<{ label: string }, true, GroupBase<{ label: string }>>) {
    return (
        <div>
            <components.Option
                {...props}
                className={
                    props.isSelected
                        ? 'react-select-selected-option react-single-select-option'
                        : 'react-single-select-option'
                }
            >
                <div className="select-item-label">{props.label}</div>
            </components.Option>
        </div>
    );
}

function SingleSelectValueContainer({ children, ...props }: ISingeSelectValueContainerProps) {
    return (
        <components.ValueContainer {...props} className="value-container">
            <div className="w-0 h-0 overflow-hidden opacity-0">{children}</div>
            {(props.selectProps as any).icon ? (
                <div className="icon-wrapper">{(props.selectProps as any).icon}</div>
            ) : null}
            <div className="select-placeholder" {...props}>
                {props.selectProps.placeholder}
            </div>
            {props.selectProps.value && (
                <div className="select-value" {...props}>
                    {(props.selectProps.value as any)?.label || ''}
                </div>
            )}
        </components.ValueContainer>
    );
}

export function DropdownContainer({ children, ...props }: ContainerProps<unknown, false, GroupBase<unknown>>) {
    return (
        <div>
            <components.SelectContainer
                {...props}
                className={`dropdown-container app-input-container ${props.selectProps.value ? 'have-value' : ''} ${
                    props.className || ''
                }`}
            >
                {children}
            </components.SelectContainer>
        </div>
    );
}

interface ISingleSelectProps {
    className?: string;
    placeholder?: string;
    options: IDropdownOption[];
    value: IDropdownOption | null;
    isClearable?: boolean;
    icon?: React.ReactNode;
    loading?: boolean;
    disabled?: boolean;
    onChange: (value: IDropdownOption | null) => void;
}
export function SelectBox({
    className = '',
    placeholder = '',
    options,
    value,
    icon,
    isClearable = false,
    onChange,
    loading,
    disabled,
}: ISingleSelectProps) {
    const [selectedOptions, setSelectedOptions] = useState<IDropdownOption | null>(value);

    useEffect(() => {
        setSelectedOptions(value);
    }, [value]);

    function onValueChange(newValue: IDropdownOption | null) {
        setSelectedOptions(newValue);
        onChange(newValue);
    }
    return (
        <Select
            {...REACT_SELECT_CONFIGS}
            placeholder={placeholder}
            options={options}
            value={selectedOptions}
            onChange={onValueChange}
            components={{
                Option: SingleSelectOption,
                ValueContainer: SingleSelectValueContainer,
                IndicatorSeparator: () => null,
                DropdownIndicator: DropdownIndicator,
                SelectContainer: DropdownContainer,
                ClearIndicator: ClearIndicator,
            }}
            className={`react-select react-single-select ${className}`}
            isSearchable={false}
            isClearable={isClearable}
            hideSelectedOptions={false}
            isLoading={loading}
            isDisabled={disabled}
            isMulti={false}
            {...({ icon } as any)}
        ></Select>
    );
}
// END - Single Select Dropdown
