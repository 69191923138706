import { ColumnsType } from 'antd/lib/table';
import moment, { Moment } from 'moment-timezone';
import { createContext, useState } from 'react';
import { ProductName, IDropdownOption, InventoryStatus, VerificationLabel } from '@gather/components';
import { IInventoryItem } from '@gather/types';
import { usePersist } from '@gather/hooks';
import { LOCAL_STORAGE_KEYS } from '@gather/constants';
import { ProductHistoryProvider } from './product-history.context';
import { InventoryListAction } from '@gather/features';

export interface IInventoryData {
    missionId?: number;
    date?: { date: Moment[] | null; quickSelect?: number } | undefined;
    aggregateBy?: IDropdownOption | null;
    displayOptions: IDropdownOption[];
    verification?: IDropdownOption | null;
    setMissionId: (missionId: number) => void;
    setDate: (date: { date: Moment[] | null; quickSelect?: number } | undefined) => void;
    setAggregateBy: (displayOptions: IDropdownOption | null) => void;
    setDisplayOptions: (displayOptions: IDropdownOption[]) => void;
    setVerification: (displayOptions: IDropdownOption | null) => void;
    selectedInventory?: IInventoryItem;
    setSelectedInventory: (inventory: IInventoryItem) => void;
}

interface IProps {
    children?: React.ReactNode;
}

function renderDate(value: string, inventory: IInventoryItem, index: number) {
    return <>{moment(value).format('MM/DD/YYYY')}</>;
}

function renderAction(value: string, inventory: IInventoryItem, index: number) {
    return <InventoryListAction inventory={inventory} />;
}

function renderProductName(value: string, record: IInventoryItem, index: number) {
    return (
        <ProductName
            name={value}
            valid={record.verifiedRecord}
            exception={record.exceptionType?.toLocaleLowerCase() || record.recordType?.toLocaleLowerCase()}
        />
    );
}

function renderStatus(value: string, record: IInventoryItem, index: number) {
    return (
        <InventoryStatus
            name={value}
            valid={record.verifiedRecord}
            exception={record.exceptionType?.toLocaleLowerCase() || record.recordType?.toLocaleLowerCase()}
        />
    );
}

function renderQuantity(value: string, record: IInventoryItem, index: number) {
    return <div className="text-right">{value}</div>;
}

function renderVerification(value: string, record: IInventoryItem, index: number) {
    return <VerificationLabel inventory={record} />;
}

export const inventoriesColumns: ColumnsType<IInventoryItem> = [
    {
        title: 'Product',
        dataIndex: 'productName',
        key: 'productName',
        render: renderProductName,
        sorter: {
            multiple: 2,
        },
        ellipsis: true,
    },
    {
        title: 'Mission',
        dataIndex: 'missionName',
        key: 'missionName',
        sorter: {
            multiple: 2,
        },
        ellipsis: true,
    },
    {
        title: 'Code',
        dataIndex: 'productCode',
        key: 'productCode',
        sorter: {
            multiple: 2,
        },
        ellipsis: true,
    },
    {
        title: 'Location',
        dataIndex: 'binCode',
        key: 'binCode',
        sorter: {
            multiple: 2,
        },
        ellipsis: true,
    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        className: 'quantity-cell',
        render: renderQuantity,
        sorter: {
            multiple: 2,
        },
        ellipsis: true,
    },
    {
        title: 'Status',
        dataIndex: 'productName',
        key: 'status',
        render: renderStatus,
        ellipsis: true,
    },
    {
        title: 'Verification',
        dataIndex: 'verificationSource',
        key: 'verificationSource',
        render: renderVerification,
        ellipsis: true,
    },
    {
        title: 'Date',
        dataIndex: 'scannedOn',
        key: 'date',
        render: renderDate,
        sorter: {
            multiple: 2,
        },
        ellipsis: true,
    },
    {
        title: '',
        key: 'action-item',
        className: 'action-item-cell',
        render: renderAction,
        ellipsis: true,
    },
];

export const verificationOptions = [
    { label: 'Valid', value: 'verified' },
    { label: 'Invalid', value: 'invalid' },
    { label: 'Pending', value: 'pending' },
];

export const aggregateOptions = [
    { label: 'Date', value: 'date' },
    { label: 'Location', value: 'location' },
];

const inventoryData: IInventoryData = {
    displayOptions: [],
    setMissionId: (_: number) => {},
    setDate: (_: { date: Moment[] | null; quickSelect?: number } | undefined) => {},
    setAggregateBy: (_: IDropdownOption | null) => {},
    setDisplayOptions: (_: IDropdownOption[]) => {},
    setVerification: (_: IDropdownOption | null) => {},
    setSelectedInventory: (_: IInventoryItem | null) => {},
};

export const InventoryContext = createContext<IInventoryData>(inventoryData);

export function InventoryProvider(props: IProps) {
    const { children } = props;
    const [selectedInventory, setSelectedInventory] = useState<IInventoryItem>();

    const [missionId, setMissionId] = useState<number | undefined>();

    const [date, setDate] = usePersist<{ date: Moment[] | null; quickSelect?: number } | undefined>(
        LOCAL_STORAGE_KEYS.INVENTORY_DATE_FILTER
    );

    const [aggregateBy, setAggregateBy] = usePersist<IDropdownOption | null>(
        LOCAL_STORAGE_KEYS.INVENTORY_AGGREGATE_FILTER
    );

    const [displayOptions, setDisplayOptions] = usePersist<IDropdownOption[]>(
        LOCAL_STORAGE_KEYS.INVENTORY_DISPLAY_FILTER,
        inventoriesColumns.map<IDropdownOption>((_inv, index) => ({
            label: `${_inv.title || ''}`,
            value: _inv.key,
        })) || []
    );

    const [verification, setVerification] = usePersist<IDropdownOption | null>(
        LOCAL_STORAGE_KEYS.INVENTORY_VERIFICATION_FILTER
    );

    return (
        <InventoryContext.Provider
            value={{
                missionId,
                setMissionId,
                date,
                setDate,
                aggregateBy,
                setAggregateBy,
                displayOptions,
                setDisplayOptions,
                verification,
                setVerification,
                selectedInventory,
                setSelectedInventory,
            }}
        >
            <ProductHistoryProvider>{children}</ProductHistoryProvider>
        </InventoryContext.Provider>
    );
}
