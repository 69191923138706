import { useContext, useEffect, useState } from 'react';
import { InventoryContext } from '@gather/contexts';
import { IBinInfo, LocationDetails } from '@gather/features';

export function InventoriesLocationDetails() {
    const { selectedInventory } = useContext(InventoryContext);
    const [binInfo, setBinInfo] = useState<IBinInfo | null>(null);

    useEffect(() => {
        if (selectedInventory) {
            console.log({ selectedInventory });
            setBinInfo({
                missionAppId: selectedInventory.missionAppId,
                binId: selectedInventory.binId,
                inventoryId: selectedInventory.items?.[0]?.itemId || '',
                missionId: selectedInventory.missionId,
                warehouseId: selectedInventory.warehouseId,
            });
        } else {
            setBinInfo(null);
        }
    }, [selectedInventory]);

    return <>{binInfo && <LocationDetails binInfo={binInfo} isSlider={true} />}</>;
}
