import { Popover } from 'antd';
import { useState } from 'react';
import { MdMoreVert } from 'react-icons/md';
import './styles.less';

export interface IActionOption {
    title: string;
    icon?: React.ReactNode;
    disabled?: boolean;
    onClick: () => void;
}

interface IProps {
    options: IActionOption[];
}

export function ActionMenu(props: IProps) {
    const [showPopover, setShowPopover] = useState(false);
    const { options } = props;
    // const containerRef = useRef<HTMLDivElement | null>();

    function closePopover(visible: boolean = false) {}

    function renderContent() {
        return (
            <div className="action-items">
                {options.map((_opt, index) => {
                    const { title, icon, disabled, onClick } = _opt;
                    return (
                        <div
                            key={`action-item-${index}`}
                            className={`action-item ${disabled ? 'disabled' : ''}`}
                            onClick={() => {
                                !disabled && onClick && onClick();
                                closePopover();
                            }}
                        >
                            <div className="action-item-icon">{icon}</div>
                            <span className="action-item-label">{title}</span>
                        </div>
                    );
                })}
            </div>
        );
    }

    return (
        <div
            onClick={(e) => {
                setShowPopover(!showPopover);
                e.stopPropagation();
            }}
            className="action-menu-icon-wrapper"
            // ref={(_ref) => containerRef.current = _ref}
        >
            <Popover
                className="action-menu"
                placement="bottomRight"
                content={renderContent}
                visible={showPopover}
                trigger="click"
                onVisibleChange={(visible) => {
                    setShowPopover(visible);
                }}
                // getPopupContainer={() => containerRef.current || document.body}
            >
                <MdMoreVert className="action-menu-icon" />
            </Popover>
        </div>
    );
}
