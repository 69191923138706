import { Pagination as AntDPagination } from 'antd';
import { useEffect, useState } from 'react';
import './styles.less';

interface IProps {
    disabled?: boolean;
    current: number;
    currentCount: number;
    total: number;
    pageSize: number;
    onChange: (page: number, pageSize: number) => void;
}
export function Pagination({ disabled, current, currentCount, total, pageSize, onChange }: IProps) {
    const [totalRecords, setTotalRecords] = useState<number>(total);

    useEffect(() => {
        if (total >= 0) {
            setTotalRecords(total);
        }
    }, [total]);

    function getCountText(startIndex: number, endIndex: number, total: number) {
        if (startIndex === endIndex) {
            return `${startIndex} of ${total}`;
        }
        return `${startIndex}-${endIndex} of ${total}`;
    }

    function renderCountSection() {
        if (total > 0) {
            const startIndex = pageSize * (current - 1) + 1;
            const endIndex = startIndex + currentCount - 1;
            return <div className="count-section">{getCountText(startIndex, endIndex, total)}</div>;
        }
        return null;
    }

    return (
        <div className="pagination-wrapper">
            {renderCountSection()}
            <AntDPagination
                disabled={disabled}
                current={current}
                pageSize={pageSize}
                total={totalRecords}
                showSizeChanger={true}
                pageSizeOptions={[10, 20, 30, 40, 50]}
                onChange={onChange}
            />
        </div>
    );
}
