import axios from 'axios';
import { ROUTES } from '@gather/routes';
import { Auth0 } from '@gather/utils';
import { API } from '@gather/constants';
import moment from 'moment-timezone';

const timezone = moment.tz.guess();

export const AXIOS = axios;

const whitelistedAPIs: string[] = [API.key.global.tenant.get];

AXIOS.interceptors.request.use(
    function (request) {
        // Do something before request is sent
        if (whitelistedAPIs.includes(request.url || '')) return request;
        const { expired, token } = Auth0.token.isExpired();
        if (expired) {
            Auth0.token.revoke();
            window.location.href = '';
        } else {
            (request.headers as any)['Authorization'] = 'Bearer ' + token;
            // (request.headers as any)['platform'] = 'web';
            // (request.headers as any)['Accept'] = 'application/json';
            // (request.headers as any)['timezone'] = timezone;
        }
        return request;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// response interceptor
AXIOS.interceptors.response.use(
    function (response) {
        // Do something with response data
        if (response && response.status === 403) {
            Auth0.token.revoke();
            window.location.href = ROUTES.root;
        }
        return response;
    },
    function (error) {
        // Do something with response error
        if (error.response && error.response.status === 403) {
            Auth0.token.revoke();
            window.location.href = ROUTES.root;
        }
        return Promise.reject(error);
    }
);
