import { Routes, Route, Navigate } from 'react-router-dom';
import { ROUTES } from '@gather/routes';
import { Exceptions } from './exceptions';
import { Insights } from './insights';
import { Inventory } from './inventories';
import { LocationDetails } from './location-details';
import { MissionDashboard } from './mission-dashboard';
import { Profile } from './profile';
import { DroneDashboard } from './drone-dashboard';
import { SearchInventories } from './search';

export function Pages() {
    return (
        <>
            <Routes>
                <Route path={ROUTES.dashboard.dafault} element={<MissionDashboard />}></Route>
                <Route path={ROUTES.insights.dafault} element={<Insights />}></Route>
                <Route path={ROUTES.inventory.dafault} element={<Inventory />}></Route>
                <Route path={ROUTES.exceptions.dafault} element={<Exceptions />}></Route>
                <Route path={ROUTES.search.dafault} element={<SearchInventories />}></Route>
                <Route path={ROUTES.droneDashboard.dafault} element={<DroneDashboard />}></Route>
                <Route path={ROUTES.location.details} element={<LocationDetails />}></Route>
                <Route path={ROUTES.profile.dafault} element={<Profile />}>
                    <Route path={ROUTES.profile.generalInfo} element={null} />
                    <Route path={ROUTES.profile.warehouseManagement} element={null} />
                    <Route path={ROUTES.profile.manageMembers} element={null} />
                    <Route path="*" element={<Navigate to={ROUTES.profile.generalInfo} replace />} />
                </Route>
                <Route path="*" element={<Navigate to={ROUTES.dashboard.dafault} replace />} />
            </Routes>
        </>
    );
}
