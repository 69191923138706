import { IDropdownOption, MultiSelectBox } from '@gather/components';
import { MdOutlineFilterAlt } from 'react-icons/md';

interface IFacilityFilterProps {
    options: string[];
    values: string[];
    loading: boolean;
    setValues: (values: string[]) => void;
}

function Facilitites(props: IFacilityFilterProps) {
    const { values, options, loading, setValues } = props;

    function onChange(value: IDropdownOption[]) {
        if (value.length < 1 && values.length >= 1) return setValues(values);
        setValues(value.map((_val) => _val.value));
    }

    return (
        <MultiSelectBox
            onChange={onChange}
            options={getOptions(options)}
            value={getOptions(values) || null}
            placeholder="Warehouse"
            loading={loading}
            disabled={loading}
            icon={<MdOutlineFilterAlt />}
        ></MultiSelectBox>
    );
}

export const InsightsFilter = {
    Facilitites,
};

function getOptions(options: string[]): IDropdownOption[] {
    return options.map((_opt) => ({ value: _opt, label: _opt }));
}
