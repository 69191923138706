import { CACHE } from '@gather/configs';
import { Query } from '@gather/constants';
import { IInventoriesParams, InventoryService } from '@gather/services';
import { IInventoryItem } from '@gather/types';
import { useQuery } from 'react-query';

export function useInventoriesQuery(
    queryKey: Query,
    params: IInventoriesParams,
    missionInventories: boolean = false
): [inverntories: IInventoryItem[], totalRecords: number, isLoading: boolean] {
    // Query hook
    const { data, isLoading } = useQuery([queryKey, params], () => fetchData(params), {
        cacheTime: CACHE.time['5mins'],
        enabled: missionInventories ? !!params.missionId : true,
    });
    // End - Query hook

    function fetchData(_params: IInventoriesParams) {
        return InventoryService.fetchInventories(_params);
    }

    const inverntories = (!isLoading && data && data.response && data.response.products) || [];
    const totalRecords = data?.response?.totalRecords;

    return [inverntories, totalRecords, isLoading];
}
