import { IInventoryItem, ILocationProductItem } from '@gather/types';
import './styles.less';

interface IVerificationLabelProps {
    inventory: IInventoryItem | ILocationProductItem;
}

export function VerificationLabel(props: IVerificationLabelProps) {
    const { inventory } = props;
    let status = <div className="verification-label verification-pending">Pending</div>;
    if (inventory.verifiedRecord) {
        if (inventory.correctness) {
            status = <div className="verification-label verification-valid">Valid</div>;
        } else {
            status = <div className="verification-label verification-invalid">Invalid</div>;
        }
    }
    return status;
}
