import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@gather/routes';
import { Auth0Service, GlobalService } from '@gather/services';
import { Auth0 } from '@gather/utils';
import { GlobalContext } from '@gather/contexts';
import { ITenantInfo } from '@gather/types';
import moment from 'moment-timezone';

export function useAuth(): [isAuthenticated: boolean] {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const { setTenant, tenant } = useContext(GlobalContext);
    const navigate = useNavigate();

    function setAuthenticated() {
        setIsAuthenticated(true);
    }

    async function login() {
        const tenantInfo = await getTenantInfo();
        if (tenantInfo?.tenant) {
            const lock = Auth0.getLock(tenantInfo.tenant);
            lock.on('authenticated', async (result) => {
                const token = result.accessToken;
                console.error('authenticated');
                Auth0.token.updateToken(token);
                setTenant(tenantInfo);
                moment.tz.setDefault(tenantInfo.timeZone);
                setAuthenticated();
                lock.hide();
            });
            lock.on('unrecoverable_error', (error) => {
                console.error('unrecoverable_error', error);
            });
            lock.on('authorization_error', (error) => {
                console.error('authorization_error', error);
            });
            lock.show();
        }
    }

    function logout() {
        Auth0.token.revoke();
        navigate(ROUTES.root);
    }

    async function checkUser() {
        const { isTokenValid, haveToken } = Auth0.token.isValid();
        console.log({ isTokenValid, haveToken });
        if (isTokenValid && haveToken) {
            try {
                await Auth0Service.userInfo();
                return setAuthenticated();
            } catch (err) {
                logout();
            }
            await login();
        } else if (!isTokenValid) {
            if (window.location.pathname === ROUTES.root) {
                await login();
            } else {
                navigate(ROUTES.root);
            }
        } else {
            setAuthenticated();
        }
    }

    async function getTenantInfo(): Promise<ITenantInfo | null> {
        try {
            // const hostname = window.location.host;
            const hostname = `dnata-dfw-staging.dashboard.gather-ai.com`;
            const tenant: ITenantInfo = await GlobalService.getTenant(hostname);
            return tenant;
        } catch (err) {
            console.error(err);
            return null;
        }
    }

    useEffect(() => {
        if (tenant && isAuthenticated) {
            moment.tz.setDefault(tenant.timeZone);
        }
    }, [tenant, isAuthenticated]);

    useEffect(() => {
        checkUser();
    }, []);

    return [isAuthenticated];
}
