import { PageHeader } from '@gather/components';
import { Query } from '@gather/constants';
import { MapView, MissionsList } from '@gather/features';
import { MissionFilter } from '@gather/features';
import { Radio } from 'antd';
import { useState } from 'react';

export function Missions() {
    const [view, setView] = useState<'list' | 'map'>('list');

    function actions() {
        return [
            <MissionFilter.Warehouse />,
            <MissionFilter.Date />,
            <MissionFilter.Display />,
            <MapViewSwitch value={view} setValue={setView} />,
        ];
    }

    return (
        <div className="missions-table-wrapper dashboard-section">
            <PageHeader title="Missions" actions={actions()} />
            <div className={`flex flex-row ${view === 'map' ? 'gap-2' : ''}`}>
                <div className="flex-1">
                    <MissionsList queryKey={Query.missions} />
                </div>
                <MapView show={view === 'map'} />
            </div>
        </div>
    );
}
interface IMapViewSwitchProps {
    value: 'list' | 'map';
    setValue: (value: 'list' | 'map') => void;
}

function MapViewSwitch(props: IMapViewSwitchProps) {
    const { value, setValue } = props;
    return (
        <Radio.Group value={value} onChange={(e) => setValue(e.target.value)}>
            <Radio.Button type="primary" value="list">List View</Radio.Button>
            <Radio.Button value="map">Map View</Radio.Button>
        </Radio.Group>
    );
}
