import { Input, Table } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { PageHeader, Pagination, TableSkeletonLoader } from '@gather/components';
import { CACHE } from '@gather/configs';
import { Query } from '@gather/constants';
import { useDebouncer } from '@gather/hooks';
import { MemberService } from '@gather/services';
import { IMemberItem, IMembersParams } from '@gather/types';

export function ManageMembers() {
    return (
        <div className="manage-members">
            <PageHeader title="Manage Members" subTitle="Manage your members efficiently." />
            <MembersList />
        </div>
    );
}

export const membersListColumns: ColumnsType<IMemberItem> = [
    {
        title: 'Username',
        dataIndex: 'userName',
        key: 'userName',
        sorter: {
            multiple: 2,
        },
        ellipsis: true,
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        sorter: {
            multiple: 2,
        },
        ellipsis: true,
    },
    {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        sorter: {
            multiple: 2,
        },
        ellipsis: true,
    },
];

export function MembersList() {
    const [params, setParams] = useState<IMembersParams>({
        page: 1,
        size: 30,
        sortField: 'createdOn',
        sort: 'descend',
        warehouseId: 25,
        queryString: '',
        status: 'accepted',
    });

    const { data, isLoading } = useQuery([Query.members, params], () => fetchData(params), {
        cacheTime: CACHE.time['2hrs'],
    });

    function fetchData(_params: IMembersParams) {
        return MemberService.getMembers(_params);
    }

    function onPaginationChange(page: number, pageSize: number) {
        setParams({
            ...params,
            page: page,
            size: pageSize,
        });
    }

    function onSortOrderChange(sortOrder: any) {
        const _sortOrder: SorterResult<IMemberItem> = sortOrder.length >= 1 ? sortOrder[1] : sortOrder;
        if (_sortOrder) {
            setParams({
                ...params,
                page: 1,
                sortField: (_sortOrder.field as string) || '',
                sort: _sortOrder.order || 'ascend',
            });
        }
    }

    function onSearch(searchKey: string) {
        setParams({
            ...params,
            page: 1,
            queryString: searchKey && searchKey.trim(),
        });
    }

    const members = (!isLoading && data && data.response && data.response.missions) || [];
    const totalRecords = (data && data.response && data.response.totalRecords) || 0;
    return (
        <>
            <SearchInput onSearch={onSearch} />
            <div className="table-container-wrapper members-list">
                <Table
                    className="members-list-table"
                    columns={membersListColumns}
                    dataSource={members}
                    rowKey="id"
                    // rowSelection={{
                    //     selectedRowKeys: selectedMission?.id
                    //         ? [selectedMission?.id]
                    //         : undefined,
                    // }}
                    // onRow={(row) => {
                    //     return {
                    //         onClick: (event) => {
                    //             setSelectedMission(row);
                    //         },
                    //     };
                    // }}
                    components={{
                        body: isLoading
                            ? {
                                  wrapper: () => <TableSkeletonLoader />,
                              }
                            : undefined,
                    }}
                    onChange={(_: any, __: any, sorter: any) => {
                        onSortOrderChange(sorter);
                    }}
                    sortDirections={['ascend', 'descend', 'ascend']}
                    pagination={false}
                    showSorterTooltip={false}
                />
                <div className="table-footer">
                    <div className="flex-1"></div>
                    <div className="pagination-wrapper">
                        <Pagination
                            disabled={isLoading}
                            currentCount={members.length || 0}
                            current={params.page}
                            pageSize={params.size}
                            total={totalRecords}
                            onChange={onPaginationChange}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
interface ISearchInputProps {
    onSearch: (value: string) => void;
}

function SearchInput(props: ISearchInputProps) {
    const { onSearch } = props;
    const [value, setValue] = useDebouncer<string>('', onSearch);

    return (
        <Input.Search
            placeholder="Search Members..."
            allowClear
            onChange={(e) => setValue(e.currentTarget.value)}
            defaultValue={value}
            className="search-input"
        />
    );
}
