import { IDownloadCumulativeCSVParams, MissionService } from '@gather/services';
import { IMissionItem } from '@gather/types';
import { message } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import moment from 'moment-timezone';

interface IDownloadParams {
    mission?: IMissionItem;
    allMissions?: boolean;
}

export async function downloadCumulativeCSV(params: IDownloadParams) {
    const { mission, allMissions } = params;
    if (mission) {
        try {
            const params: IDownloadCumulativeCSVParams = {
                missionId: allMissions ? undefined : mission.id,
                warehouses: [mission.warehouseId],
            };
            const blob = await MissionService.downloadInventoryReport(params);
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            if (mission.id) {
                a.download = `${mission.name}-${mission.directory}.csv`;
            } else {
                a.download = `cumulative-${mission.warehouse}-${moment().format('YYYYMMDD')}.csv`;
            }
            a.click();
            URL.revokeObjectURL(a.href);
        } catch (err) {
            console.error(err);
        }
    }
    return;
}

export async function downloadWMS(filename: string) {
    if (filename) {
        try {
            const blob = await MissionService.downloadWMSCSV(filename);
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = filename;
            a.click();
            URL.revokeObjectURL(a.href);
        } catch (err) {
            console.error(err);
        }
    }
    return;
}

export function deleteMission(id: number) {
    return confirm({
        title: 'Are you sure you want to delte the mission?',
        onOk: async () => {
            if (id || id >= 0) {
                try {
                    await MissionService.deleteMission(id);
                    message.success('The mission has been deleted successfully!');
                } catch (err) {
                    message.error('Failed to delete the mission!');
                    console.error(err);
                }
            }
        },
        centered: true,
        maskClosable: true,
        okText: 'YES',
        cancelText: 'NO',
    });
}
