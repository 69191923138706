import { LoadingOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { MdFileDownload } from 'react-icons/md';
import './styles.less';

interface Props {
    prefixText?: string;
    postfixText?: string;
    onDownload: () => Promise<any>;
}

export function DownloadButton({ prefixText = '', postfixText = '', onDownload }: Props) {
    const [showLoader, setShowLoader] = useState(false);

    async function onDownloadClick() {
        setShowLoader(true);
        try {
            setShowLoader(true);
            await onDownload();
            setShowLoader(false);
        } catch {
            setShowLoader(false);
        }
    }

    function renderDownloadIcon() {
        if (showLoader) return <LoadingOutlined size={12} />;
        return <MdFileDownload />;
    }

    return (
        <div className={`download-button ${showLoader ? 'loading' : ''}`} onClick={onDownloadClick}>
            {prefixText || ''}
            <div className="download-icon-wrapper">{renderDownloadIcon()}</div>
            <span className="postfix-text">{postfixText || ''}</span>
        </div>
    );
}
