import { createContext, useState } from 'react';
import { LocationHistory } from '@gather/features';
import { IHistoryParams } from '@gather/services';

export interface IProductHistory {
    historyData?: IHistoryParams;
    showHistory: (historyData: IHistoryParams) => void;
}

interface IProps {
    children?: React.ReactNode;
}

const productHistory: IProductHistory = {
    showHistory: (historyData: IHistoryParams) => {},
};

export const HistoryContext = createContext<IProductHistory>(productHistory);

export function ProductHistoryProvider({ children }: IProps) {
    const [params, setParams] = useState<IHistoryParams>();

    function onClose() {
        setParams(undefined);
    }

    return (
        <HistoryContext.Provider
            value={{
                historyData: params,
                showHistory: setParams,
            }}
        >
            {children}
            <LocationHistory {...params} onClose={onClose} />
        </HistoryContext.Provider>
    );
}
