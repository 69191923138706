import Auth0Lock from 'auth0-lock';
import { clearCookie, getCookie, setCookie } from './cookie.utils';
import JWTDecode from 'jwt-decode';

let lock: typeof Auth0Lock;

function getLock(connection: string) {
    if (!lock) {
        let options: Auth0LockConstructorOptions = {
            languageDictionary: {
                title: 'GATHER',
            },
            theme: {
                primaryColor: '#14E2AC',
                logo: 'https://gatherdashboardprod.blob.core.windows.net/publicblob/icon/logo_mark.png',
            },
            auth: {
                redirect: true,
                params: {
                    scope: 'openid',
                    audience: 'https://gatherai.api.com',
                },
            },
            closable: false,
        };
        if (connection) {
            options['allowedConnections'] = [connection];
        }
        const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
        const domain = process.env.REACT_APP_AUTH0_DOMAIN || '';
        lock = new Auth0Lock(clientId, domain, options);
    }
    return lock;
}

const token = {
    isValid: () => {
        let isTokenValid: boolean = false;
        const token = getCookie('token');
        const decodedToken: any = token && token !== '' && JWTDecode(token);
        if (decodedToken && decodedToken.exp < new Date().getTime()) {
            isTokenValid = true;
        }
        return { isTokenValid, haveToken: token && token !== '' };
    },
    isExpired: () => {
        let isExpired: boolean = true;
        const token = getCookie('token');
        const decodedToken: any = token && token !== '' && JWTDecode(token);
        if (token && token !== '' && decodedToken.exp < new Date().getTime()) {
            isExpired = false;
        }
        return { expired: isExpired, token };
    },
    revoke: () => {
        clearCookie('token');
    },
    updateToken: (token: string) => {
        const decodedToken: any = JWTDecode(token);
        setCookie('token', token, new Date(decodedToken.exp * 1000));
    },
};

export const Auth0 = {
    getLock,
    token,
};
