import { RaphaelPaper, RaphaelAttributes, RaphaelPath } from 'raphael';
import { IMAGE_OVERLAY } from '@gather/constants';
import { I2dPoint } from '@gather/types';

export function drawAnimatedPath(
    canvas: RaphaelPaper<'SVG' | 'VML'>,
    pathstr: string,
    duration: number,
    attr: Partial<RaphaelAttributes>,
    onComplete?: () => void
) {
    const guide_path = canvas.path(pathstr).attr({ stroke: 'none', fill: 'none' });
    const path = canvas.path(guide_path.getSubpath(0, 1)).attr(attr);
    const total_length = guide_path.getTotalLength();
    const start_time = new Date().getTime();
    const interval_length = 25;
    const result = path;

    const interval_id = setInterval(function () {
        const elapsed_time = new Date().getTime() - start_time;
        const this_length = (elapsed_time / duration) * total_length;
        const subpathstr = guide_path.getSubpath(0, this_length);
        attr.path = subpathstr;
        path.animate(attr, interval_length);
        if (elapsed_time >= duration) {
            clearInterval(interval_id);
            if (onComplete) onComplete();
        }
    }, interval_length);
    return result;
}

export function drawPath(canvas: RaphaelPaper<'SVG' | 'VML'>, pathstr: string, attr: Partial<RaphaelAttributes>) {
    const path = canvas.path(pathstr);
    path && path.attr(attr);
    return path;
}

export function getPath(cordinates: I2dPoint[]) {
    const pathArr: string[] = [];
    cordinates.forEach((cordinates, i) => {
        const { x, y } = cordinates;
        if (i === 0) {
            pathArr.push(`M ${x},${y}`);
        } else {
            pathArr.push(`L ${x},${y}`);
        }
    });
    const path = pathArr.join(' ');
    return `${path}Z`;
}

export const rectToPoints = (rect: [[number, number], [number, number]]) => {
    if (!rect) return [];
    let [[x1, y1], [w, h]] = rect;
    // x,y is bottom-left axes, transfer to top-left axes
    return [
        [x1, 1 - y1],
        [x1 + w, 1 - y1],
        [x1 + w, 1 - (y1 + h)],
        [x1, 1 - (y1 + h)],
    ];
};

export function rectToPath(rect: [[number, number], [number, number]], width: number, height: number) {
    const points = rectToPoints(rect).map((_) => [_[0] * width, _[1] * height]) as any;
    const path = `M${points.map((p: any) => p.join(',')).join('L')}Z`;
    return path;
}
export function drawCodeText(
    paper: RaphaelPaper<'SVG' | 'VML'> | undefined,
    box: RaphaelPath<'SVG' | 'VML'> | undefined,
    code: string,
    strokeWidth: number
) {
    const fontSize = 80;
    const space = 5;
    if (box && paper) {
        let { x, y, width } = box.getBBox();
        x = x + width / 2;
        y = y - strokeWidth - fontSize - space;
        const codeNode = paper.text(x, y, code);
        codeNode.attr({
            'font-size': fontSize,
            'font-family': 'Euclid Flex',
            fill: '#000000',
            'fill-opacity': 1,
            text: code,
        });
        const codeBbx = codeNode.getBBox();
        const codeBg = paper.rect(0, 0, 1, 1);
        codeBg.attr({
            fill: IMAGE_OVERLAY.color.box,
            'fill-opacity': 1,
            x: codeBbx.x - fontSize,
            y: codeBbx.y - fontSize / 2,
            width: codeBbx.width + fontSize * 2,
            height: codeBbx.height + fontSize,
        });
        codeNode.insertAfter(box);
        codeBg.insertBefore(codeNode);
        box.data('code-bg', codeBg);
        box.data('code-txt', codeNode);
        box.data('code-val', code);
        return [codeNode, codeBg];
    }
    return [];
}
