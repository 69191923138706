export const ROUTES = {
    root: '/',
    logout: '/logout',
    login: '/login',
    dashboard: {
        dafault: '/mission-dashboard',
    },
    insights: {
        dafault: '/insights',
    },
    inventory: {
        dafault: '/inventory',
    },
    exceptions: {
        dafault: '/exceptions',
    },
    search: {
        dafault: '/search-results',
    },
    droneDashboard: {
        dafault: '/drone-dashboard',
    },
    location: {
        details: '/location-details',
    },
    profile: {
        dafault: '/profile',
        generalInfo: 'general-info',
        warehouseManagement: 'warehouse-management',
        manageMembers: 'manage-members',
    },
};
