import { QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppHeader, Sidebar } from '@gather/components';
import { queryClient } from '@gather/configs';
import { useAuth } from '@gather/hooks';
import { Pages } from '@gather/pages';
import { SplashScreen } from '@gather/pages/splash-screen';
import { ReactQueryDevtools } from 'react-query/devtools';
import { GlobalContextProvider } from '@gather/contexts';
import '@gather/App.less';

function Dashboard() {
    const [isAuthenticated] = useAuth();
    return (
        <>
            <SplashScreen isAuthenticated={isAuthenticated} />
            {isAuthenticated && (
                <div className="app">
                    <Sidebar />
                    <div className="app-content-wrapper">
                        <AppHeader />
                        <div className="flex-1 app-content">
                            <Pages />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
            <GlobalContextProvider>
                <Router>
                    <Dashboard />
                </Router>
            </GlobalContextProvider>
        </QueryClientProvider>
    );
}

export default App;
