import { useEffect, useState } from 'react';
import './styles.less';

export interface ITab {
    name: string;
    id: number;
    path?: string;
}

interface Props {
    tabs: ITab[];
    value: ITab;
    onChange: (tab: ITab) => void;
}

const tabWidth = 90;

export function Tabs({ tabs, onChange, value }: Props) {
    const [activeTabIndex, setActiveTabIndex] = useState(tabs.findIndex((_tab) => _tab.id === value.id));
    useEffect(() => {
        setActiveTabIndex(tabs.findIndex((_tab) => _tab.id === value.id));
    }, [value, tabs]);

    function renderTabHeader(tab: ITab, key: string) {
        return (
            <div
                key={key}
                onClick={() => onChange(tab)}
                className={tab.id === value.id ? 'tab active' : 'tab'}
                style={{ width: `${tabWidth}px` }}
            >
                {tab.name}
            </div>
        );
    }

    return (
        <div className="custom-tabs">
            {tabs.map((_tab, i) => renderTabHeader(_tab, `tab-item-${i}`))}
            <div
                className="active-tab-indicator"
                style={{
                    left: `${activeTabIndex * tabWidth}px`,
                    width: `${tabWidth}px`,
                }}
            ></div>
        </div>
    );
}
