import { Tooltip } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, SlideModal } from '@gather/components';
import { HistoryContext, InventoryContext, LocationContext, LocationProvider } from '@gather/contexts';
import { ROUTES } from '@gather/routes';
import { BinNavigation } from './bin-navigation';
import { CauseCountSection } from './case-count-section';
import { LocationDetailsHeader } from './header';
import { ImagePreviewer } from './image-previewer-section';
import { ProductsDetailsSection } from './product-details-section';
import { ProductListSection } from './product-list-section';
import './styles.less';
import { MdHistory } from 'react-icons/md';
import { IMAGE_FILTER } from '@gather/constants';

export interface IBinInfo {
    missionAppId: string;
    binId: number;
    inventoryId: string;
    missionId: number;
    warehouseId: number;
}

interface ILocationDetailsProps {
    binInfo: IBinInfo;
    isSlider: boolean;
}

interface ILocationDetailsContentProps {
    binInfo: IBinInfo;
}

export function LocationDetails({ binInfo, isSlider }: ILocationDetailsProps) {
    function renderSlider() {
        return <LocationDetailsModal binInfo={binInfo} />;
    }
    function renderPage() {
        return <LocationDetailsPage binInfo={binInfo} />;
    }
    function renderContent() {
        if (isSlider) return renderSlider();
        return renderPage();
    }
    return (
        <div className="location-details-container">
            <LocationProvider
                showAction={!isSlider}
                locationInfo={{
                    appMissionId: binInfo.missionAppId,
                    binId: binInfo.binId,
                    itemId: binInfo.inventoryId,
                    missionId: binInfo.missionId,
                    warehouseId: binInfo.warehouseId,
                }}
            >
                {renderContent()}
            </LocationProvider>
        </div>
    );
}

function LocationDetailsModal({ binInfo }: ILocationDetailsContentProps) {
    useEffect(() => {
        if (binInfo) {
            setShowLocationDetails(true);
        }
    }, [binInfo]);

    const [showLocationDetails, setShowLocationDetails] = useState(false);

    return (
        <>
            {binInfo && (
                <SlideModal
                    prefixActions={[<PrefixActions />]}
                    // postfixActions={[<PostfixActions />]}
                    show={showLocationDetails}
                    onClose={() => setShowLocationDetails(false)}
                    className="location-details-slide-modal"
                >
                    <div className="location-details-modal-content">
                        <ImagePreviewer
                            filters={[IMAGE_FILTER.brightness, IMAGE_FILTER.fullScreen, IMAGE_FILTER.overlay]}
                        />
                        <ProductListSection />
                        <ViewProductsButton />
                    </div>
                </SlideModal>
            )}
        </>
    );
}

function LocationDetailsPage({ binInfo }: ILocationDetailsContentProps) {
    useEffect(() => {
        if (binInfo) {
            console.log({ binInfo });
        }
    }, [binInfo]);

    return (
        <div className="location-details-page-content">
            <Breadcrumb
                className="location-details-breadcrumb"
                routes={[
                    {
                        breadcrumbName: 'Mission Dashboard',
                        path: ROUTES.dashboard.dafault,
                    },
                    {
                        breadcrumbName: 'Location Details',
                        path: '',
                    },
                ]}
                actions={[<BinNavigation />]}
                preActions={[<LocationHistoryButton />]}
            />
            <div className="location-details-content-wrapper">
                <div className="location-details-content">
                    <LocationDetailsHeader />
                    <div className="location-details-flex-container">
                        <div className="left-section">
                            <ImagePreviewer thumbnail />
                            <ProductsDetailsSection />
                        </div>
                        <div className="right-section">
                            <CauseCountSection />
                            <ProductListSection />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function PrefixActions() {
    const { selectedInventory } = useContext(InventoryContext);
    return (
        <div className="location-details-slider-header-prefix">
            Location
            <span className="location-name">{selectedInventory?.binCode}</span>
        </div>
    );
}

function ViewProductsButton() {
    const { selectedProduct } = useContext(LocationContext);
    const { binId, missionId, items } = selectedProduct || {};

    const [searchParams, setSearchParams] = useState('');

    useEffect(() => {
        const id = items?.[0]?.itemId;
        let path = `?bin=${binId}`;
        if (missionId) {
            path += `&mission=${missionId}`;
        }
        if (id) {
            path += `&inventory=${id}`;
        }
        path += `#`;
        setSearchParams(path);
    }, [binId, missionId, items]);

    return (
        <Link
            to={{
                pathname: ROUTES.location.details,
                search: searchParams,
            }}
            className={`view-products-button ${selectedProduct ? '' : 'disabled-button'}`}
        >
            View Product Details
        </Link>
    );
}

function LocationHistoryButton() {
    const { selectedProduct } = useContext(LocationContext);

    const { showHistory } = useContext(HistoryContext);

    return (
        <div className="location-history-icon-wrapper">
            <Tooltip title="Location History" key={'location-history-icon'} color="#FFF" placement="right">
                <MdHistory
                    onClick={() => {
                        selectedProduct &&
                            showHistory({
                                missionAppId: selectedProduct.missionName,
                                binName: selectedProduct.binCode,
                            });
                    }}
                    size={21}
                    className="location-history-icon"
                />
            </Tooltip>
        </div>
    );
}
