export interface IUser {
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    nickname: string;
    title: string;
    accecibility: string;
}
export interface IUserResponse {
    code: number;
    message: string;
}
export const USER_FORM_CONTROLS: IUser = {
    id: 'id',
    email: 'email',
    firstName: 'firstName',
    lastName: 'lastName',
    nickname: 'nickname',
    title: 'title',
    accecibility: 'accecibility',
};

export interface IMembersParams {
    warehouseId: number;
    status: string;
    sort: string;
    sortField: string;
    queryString: string;
    page: number;
    size: number;
}
export interface IMemberItem {
    userName: string;
    email: string;
    userId: string;
    warehouses: number;
    role: string;
}

export interface IWarehouseParams {
    warehouseId: number;
    status: string;
    sort: string;
    sortField: string;
    queryString: string;
    page: number;
    size: number;
}
export interface IWarehouseItem {
    userName: string;
    email: string;
    userId: string;
    warehouses: number;
    role: string;
}
