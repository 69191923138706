import { PageHeader } from '@gather/components';
import { Query } from '@gather/constants';
import { InventoryProvider } from '@gather/contexts';
import { InventoryFilter, InventoriesList } from '@gather/features';
import { useSearchParams } from 'react-router-dom';
import './styles.less';

export function SearchInventories() {
    const [searchParams] = useSearchParams();

    const searchKey = searchParams.get('search');

    function actions() {
        return [
            <InventoryFilter.AggregatedBy />,
            <InventoryFilter.Verification />,
            <InventoryFilter.Date />,
            <InventoryFilter.Display />,
        ];
    }
    return (
        <div className="search-screen">
            <InventoryProvider>
                <PageHeader title={`Search Results for '${searchKey}'`} actions={actions()} />
                <InventoriesList queryKey={Query.exceptions} />
            </InventoryProvider>
        </div>
    );
}
