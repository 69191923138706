import { API } from '@gather/constants';
import { AXIOS } from '@gather/middlewares';

async function fetchWarehouses() {
    const response = await AXIOS.get(API.format(API.key.global.warehouse));
    return response.data;
}

async function getTenant(domain: string) {
    const response = await AXIOS.post(API.key.global.tenant.get, { domain });
    return response.data;
}

export const GlobalService = Object.freeze({
    fetchWarehouses,
    getTenant,
});
