import { IInventoryItem, ILocationProductItem } from '@gather/types';
import moment, { Moment } from 'moment-timezone';

export const DateTime = {
    format: {
        date: (value?: Moment | Date | string | null): string => {
            if (value) return moment(value).format('YYYY-MM-DD');
            return '';
        },
        time: (value?: Moment | Date | string | null): string => {
            if (value) return moment(value).format('hh:mm A');
            return '';
        },
        dateTime: (value?: Moment | Date | Date | string | null): string => {
            if (value) return moment(value).format('YYYY-MM-DD hh:mm A');
            return '';
        },
    },
};

export function getVerificationStatus(inventory: IInventoryItem | ILocationProductItem) {
    let status = 'Pending';
    if (inventory.verifiedRecord) {
        if (inventory.correctness) {
            status = 'Valid';
        } else {
            status = 'Invalid';
        }
    }
    return status;
}
