import { useContext } from 'react';
import { PageHeader } from '@gather/components';
import { InventoryFilters, Query } from '@gather/constants';
import { InventoryProvider, MissionContext } from '@gather/contexts';
import { InventoriesList, InventoryFilter } from '@gather/features';
import { MissionLocationDetails } from './mission-location-details';

export function Inventories() {
    const { selectedMission } = useContext(MissionContext);

    function actions() {
        return [<></>, <InventoryFilter.Display />];
    }
    return (
        <div className="inventories-table-wrapper dashboard-section">
            <InventoryProvider>
                <PageHeader title="Inventories" actions={actions()} />
                <InventoriesList
                    queryKey={Query.missionInventories}
                    missionId={selectedMission?.id}
                    enabledFilters={[InventoryFilters.display]}
                    missionInventories
                />
                <MissionLocationDetails />
            </InventoryProvider>
        </div>
    );
}
