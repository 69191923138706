import { API } from '@gather/constants';
import { AXIOS } from '@gather/middlewares';
import { IMembersParams } from '@gather/types';

async function getMembers(params: IMembersParams) {
    const response = await AXIOS.post(API.key.members.list, params);
    return response.data;
}

export const MemberService = Object.freeze({
    getMembers,
});
