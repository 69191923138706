import { useEffect, useState } from 'react';
import { ITab, Tabs } from '../tabs';
import './styles.less';
import { Input } from 'antd';
import { MdOutlineNotificationsNone, MdOutlineSettings, MdOutlineSearch } from 'react-icons/md';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from '@gather/routes';

export function AppHeader() {
    const [searchParams] = useSearchParams();

    const [searchKey, setSearchKey] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const searchKey = searchParams.get('search') || '';
        setSearchKey(searchKey);
    }, [searchParams]);

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            const _searchKey = searchKey.trim();
            if(!_searchKey || _searchKey === "") return;
            setSearchKey(_searchKey);
            navigate({
                pathname: ROUTES.search.dafault,
                search: createSearchParams({
                    search: _searchKey,
                }).toString(),
            });
        }
    }

    return (
        <header className="app-header">
            <div className="current-history-tabs">
                <CurrentHistoryTab />
            </div>
            <div className="search-input">
                <Input
                    prefix={<MdOutlineSearch className="search-icon" />}
                    placeholder="Search Missions, Locations , Products, Code"
                    bordered={false}
                    value={searchKey}
                    autoComplete="off"
                    autoCorrect="off"
                    onChange={(e) => setSearchKey(e.currentTarget.value)}
                    onKeyDown={handleKeyDown}
                />
            </div>
            <div className="actions">
                <HeaderActions />
            </div>
        </header>
    );
}

const tabs: ITab[] = [
    { name: 'Current', id: 1 },
    { name: 'History', id: 2 },
];

function CurrentHistoryTab() {
    const [activeTab, setActiveTab] = useState(tabs[0]);
    return <Tabs tabs={tabs} value={activeTab} onChange={setActiveTab} />;
}

function HeaderActions() {
    return (
        <div className="header-actions flex flex-row">
            <div className="action-icon">
                <MdOutlineNotificationsNone />
            </div>
            <div className="action-icon">
                <MdOutlineSettings />
            </div>
        </div>
    );
}

interface IPageHeaderProps {
    title: string;
    subTitle?: string;
    actions?: JSX.Element[];
}

export function PageHeader({ title, subTitle, actions }: IPageHeaderProps) {
    return (
        <header className="page-header">
            <div className="page-title-wrapper">
                <span className="page-title">{title}</span>
                {subTitle && <span className="page-sub-title">{subTitle}</span>}
            </div>
            <div className="header-spacer"></div>
            {actions && (
                <div className="header-actions">
                    {actions.map((action, i) => (
                        <div key={`action-item-${i}`} className="action-item">
                            {action}
                        </div>
                    ))}
                </div>
            )}
        </header>
    );
}
