import './styles.less';
import logoExpanded from '@gather/assets/images/logo_with_name.png';
import logoCollapsed from '@gather/assets/images/logo.svg';
import { ReactComponent as Chevron } from '@gather/assets/images/chevron.svg';
import {
    MdControlCamera,
    MdSpaceDashboard,
    MdInsights,
    MdOutlineInventory,
    MdOutlineErrorOutline,
    MdPerson,
    MdOutlineLogout,
} from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from '@gather/routes';
import { usePersist } from '@gather/hooks';
import { LOCAL_STORAGE_KEYS } from '@gather/constants';
import { Auth0 } from '@gather/utils';

const LOGO = {
    expanded: logoExpanded,
    collapsed: logoCollapsed,
};

interface IMenuOption {
    title: string;
    icon: JSX.Element;
    path: string;
}

const MENU: { options: IMenuOption[]; actions: IMenuOption[] } = {
    options: [
        {
            title: 'Mission Dashboard',
            icon: <MdSpaceDashboard className="menu-item-icon" />,
            path: ROUTES.dashboard.dafault,
        },
        {
            title: 'Insights',
            icon: <MdInsights className="menu-item-icon" />,
            path: ROUTES.insights.dafault,
        },
        {
            title: 'Inventory',
            icon: <MdOutlineInventory className="menu-item-icon" />,
            path: ROUTES.inventory.dafault,
        },
        {
            title: 'Exceptions',
            icon: <MdOutlineErrorOutline className="menu-item-icon" />,
            path: ROUTES.exceptions.dafault,
        },
        {
            title: 'Drone Dashboard',
            icon: <MdControlCamera className="menu-item-icon" />,
            path: ROUTES.droneDashboard.dafault,
        },
    ],
    actions: [
        {
            title: 'Profile',
            icon: <MdPerson className="menu-item-icon" />,
            path: ROUTES.profile.dafault,
        },
        {
            title: 'Sign Out',
            icon: <MdOutlineLogout className="menu-item-icon" />,
            path: ROUTES.logout,
        },
    ],
};

export function Sidebar() {
    const [collapsed, setCollapsed] = usePersist(LOCAL_STORAGE_KEYS.DRAWER, true);
    const location = useLocation();

    function toggleCollapse() {
        setCollapsed(!collapsed);
    }

    function onLogout() {
        Auth0.token.revoke();
        window.location.href = ROUTES.root;
    }

    function renderMenuItem(item: IMenuOption, key: string) {
        const { title, icon, path } = item;
        return (
            <li key={key} className={location.pathname.startsWith(path) ? 'active menu-item' : 'menu-item'}>
                <Link onClick={path === ROUTES.logout ? onLogout : undefined} to={path} className={`menu-item-link`}>
                    <div className="menu-item-icon-wrapper">
                        {icon}
                        <span className="tooltip">{title}</span>
                    </div>
                    <span className="menu-item-title">{title}</span>
                </Link>
            </li>
        );
    }

    return (
        <nav className={`side-bar ${collapsed ? 'collapse' : ''}`} onClick={toggleCollapse}>
            <div className="logo-wrapper">
                <img src={collapsed ? LOGO.collapsed : LOGO.expanded} className="logo" alt="Gather AI" srcSet="" />
            </div>
            <ul onClick={(e) => e.stopPropagation()} className="option-list menu-option-list">
                {MENU.options.map((option, i) => renderMenuItem(option, `menu-item-${i}`))}
            </ul>
            <div className="flex-1"></div>
            <ul onClick={(e) => e.stopPropagation()} className="option-list menu-action-list">
                {MENU.actions.map((option, i) => renderMenuItem(option, `menu-action-item-${i}`))}
            </ul>
            <span className="expand-collapse-icon">
                <Chevron className={collapsed ? 'chevron __flip' : 'chevron'} />
            </span>
        </nav>
    );
}
