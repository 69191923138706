import { ColumnsType } from 'antd/lib/table';
import moment, { Moment } from 'moment-timezone';
import { createContext, useState } from 'react';
import { MdOutlineDelete, MdOutlineDescription, MdOutlineFactCheck } from 'react-icons/md';
import { ActionMenu, IDropdownOption } from '@gather/components';
import { IMissionItem } from '@gather/types';
import { usePersist } from '@gather/hooks';
import { LOCAL_STORAGE_KEYS } from '@gather/constants';
import { DateTime, deleteMission, downloadCumulativeCSV, downloadWMS } from '@gather/utils';
import confirm from 'antd/lib/modal/confirm';

export interface IMissionData {
    selectedMission?: IMissionItem;
    date?: { date: Moment[] | null; quickSelect?: number } | undefined;
    displayOptions: IDropdownOption[];
    warehouse?: IDropdownOption | null;
    setSelectedMission: (mission: IMissionItem) => void;
    setDate: (date: { date: Moment[] | null; quickSelect?: number } | undefined) => void;
    setDisplayOptions: (displayOptions: IDropdownOption[]) => void;
    setWarehouse: (displayOptions: IDropdownOption | null) => void;
}

interface IProps {
    children?: React.ReactNode;
}

function renderDate(value: string, mission: IMissionItem, index: number) {
    return <>{moment(value).format('MM/DD/YYYY')}</>;
}

function renderTime(value: string, mission: IMissionItem, index: number) {
    return <>{DateTime.format.time(value)}</>;
}

function renderAction(value: string, mission: IMissionItem, index: number) {
    return (
        <ActionMenu
            options={[
                {
                    onClick: () => {
                        downloadCumulativeCSV({ mission: mission });
                    },
                    title: 'Download Mission CSV',
                    icon: <MdOutlineDescription />,
                },
                {
                    onClick: () => {
                        downloadWMS(mission.wmsFileName);
                    },
                    disabled: !mission.wmsFileName,
                    title: 'Download WMS',
                    icon: <MdOutlineFactCheck />,
                },
                {
                    onClick: () => {
                        deleteMission(mission.id);
                    },
                    title: 'Delete Mission',
                    icon: <MdOutlineDelete />,
                },
            ]}
        />
    );
}

export const missionColumns: ColumnsType<IMissionItem> = [
    {
        title: 'Mission',
        dataIndex: 'name',
        key: 'name',
        sorter: {
            multiple: 2,
        },
        ellipsis: true,
    },
    {
        title: 'Bin Scanned',
        dataIndex: 'binSelected',
        key: 'binSelected',
        sorter: {
            multiple: 2,
        },
        ellipsis: true,
    },
    {
        title: 'Date',
        dataIndex: 'createdOn',
        key: 'date',
        render: renderDate,
        sorter: {
            multiple: 2,
        },
        ellipsis: true,
    },
    {
        title: `Time (${moment().zoneName() || 'CDT'})`,
        dataIndex: 'createdOn',
        key: 'time',
        render: renderTime,
        ellipsis: true,
    },
    {
        title: 'Warehouse',
        dataIndex: 'warehouse',
        key: 'warehouse',
        sorter: {
            multiple: 2,
        },
        ellipsis: true,
    },
    {
        title: '',
        className: 'action-item-cell',
        dataIndex: 'action',
        key: 'action-item',
        render: renderAction,
    },
];

const missionData: IMissionData = {
    displayOptions: [],
    setSelectedMission: (_: IMissionItem) => {},
    setDate: (_: { date: Moment[] | null; quickSelect?: number } | undefined) => {},
    setDisplayOptions: (_: IDropdownOption[]) => {},
    setWarehouse: (_: IDropdownOption | null) => {},
};

export const MissionContext = createContext<IMissionData>(missionData);

export function MissionProvider({ children }: IProps) {
    const [selectedMission, setSelectedMission] = useState<IMissionItem>();

    const [warehouse, setWarehouse] = usePersist<IDropdownOption | null>(LOCAL_STORAGE_KEYS.MISSION_WAREHOUSE_FILTER);
    const [date, setDate] = usePersist<{ date: Moment[] | null; quickSelect?: number } | undefined>(
        LOCAL_STORAGE_KEYS.MISSION_DATE_FILTER
    );

    const [displayOptions, setDisplayOptions] = usePersist<IDropdownOption[]>(
        LOCAL_STORAGE_KEYS.MISSION_DISPLAY_FILTER,
        missionColumns.map<IDropdownOption>((_inv, index) => ({
            label: `${_inv.title || ''}`,
            value: _inv.key,
        })) || []
    );

    return (
        <MissionContext.Provider
            value={{
                selectedMission,
                setSelectedMission,
                date,
                setDate,
                displayOptions,
                setDisplayOptions,
                warehouse,
                setWarehouse,
            }}
        >
            {children}
        </MissionContext.Provider>
    );
}
