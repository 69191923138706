import { EXCEPTION_COLOR, EXCEPTION_TYPE, IGNORE_TYPES } from '@gather/constants';
import { ReactComponent as IconError } from '@gather/assets/images/exception-icons/ic_error_new.svg';
import { ReactComponent as IconErrorQuestion } from '@gather/assets/images/exception-icons/ic_error_question.svg';
import { ReactComponent as IconMatch } from '@gather/assets/images/exception-icons/ic_match.svg';
import { ReactComponent as IconErrorGreen } from '@gather/assets/images/exception-icons/ic_error_new_green.svg';
import { ReactComponent as IconErrorQuestionGreen } from '@gather/assets/images/exception-icons/ic_error_question_green.svg';
import { ReactComponent as MultideepGreenIcon } from '@gather/assets/images/exception-icons/multi-deep-green.svg';
import { ReactComponent as PickIcon } from '@gather/assets/images/exception-icons/pick.svg';
// import { ReactComponent as MultideepRedIcon } from '@gather/assets/images/exception-icons/multi-deep-red.svg';
import { Tooltip } from 'antd';
import './styles.less';

interface IProductNameProps {
    exception: string;
    name: string;
    valid?: boolean;
}

interface IExceptionData {
    icon: React.ReactNode;
    tooltip: string;
    status: string;
    color: EXCEPTION_COLOR;
}

export function ProductName(props: IProductNameProps) {
    const { exception, valid, name } = props;
    const exceptionData = getIconData(exception, valid);
    function renderIcon() {
        if (IGNORE_TYPES.includes(name)) {
            return null;
        }
        return (
            <Tooltip className="exception-icon-wrapper" placement="right" color="white" title={exceptionData.tooltip}>
                {exceptionData.icon}
            </Tooltip>
        );
    }
    return (
        <div className="product-name-wrapper">
            <div className="product-name-label">{name}</div>
            {renderIcon()}
        </div>
    );
}

export function InventoryStatus(props: IProductNameProps) {
    const { exception, valid, name } = props;
    const exceptionData = getIconData(exception, valid);
    function renderIcon() {
        if (IGNORE_TYPES.includes(name)) {
            return null;
        }
        return (
            <Tooltip className="exception-icon-wrapper" placement="right" color="white" title={exceptionData.tooltip}>
                {exceptionData.icon}
            </Tooltip>
        );
    }
    function renderStatus() {
        if (IGNORE_TYPES.includes(name)) {
            return <span>Not Applicable</span>;
        }
        return exceptionData.status;
    }
    return (
        <div className="status-wrapper">
            {renderIcon()}
            <div className="product-name-label">{renderStatus()}</div>
        </div>
    );
}

function getIconData(exception: EXCEPTION_TYPE | string, valid: boolean = false): IExceptionData {
    let exceptionData: IExceptionData = {
        icon: <IconError fill={EXCEPTION_COLOR.MATCH} />,
        tooltip: 'Unknown',
        status: 'Unknown',
        color: EXCEPTION_COLOR.MATCH,
    };
    switch (exception) {
        case EXCEPTION_TYPE.DRONE_ONLY:
        case EXCEPTION_TYPE.DEFAULT_DRONE_ONLY:
        case EXCEPTION_TYPE.MULTIDEEP_DRONE_ONLY_EXCEPTION:
        case EXCEPTION_TYPE.PICK_DRONE_ONLY_EXCEPTION: {
            const color = valid ? EXCEPTION_COLOR.MATCH : EXCEPTION_COLOR.ERROR;
            exceptionData = {
                icon: valid ? <IconErrorGreen fill={color} /> : <IconError fill={color} />,
                tooltip: `Drone found a Code here. That Code isn't present in the WMS file.`,
                status: 'Product not in WMS',
                color: color,
            };
            break;
        }
        case EXCEPTION_TYPE.WMS_ONLY:
        case EXCEPTION_TYPE.DEFAULT_WMS_ONLY:
        case EXCEPTION_TYPE.MULTIDEEP_WMS_ONLY_EXCEPTION:
        case EXCEPTION_TYPE.PICK_WMS_ONLY_EXCEPTION: {
            const color = valid ? EXCEPTION_COLOR.MATCH : EXCEPTION_COLOR.QUESTION_ERROR;
            exceptionData = {
                icon: valid ? <IconErrorQuestionGreen fill={color} /> : <IconErrorQuestion fill={color} />,
                tooltip: `Drone didn't find a code here. WMS has a code here.`,
                status: 'Missing item',
                color: color,
            };
            break;
        }
        case EXCEPTION_TYPE.WMS_AND_DRONE:
        case EXCEPTION_TYPE.DEFAULT_WMS_AND_DRONE:
        case EXCEPTION_TYPE.MULTIDEEP_WMS_AND_DRONE_NON_EXCEPTION: {
            exceptionData = {
                icon: <IconMatch fill={EXCEPTION_COLOR.MATCH} />,
                tooltip: `Drone found a code here, which matches with the WMS file.`,
                status: 'Match',
                color: EXCEPTION_COLOR.MATCH,
            };
            break;
        }
        case EXCEPTION_TYPE.MULTIDEEP_WMS_ONLY_NON_EXCEPTION: {
            exceptionData = {
                icon: <MultideepGreenIcon fill={EXCEPTION_COLOR.MATCH} />,
                tooltip: `The product may be lying behind a front-facing pallet. Drone didn't find a code here. WMS has a code here.`,
                status: 'MultiDeep Hidden',
                color: EXCEPTION_COLOR.MATCH,
            };
            break;
        }
        case EXCEPTION_TYPE.PICK_WMS_AND_DRONE_NON_EXCEPTION:
        case EXCEPTION_TYPE.PICK_DRONE_ONLY_NON_EXCEPTION:
        case EXCEPTION_TYPE.PICK_WMS_ONLY_NON_EXCEPTION: {
            exceptionData = {
                icon: <PickIcon fill={EXCEPTION_COLOR.MATCH} />,
                tooltip: `This is a pick location. Pick locations do not trigger a match or an exception.`,
                status: 'Pick Location',
                color: EXCEPTION_COLOR.MATCH,
            };
            break;
        }
        case EXCEPTION_TYPE.USER_INPUT: {
            exceptionData = {
                icon: <IconMatch fill={EXCEPTION_COLOR.MATCH} />,
                tooltip: 'Added By User',
                status: 'Added By User',
                color: EXCEPTION_COLOR.MATCH,
            };
            break;
        }
        default:
            return exceptionData;
    }
    return exceptionData;
}
