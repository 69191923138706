import { useContext, useState, useEffect } from 'react';
import { IDropdownOption } from '@gather/components';
import { InventoryContext } from '@gather/contexts';
import { IInventoriesParams, InventoryService, IAggregateKey } from '@gather/services';
import { IInventoryItem } from '@gather/types';
import { DateTime } from '@gather/utils';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { LoadingOutlined } from '@ant-design/icons';

interface IExpandedTableParams {
    inventory: IInventoryItem;
    expanded: boolean;
    params: IInventoriesParams;
    columns: ColumnsType<IInventoryItem>;
    aggregateBy: IDropdownOption | null | undefined;
}

export function ExpandedList(props: IExpandedTableParams) {
    const { columns, params: _params, aggregateBy, inventory } = props;
    const { selectedInventory, setSelectedInventory } = useContext(InventoryContext);

    const [params, setParams] = useState<IInventoriesParams>({
        ..._params,
        pagenumber: 0,
        pagesize: 2,
    });

    const [loading, setLoading] = useState(false);
    const [haveMore, setHaveMore] = useState(false);

    const [inventories, setInventories] = useState<IInventoryItem[]>([]);

    useEffect(() => {
        if (_params) {
            setInventories([]);
            setParams({
                ..._params,
                pagenumber: 1,
                pagesize: 30,
            });
        }
    }, [_params]);

    useEffect(() => {
        if (params && params.pagenumber > 0) {
            fetchData(params);
        }
    }, [params]);

    async function fetchData(_params: IInventoriesParams) {
        setLoading(true);
        try {
            const data = await InventoryService.fetchAggregatedInventories(
                _params,
                getAggregateValue(aggregateBy?.value, inventory)
            );
            if (data?.response) {
                setInventories([...inventories, ...data.response.products]);
                setHaveMore(data?.response?.hasMoreData || false);
            }
        } catch (err) {
            console.error(err);
        }
        setLoading(false);
    }

    function hanldeLoadMore() {
        setParams({
            ...params,
            pagenumber: params.pagenumber + 1,
        });
    }

    function renderTable() {
        if (loading && params.pagenumber === 1) return null;
        return (
            <Table
                className="inventories-sub-table"
                columns={columns}
                dataSource={inventories}
                pagination={false}
                rowKey="id"
                rowSelection={{
                    selectedRowKeys: selectedInventory?.id ? [selectedInventory?.id] : undefined,
                }}
                onRow={(row: IInventoryItem) => {
                    return {
                        onClick: (event) => {
                            const isPreSelected = selectedInventory?.id === row.id;
                            setSelectedInventory({
                                ...row,
                                selected: isPreSelected,
                            });
                        },
                    };
                }}
            />
        );
    }

    function renderLoadMoreButton() {
        if (!loading && !haveMore) return null;
        return (
            <div className="load-more-button" onClick={haveMore ? hanldeLoadMore : undefined}>
                {loading ? <LoadingOutlined className="loading-icon" /> : haveMore && <>Load More</>}
            </div>
        );
    }

    return (
        <div className="flex flex-col">
            {renderTable()}
            {renderLoadMoreButton()}
        </div>
    );
}

function getAggregateValue(key: string, inventory: IInventoryItem): IAggregateKey {
    let value: any = (inventory as any)[key];
    switch (key) {
        case 'date':
            value = DateTime.format.date(inventory.scannedOn);
            break;
        case 'location':
            value = inventory.binCode;
            break;
        default:
            value = (inventory as any)[key];
            break;
    }
    return { key, value };
}
