import { PageHeader } from '@gather/components';
import { Query } from '@gather/constants';
import { InventoryProvider } from '@gather/contexts';
import { InventoriesList, InventoryFilter } from '@gather/features';
import { InventoriesLocationDetails } from './inventories-location-details';
import './styles.less';

export function Inventory() {
    function actions() {
        return [
            <InventoryFilter.AggregatedBy />,
            <InventoryFilter.Verification />,
            <InventoryFilter.Date />,
            <InventoryFilter.Display />,
        ];
    }
    return (
        <div className="inventory-screen">
            <InventoryProvider>
                <PageHeader title="Inventories" actions={actions()} />
                <InventoriesList queryKey={Query.inventories} />
                <InventoriesLocationDetails />
            </InventoryProvider>
        </div>
    );
}
