import { IDeleteInventoryParams, InventoryService } from '@gather/services';
import { message } from 'antd';
import confirm from 'antd/lib/modal/confirm';

export async function deleteInventory(id?: string, onDeleteSuccess?: () => void) {
    return confirm({
        title: 'Are you sure you want to delte the product?',
        onOk: async () => {
            if (id) {
                const params: IDeleteInventoryParams = {
                    inventoryItemIds: [id],
                    userDeleted: true,
                };
                try {
                    await InventoryService.deleteInventory(params);
                    onDeleteSuccess && onDeleteSuccess();
                    message.success('The product has been deleted successfully!');
                } catch (err) {
                    message.error('Failed to delete the product!');
                    console.error(err);
                }
            }
        },
        centered: true,
        maskClosable: true,
        okText: 'YES',
        cancelText: 'NO',
    });
}
