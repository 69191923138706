import { Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Query } from '@gather/constants';
import { IUpdateProductCodeParams, LocationService } from '@gather/services';
import { ILocationProductItem } from '@gather/types';
import { queryClient } from '@gather/configs';
import { LocationContext } from '@gather/contexts';

interface IProps {
    code: string;
    product: ILocationProductItem;
}

export function ProductCode(props: IProps) {
    const { code, product } = props;
    const { params } = useContext(LocationContext);
    const [productCode, setProductCode] = useState('');

    useEffect(() => {
        setProductCode(code);
    }, [code]);

    const { isLoading, mutate } = useMutation(updateProductCode, {
        mutationKey: Query.updateProductCode,
        onSuccess: () => {
            // updating the latest product data to the product list(location details) react-query data
            const updatedProduct = {
                ...product,
                productCode: productCode,
            };
            const key = [Query.products, params];
            queryClient.setQueryData(key, (oldData: any) => {
                return {
                    ...oldData,
                    response: {
                        ...oldData.response,
                        products: oldData.response.products.map((_product: ILocationProductItem) => {
                            if (_product.id === updatedProduct.id) {
                                return updatedProduct;
                            }
                            return _product;
                        }),
                    },
                };
            });
            // END - updating the latest product data to the product list(location details) react-query data
        },
        onError: () => {
            setProductCode(code);
        },
    });

    function updateProductCode(params: IUpdateProductCodeParams) {
        return LocationService.updateProductCode(params);
    }

    function triggerMutation() {
        if (product.items?.[0]?.itemId && code !== productCode) {
            const params: IUpdateProductCodeParams = {
                barcode: code,
                inventoryItemIds: [product.items[0].itemId],
                version: product.version,
            };
            mutate(params);
        }
    }

    function handleBlur(e: React.FocusEvent<HTMLInputElement, Element>) {
        if (e.currentTarget.value !== code) {
            triggerMutation();
        }
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            triggerMutation();
        }
    }

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        setProductCode(e.currentTarget.value);
    }
    return (
        <Input
            addonAfter={isLoading && <LoadingOutlined />}
            disabled={isLoading}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            value={productCode}
            onChange={onChange}
            placeholder="Product Code"
        />
    );
}
