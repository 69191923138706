import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { CACHE } from '@gather/configs';
import { Query, CASE_COUNT } from '@gather/constants';
import { LocationContext } from '@gather/contexts';
import { ITiHiParams, LocationService } from '@gather/services';
import { ITiHiData } from '@gather/types';

interface ITiHiProperty {
    id: number;
    name: string;
    permissionKey: string;
    accessor: (data: ITiHiData) => string | number;
}

export function CauseCountSection() {
    const { selectedProduct } = useContext(LocationContext);

    const [params, setParams] = useState<ITiHiParams>({
        binCode: '',
        binId: 0,
        missionId: 0,
    });
    // const [enabledProperties, setEnabledProperties] = useState(
    //     getEnabledProperties(TI_HI.properties)
    // );

    const enabledProperties = getEnabledProperties(CASE_COUNT.properties);

    const { data } = useQuery([Query.tiHi, params], () => fetchData(params), {
        cacheTime: CACHE.time['5mins'],
        enabled: selectedProduct?.binId ? true : false,
    });

    useEffect(() => {
        if (selectedProduct) {
            setParams({
                binCode: selectedProduct.binCode,
                binId: selectedProduct.binId,
                missionId: selectedProduct.missionId,
            });
        }
    }, [selectedProduct]);

    function fetchData(_params: ITiHiParams) {
        return LocationService.fetchTiHiData(_params);
    }

    const tiHiData: ITiHiData = data?.data;

    function renderTiHiItem(item: ITiHiProperty) {
        return (
            <div key={item.id} className="case-count-item">
                <span className="data-label">{item.name}</span>
                <span className="data-value">{tiHiData ? item.accessor(tiHiData) : '-'}</span>
            </div>
        );
    }
    return (
        <div className="case-count-section">
            <div className="case-count-header">Case count Detail</div>
            <div className="case-count-content">{enabledProperties.map(renderTiHiItem)}</div>
        </div>
    );
}

function hasPermission(key: string) {
    return true;
}

function getEnabledProperties(properties: ITiHiProperty[]) {
    const _enabledProperties: ITiHiProperty[] = properties.filter((property) => hasPermission(property.permissionKey));
    CASE_COUNT.verticalSiblings.forEach((siblings) => {
        const topIndex = _enabledProperties.findIndex((_property) => _property.id === siblings.top);
        const bottomIndex = _enabledProperties.findIndex((_property) => _property.id === siblings.bottom);
        if (topIndex >= 0 && bottomIndex >= 0 && topIndex + 2 < _enabledProperties.length) {
            const bottomItem = _enabledProperties[bottomIndex];
            _enabledProperties.splice(bottomIndex, 1);
            if (topIndex + 2 < _enabledProperties.length) {
                _enabledProperties.splice(topIndex + 2, 0, bottomItem);
            } else {
                _enabledProperties.push(bottomItem);
            }
        }
    });
    return _enabledProperties;
}
