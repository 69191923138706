const MULTIDEEP_TYPE = Object.freeze({
    DEFAULT: 'default',
    MULTIDEEP: 'multiDeep',
    PICK: 'pick',
});

const RESPONSE = Object.freeze({
    SUCCESS: 'Success',
    PENDING: 'Pending',
    FAILED: 'Failed',
});

//Status as User Inout
const USER_INPUT = 'userinput';

export enum EXCEPTION_TYPE {
    //Status as Record Type
    DRONE_ONLY = 'droneonly',
    WMS_ONLY = 'wmsonly',
    WMS_AND_DRONE = 'wmsanddrone',

    //Status as DEFAULT
    DEFAULT_WMS_ONLY = 'defaultwmsonly',
    DEFAULT_DRONE_ONLY = 'defaultdroneonly',
    DEFAULT_WMS_AND_DRONE = 'defaultwmsanddrone',

    //Status as PICK
    PICK_WMS_ONLY_NON_EXCEPTION = 'pickwmsonlynonexception',
    PICK_DRONE_ONLY_NON_EXCEPTION = 'pickdroneonlynonexception',
    PICK_WMS_ONLY_EXCEPTION = 'pickwmsonlyexception',
    PICK_DRONE_ONLY_EXCEPTION = 'pickdroneonlyexception',
    PICK_WMS_AND_DRONE_NON_EXCEPTION = 'pickwmsanddronenonexception',

    //Status as MULTIDEEP
    MULTIDEEP_WMS_ONLY_NON_EXCEPTION = 'multideepwmsonlynonexception',
    MULTIDEEP_DRONE_ONLY_NON_EXCEPTION = 'multideepdroneonlynonexception',
    MULTIDEEP_WMS_ONLY_EXCEPTION = 'multideepwmsonlyexception',
    MULTIDEEP_DRONE_ONLY_EXCEPTION = 'multideepdroneonlyexception',
    MULTIDEEP_WMS_AND_DRONE_NON_EXCEPTION = 'multideepwmsanddronenonexception',

    //Status as User Input
    USER_INPUT = 'userinput',
}

// All Exceptions
export const EXECPTION_TYPES = Object.freeze({
    NON_EXECPTION: [
        EXCEPTION_TYPE.WMS_AND_DRONE,
        EXCEPTION_TYPE.DEFAULT_WMS_AND_DRONE,
        EXCEPTION_TYPE.MULTIDEEP_WMS_ONLY_NON_EXCEPTION,
        EXCEPTION_TYPE.MULTIDEEP_DRONE_ONLY_NON_EXCEPTION,
        EXCEPTION_TYPE.MULTIDEEP_WMS_AND_DRONE_NON_EXCEPTION,
        EXCEPTION_TYPE.PICK_WMS_ONLY_NON_EXCEPTION,
        EXCEPTION_TYPE.PICK_DRONE_ONLY_NON_EXCEPTION,
        EXCEPTION_TYPE.PICK_WMS_AND_DRONE_NON_EXCEPTION,
        EXCEPTION_TYPE.USER_INPUT,
    ],
    EXECPTION: [
        EXCEPTION_TYPE.DRONE_ONLY,
        EXCEPTION_TYPE.WMS_ONLY,
        EXCEPTION_TYPE.DEFAULT_WMS_ONLY,
        EXCEPTION_TYPE.DEFAULT_DRONE_ONLY,
        EXCEPTION_TYPE.MULTIDEEP_WMS_ONLY_EXCEPTION,
        EXCEPTION_TYPE.MULTIDEEP_DRONE_ONLY_EXCEPTION,
        EXCEPTION_TYPE.PICK_WMS_ONLY_EXCEPTION,
        EXCEPTION_TYPE.PICK_DRONE_ONLY_EXCEPTION,
    ],
});

export enum EXCEPTION_COLOR {
    ERROR = '#FF903E',
    MATCH = '#39E8BE',
    USER = '#0995fe',
    QUESTION_ERROR = '#FF4444',
}

export const IGNORE_TYPES = ['Box', 'noCodeRead'];
