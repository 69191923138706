import { IDropdownOption } from '../dropdown';
import './styles.less';

interface IPopoverListProps {
    options: IDropdownOption[];
    onSelect: (option: IDropdownOption) => void;
}
export function PopoverList(props: IPopoverListProps) {
    const { options, onSelect } = props;
    function renderOption(option: IDropdownOption, index: number) {
        const { disabled } = option;
        return (
            <div
                key={`popover-opt-${index}`}
                className={`popover-list-option ${disabled ? 'disabled-button' : ''}`}
                onClick={() => onSelect(option)}
            >
                {option.icon ? <div className="option-icon">{option.icon}</div> : null}
                <div className="option-label">{option.label}</div>
            </div>
        );
    }
    return <div className="popover-list">{options.map(renderOption)}</div>;
}
