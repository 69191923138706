import { API } from '@gather/constants';
import { AXIOS } from '@gather/middlewares';

interface IIframeParams {
    tenants: string[];
}

async function getIFrames(params: IIframeParams) {
    const response = await AXIOS.get(API.key.insights.iframes.get, { params: params });
    return response.data;
}

export const InsightsService = Object.freeze({
    getIFrames,
});
