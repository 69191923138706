import { API } from '@gather/constants';
import { AXIOS } from '@gather/middlewares';

async function userInfo() {
    const response = await AXIOS.get(API.key.auth0.userInfo);
    return response.data;
}

export const Auth0Service = Object.freeze({
    userInfo,
});
