import { PageHeader } from '@gather/components';
import { DroneProvider } from '@gather/contexts';
import { DroneList, DroneFilter } from '@gather/features';
import './styles.less';

export function DroneDashboard() {
    return (
        <div className="drone-dashboard">
            <DroneProvider>
                <Drones />
            </DroneProvider>
        </div>
    );
}

export function Drones() {
    function actions() {
        return [<DroneFilter.Warehouse />, <DroneFilter.Date />, <DroneFilter.Display />];
    }
    return (
        <div className="drones-table-wrapper dashboard-section">
            <PageHeader title="Drones" actions={actions()} />
            <DroneList />
        </div>
    );
}
