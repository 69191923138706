import { MissionProvider } from '@gather/contexts';
import { Inventories } from './inventories';
import { Missions } from './mission';
import './styles.less';

export function MissionDashboard() {
    return (
        <div className="mission-dashboard">
            <MissionProvider>
                <Missions />
                <Inventories />
            </MissionProvider>
        </div>
    );
}
