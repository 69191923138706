import { useContext, useState } from 'react';
import './styles.less';
import IframeResizer from 'iframe-resizer-react';
import { useQuery } from 'react-query';
import { LOCAL_STORAGE_KEYS, Query } from '@gather/constants';
import { InsightsService } from '@gather/services';
import { usePersist } from '@gather/hooks';
import { InsightsFilter } from './filters';
import { GlobalContext } from '@gather/contexts';
import { PageHeader } from '@gather/components';
import { Skeleton } from 'antd';

export function Insights() {
    const [loading, setLoading] = useState(true);
    const { tenant } = useContext(GlobalContext);

    const [selectedFacilities, setSelectedFacilities] = usePersist<string[]>(LOCAL_STORAGE_KEYS.FACILITIES, [
        tenant.tenant,
    ]);

    const { data, isLoading } = useQuery(
        [Query.insights, selectedFacilities],
        () => fetchIframeURLs(selectedFacilities),
        {
            enabled: selectedFacilities.length > 0,
            keepPreviousData: true,
        }
    );

    function fetchIframeURLs(facilities: string[]) {
        return InsightsService.getIFrames({ tenants: facilities.map((_facility) => _facility) });
    }

    function onAfterInit() {
        loading &&
            setTimeout(() => {
                loading && setLoading(false);
            }, 1000);
    }

    const _loading = loading || isLoading;

    function renderTrends() {
        return (
            <IframeResizer
                id="trends-iframe"
                onInit={onAfterInit}
                onAbort={onAfterInit}
                onError={onAfterInit}
                heightCalculationMethod="lowestElement"
                sizeHeight
                checkOrigin={false}
                bodyBackground="transparent"
                src={data?.trends}
                title="Metabase Manage Visualizations"
                frameBorder="0"
                allowTransparency
            />
        );
    }
    function renderInsights() {
        return (
            <IframeResizer
                id="insights-iframe"
                onInit={onAfterInit}
                onAbort={onAfterInit}
                onError={onAfterInit}
                heightCalculationMethod="lowestElement"
                sizeHeight
                checkOrigin={false}
                bodyBackground="transparent"
                src={data?.insights}
                title="Metabase Manage Visualizations"
                frameBorder="0"
                allowTransparency
            />
        );
    }

    function renderActions() {
        return [
            <InsightsFilter.Facilitites
                options={data?.tenants || selectedFacilities}
                values={selectedFacilities}
                setValues={setSelectedFacilities}
                loading={_loading}
            />,
        ];
    }

    function renderCharts() {
        return (
            <>
                {_loading && <InsightsSkeleton />}
                <div className={`insights-charts ${_loading ? 'insights-loading overflow-hidden' : ''}`}>
                    {renderTrends()}
                    {renderInsights()}
                </div>
            </>
        );
    }

    return (
        <div className="insights">
            <PageHeader title="Insights" actions={renderActions()} />
            {renderCharts()}
        </div>
    );
}

function InsightsSkeleton() {
    return (
        <div className="insights-charts insights-skeleton-container">
            <Skeleton.Input active className="header-skeleton" />
            <div className="trends-skeleton-wrapper">
                <Skeleton.Input active className="trends-skeleton" />
                <Skeleton.Input active className="trends-skeleton" />
                <Skeleton.Input active className="trends-skeleton" />
                <Skeleton.Input active className="trends-skeleton" />
                <Skeleton.Input active className="trends-skeleton" />
            </div>
            <div className="insights-skeleton-wrapper">
                <Skeleton.Input active className="insights-skeleton" />
                <Skeleton.Input active className="insights-skeleton" />
                <Skeleton.Input active className="insights-skeleton" />
            </div>
        </div>
    );
}
