import { ColumnsType } from 'antd/lib/table';
import { Moment } from 'moment-timezone';
import { createContext, useState } from 'react';
import { MdOutlineDescription } from 'react-icons/md';
import { ActionMenu, IDropdownOption } from '@gather/components';
import { IDroneItem } from '@gather/types';
import { usePersist } from '@gather/hooks';
import { LOCAL_STORAGE_KEYS } from '@gather/constants';

export interface IDroneData {
    selectedDrone?: IDroneItem;
    date?: { date: Moment[] | null; quickSelect?: number } | undefined;
    displayOptions: IDropdownOption[];
    warehouse?: IDropdownOption | null;
    setSelectedDrone: (drone: IDroneItem) => void;
    setDate: (date: { date: Moment[] | null; quickSelect?: number } | undefined) => void;
    setDisplayOptions: (displayOptions: IDropdownOption[]) => void;
    setWarehouse: (displayOptions: IDropdownOption | null) => void;
}

interface IProps {
    children?: React.ReactNode;
}

function renderAction(value: string, drone: IDroneItem, index: number) {
    return (
        <ActionMenu
            options={[
                {
                    onClick: () => {
                        console.log('clicked');
                    },
                    title: 'Drone Action',
                    icon: <MdOutlineDescription />,
                },
            ]}
        />
    );
}

export const droneColumns: ColumnsType<IDroneItem> = [
    {
        title: 'Drone',
        dataIndex: 'name',
        key: 'name',
        sorter: {
            multiple: 2,
        },
        ellipsis: true,
    },
    {
        title: 'Drone Status',
        dataIndex: 'status',
        key: 'status',
        sorter: {
            multiple: 2,
        },
        ellipsis: true,
    },
    {
        title: 'Next Flight In',
        dataIndex: 'nextFlight',
        key: 'nextFlight',
        ellipsis: true,
    },
    {
        title: 'Drone Battery',
        dataIndex: 'droneBattery',
        key: 'droneBattery',
        sorter: {
            multiple: 2,
        },
        ellipsis: true,
    },
    {
        title: 'Controller Battery',
        dataIndex: 'controllerBattery',
        key: 'controllerBattery',
        sorter: {
            multiple: 2,
        },
        ellipsis: true,
    },
    {
        title: 'Warehouse',
        dataIndex: 'warehouse',
        key: 'warehouse',
        sorter: {
            multiple: 2,
        },
        ellipsis: true,
    },
    {
        title: '',
        className: 'action-item-cell',
        dataIndex: 'action',
        key: 'action-item',
        render: renderAction,
    },
];

const droneData: IDroneData = {
    displayOptions: [],
    setSelectedDrone: (_: IDroneItem) => {},
    setDate: (_: { date: Moment[] | null; quickSelect?: number } | undefined) => {},
    setDisplayOptions: (_: IDropdownOption[]) => {},
    setWarehouse: (_: IDropdownOption | null) => {},
};

export const DroneContext = createContext<IDroneData>(droneData);

export function DroneProvider({ children }: IProps) {
    const [selectedDrone, setSelectedDrone] = useState<IDroneItem>();

    const [warehouse, setWarehouse] = usePersist<IDropdownOption | null>(LOCAL_STORAGE_KEYS.DRONE_WAREHOUSE_FILTER);
    const [date, setDate] = usePersist<{ date: Moment[] | null; quickSelect?: number } | undefined>(
        LOCAL_STORAGE_KEYS.DRONE_DATE_FILTER
    );

    const [displayOptions, setDisplayOptions] = usePersist<IDropdownOption[]>(
        LOCAL_STORAGE_KEYS.DRONE_DISPLAY_FILTER,
        droneColumns.map<IDropdownOption>((_inv, index) => ({
            label: `${_inv.title || ''}`,
            value: _inv.key,
        })) || []
    );

    return (
        <DroneContext.Provider
            value={{
                selectedDrone,
                setSelectedDrone,
                date,
                setDate,
                displayOptions,
                setDisplayOptions,
                warehouse,
                setWarehouse,
            }}
        >
            {children}
        </DroneContext.Provider>
    );
}
