import { useSearchParams } from 'react-router-dom';
import { LocationDetails as LocationDetailsContent } from '@gather/features';
import './styles.less';

export function LocationDetails() {
    const [searchParams] = useSearchParams();

    const missionId = +(searchParams.get('mission') || 0);
    const binId = +(searchParams.get('bin') || 0);
    const inventoryId = searchParams.get('inventory') || '';
    console.log({ inventoryId });
    return (
        <LocationDetailsContent
            isSlider={false}
            binInfo={{
                missionAppId: '',
                binId: binId,
                inventoryId: inventoryId,
                missionId: missionId,
                warehouseId: 0,
            }}
        />
    );
}
