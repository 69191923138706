import { createContext } from 'react';
import { IDropdownOption } from '@gather/components';
import { usePersist } from '@gather/hooks';
import { LOCAL_STORAGE_KEYS } from '@gather/constants';
import { ITenantInfo } from '@gather/types';

export interface IGlobalContext {
    warehouse: IDropdownOption;
    setWarehouse: (displayOptions: IDropdownOption) => void;
    tenant: ITenantInfo;
    setTenant: (tenant: ITenantInfo) => void;
}

interface IProps {
    children?: React.ReactNode;
}

const defaultWarehouse: IDropdownOption = {
    label: '',
    value: 0,
};

const defaultTenant: ITenantInfo = {
    offline: false,
    tenant: '',
    timeZone: '',
};

const globalContextData: IGlobalContext = {
    warehouse: defaultWarehouse,
    setWarehouse: (_: IDropdownOption) => {},
    tenant: defaultTenant,
    setTenant: (tenant: ITenantInfo) => {},
};

export const GlobalContext = createContext<IGlobalContext>(globalContextData);
// NOTE: USE GlobalContext only if the data is shared all over the application
// NOTE: DO NOT PUT DATA THAT NEEDS CONCURRENT UPDATES
export function GlobalContextProvider({ children }: IProps) {
    const [warehouse, setWarehouse] = usePersist<IDropdownOption>(
        LOCAL_STORAGE_KEYS.WAREHOUSE_GLOBAL,
        defaultWarehouse
    );
    const [tenant, setTenant] = usePersist<ITenantInfo>(LOCAL_STORAGE_KEYS.TENANT_INFO, defaultTenant);

    return (
        <GlobalContext.Provider
            value={{ tenant, setTenant, warehouse, setWarehouse }}
        >
            {children}
        </GlobalContext.Provider>
    );
}
