import { PageHeader } from '@gather/components';
import { Query } from '@gather/constants';
import { InventoryProvider } from '@gather/contexts';
import { InventoryFilter, InventoriesList } from '@gather/features';
import { ExceptionsLocationDetails } from './exceptions-location-details';
import './styles.less';

export function Exceptions() {
    function actions() {
        return [
            <InventoryFilter.AggregatedBy />,
            <InventoryFilter.Verification />,
            <InventoryFilter.Date />,
            <InventoryFilter.Display />,
        ];
    }
    return (
        <div className="exceptions-screen">
            <InventoryProvider>
                <PageHeader title="Exceptions" actions={actions()} />
                <InventoriesList exceptions={true} queryKey={Query.exceptions} />
                <ExceptionsLocationDetails />
            </InventoryProvider>
        </div>
    );
}
