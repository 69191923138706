import { useContext, useEffect, useState } from 'react';
import { IDropdownOption, MultiSelectBox, SelectBox, RangePicker } from '@gather/components';
import { GlobalContext, missionColumns, MissionContext } from '@gather/contexts';
import { MdOutlineSettings, MdOutlineFilterAlt } from 'react-icons/md';
import { Moment } from 'moment-timezone';
import { useQuery } from 'react-query';
import { Query } from '@gather/constants';
import { GlobalService } from '@gather/services';

function Display() {
    const { displayOptions, setDisplayOptions } = useContext(MissionContext);
    const [options, setOptions] = useState<IDropdownOption[]>(
        missionColumns.map<IDropdownOption>((_inv, index) => ({
            label: `${_inv.title || ''}`,
            value: _inv.key,
            hidden: _inv.key === 'action-item',
        }))
    );

    useEffect(() => {
        // write logic to implement wms columns options by using -> setOptions
    });

    function onChange(values: IDropdownOption[]) {
        setDisplayOptions(values);
    }

    return (
        <MultiSelectBox
            onChange={onChange}
            options={options}
            value={displayOptions}
            placeholder="Display"
            icon={<MdOutlineSettings />}
        ></MultiSelectBox>
    );
}

function Warehouse() {
    const [warehouses, setWarehouses] = useState<IDropdownOption[]>([]);
    const { warehouse, setWarehouse } = useContext(MissionContext);
    const GLOBAL_CONTEXT = useContext(GlobalContext);

    const { data, isLoading } = useQuery([Query.warehouses], () => fetchWarehouses(), {
        keepPreviousData: true,
        cacheTime: Infinity,
    });

    function getDefaultWarehouse(_warehouses: IDropdownOption[]) {
        const { tenant } = GLOBAL_CONTEXT.tenant;
        const defaultWarehouse =
            _warehouses.find(
                (_warehouse) =>
                    _warehouse.label.toLowerCase().replaceAll(' ', '') === tenant.toLowerCase().replaceAll('-', '')
            ) || _warehouses[0];

        return defaultWarehouse;
    }

    useEffect(() => {
        if (data) {
            const _warehouses: IDropdownOption[] = data.map(
                (_warehouse: any): IDropdownOption => ({
                    label: _warehouse.name,
                    code: _warehouse.code,
                    value: _warehouse.id,
                })
            );
            setWarehouses(_warehouses);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (warehouses && warehouses[0] && !warehouse) {
            // TO-DO: Set default warehouse with tenant name
            const defaultWarehouse = getDefaultWarehouse(warehouses);
            setWarehouse(defaultWarehouse);
            GLOBAL_CONTEXT.setWarehouse(defaultWarehouse);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouses, warehouse]);

    function fetchWarehouses() {
        return GlobalService.fetchWarehouses();
    }

    function onChange(value: IDropdownOption | null) {
        setWarehouse(value);
    }

    return (
        <SelectBox
            onChange={onChange}
            options={warehouses}
            value={warehouse || null}
            placeholder="Warehouse"
            isClearable={false}
            loading={isLoading}
            icon={<MdOutlineFilterAlt />}
        ></SelectBox>
    );
}

function Date() {
    const { date, setDate } = useContext(MissionContext);

    function onChange(values: { date: Moment[] | null; quickSelect?: number }) {
        setDate(values || undefined);
    }

    return <RangePicker placeholder={'Date'} value={date} onChange={onChange} />;
}

export const MissionFilter = {
    Display,
    Warehouse,
    Date,
};
