import { Table } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Query } from '@gather/constants';
import { IMMissionsParams } from '@gather/services';
import './styles.less';
import { missionColumns, MissionContext } from '@gather/contexts';
import { Pagination } from '@gather/components';
import { DownloadButton, TableSkeletonLoader } from '@gather/components';
import { ColumnsType } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { IMissionItem } from '@gather/types';
import { DateTime, downloadCumulativeCSV, filterColumns } from '@gather/utils';
import { useMissionsQuery } from '@gather/queries';

interface IProps {
    queryKey: Query;
}

export function MissionsList(props: IProps) {
    const { queryKey } = props;

    const { displayOptions, selectedMission, warehouse, date, setSelectedMission } = useContext(MissionContext);

    const [params, setParams] = useState<IMMissionsParams>({
        isCurrent: true,
        pagenumber: 1,
        pagesize: 30,
        sortby: 'createdOn',
        sortdirection: 'descend',
        warehouses: warehouse?.value || 0,
    });
    const [tableColumns, setTableColumns] = useState(missionColumns);

    const [missions, totalRecords, isLoading] = useMissionsQuery(queryKey, params);

    useEffect(() => {
        const _tableColumns: ColumnsType<IMissionItem> = filterColumns(
            missionColumns,
            displayOptions,
            params.sortby,
            params.sortdirection
        ) as ColumnsType<IMissionItem>;

        setTableColumns(_tableColumns);
    }, [displayOptions, params.sortby, params.sortdirection]);

    useEffect(() => {
        setParams({
            ...params,
            startDate: DateTime.format.date(date?.date?.[0]),
            endDate: DateTime.format.date(date?.date?.[1]),
            warehouses: warehouse?.value || 0,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, warehouse]);

    useEffect(() => {
        if (missions && missions.length > 0 && !selectedMission) {
            setSelectedMission(missions[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [missions]);

    function onPaginationChange(page: number, pageSize: number) {
        setParams({
            ...params,
            pagenumber: page,
            pagesize: pageSize,
        });
    }

    function onSortOrderChange(sortOrder: any) {
        const _sortOrder: SorterResult<IMissionItem> = sortOrder.length >= 1 ? sortOrder[1] : sortOrder;
        if (_sortOrder) {
            setParams({
                ...params,
                pagenumber: 1,
                sortby: (_sortOrder.field as string) || '',
                sortdirection: _sortOrder.order || 'ascend',
            });
        }
    }

    return (
        <div className="table-container-wrapper missions-list">
            <Table
                className="missions-list-table"
                columns={tableColumns}
                dataSource={missions}
                rowKey="id"
                rowSelection={{
                    selectedRowKeys: selectedMission?.id ? [selectedMission?.id] : undefined,
                }}
                onRow={(row) => {
                    return {
                        onClick: (event) => {
                            setSelectedMission(row);
                        },
                    };
                }}
                components={{
                    body: isLoading
                        ? {
                              wrapper: () => <TableSkeletonLoader />,
                          }
                        : undefined,
                }}
                onChange={(_: any, __: any, sorter: any) => {
                    onSortOrderChange(sorter);
                }}
                sortDirections={['ascend', 'descend', 'ascend']}
                pagination={false}
                showSorterTooltip={false}
            />
            <div className="table-footer">
                <DownloadCumulativeButton />
                <div className="flex-1"></div>
                <div className="pagination-wrapper">
                    <Pagination
                        disabled={isLoading}
                        currentCount={missions.length || 0}
                        current={params.pagenumber}
                        pageSize={params.pagesize}
                        total={totalRecords}
                        onChange={onPaginationChange}
                    />
                </div>
            </div>
        </div>
    );
}

function DownloadCumulativeButton() {
    const { selectedMission } = useContext(MissionContext);

    async function downloadCumulative() {
        return downloadCumulativeCSV({ mission: selectedMission, allMissions: true });
    }

    return <DownloadButton onDownload={downloadCumulative} postfixText="Cumulative Warehouse Inventory" />;
}
