import { API } from '@gather/constants';
import { AXIOS } from '@gather/middlewares';
import { IInventoriesParams, InventoryService } from './inventories.service';

export interface IGalaryParams {
    binId: number;
    missionId: number;
    appMissionId: string;
    warehouseId: number;
}

export interface IUpdateProductCodeParams {
    inventoryItemIds: string[];
    barcode: string;
    version: string;
}

export interface ITiHiParams {
    binId: number;
    missionId: number;
    binCode: string;
}

export interface IHistoryParams {
    itemIds?: string[];
    missionAppId?: string;
    binName?: string;
}

export interface ICreateProductParams {
    attributedImage: number;
    code: string;
    inventoryItemType: 'licensePlateNumber' | 'box' | 'sku' | 'lotNumber';
    locatedInBin: { id: string };
    missionId: string;
    recordType: 'userInput';
}

async function fetchProducts(params: IInventoriesParams) {
    return InventoryService.fetchInventories(params);
}

async function fetchGallery(params: IGalaryParams) {
    const { binId, missionId, appMissionId, warehouseId } = params;
    const response = await AXIOS.get(API.format(API.key.location.gallery, binId, missionId, appMissionId, warehouseId));
    return response.data;
}

async function fetchTiHiData(params: ITiHiParams) {
    const { binId, missionId, binCode } = params;
    const response = await AXIOS.get(API.format(API.key.location.tiHi, binId, missionId, binCode));
    return response.data;
}

async function updateProductCode(params: IUpdateProductCodeParams) {
    const response = await AXIOS.put(API.key.location.products.update.code, params);
    return response.data;
}

async function createProduct(params: ICreateProductParams) {
    const response = await AXIOS.post(API.key.location.products.create, params);
    return response.data;
}

async function fetchLocationHistory(params: IHistoryParams) {
    const { itemIds, missionAppId, binName } = params;
    if (params.itemIds) {
        const response = await AXIOS.post(API.key.location.history.item, {
            itemIds,
        });
        return response.data;
    }
    const response = await AXIOS.get(API.format(API.key.location.history.bin, binName, missionAppId));
    return response.data;
}

export const LocationService = Object.freeze({
    fetchProducts,
    fetchGallery,
    updateProductCode,
    fetchTiHiData,
    fetchLocationHistory,
    createProduct,
});
