import { PageHeader } from '@gather/components';
import { Button, Form, Input, Skeleton } from 'antd';
import { CACHE, queryClient, validateMessages } from '@gather/configs';
import { useMutation, useQuery } from 'react-query';
import { Query } from '@gather/constants';
import { ProfileService } from '@gather/services';
import { useEffect } from 'react';
import { IUser, IUserResponse, USER_FORM_CONTROLS } from '@gather/types';
import { LoadingOutlined } from '@ant-design/icons';

export function GeneralInfo() {
    return (
        <>
            <div className="general-info">
                <PageHeader
                    title="General Information"
                    subTitle="Edit any of your details below so your account is always up to date."
                />
                <GeneralInfoForm />
            </div>
        </>
    );
}

function GeneralInfoForm() {
    const [form] = Form.useForm<IUser>();
    // Query hook
    const { mutate, isLoading: isSubmitLoading } = useMutation<IUserResponse, unknown, IUser>(updateUserProfile, {
        onSuccess: () => {
            queryClient.setQueryData(Query.profile, form.getFieldsValue());
        },
    });

    const { data, isLoading } = useQuery<IUser>(Query.profile, () => fetchUserProfile(), {
        cacheTime: CACHE.time['8hrs'],
    });
    // End - Query hook

    function fetchUserProfile() {
        return ProfileService.getUserInfo();
    }

    function updateUserProfile(user: IUser) {
        return ProfileService.updateUserInfo(user);
    }

    const onFinish = (values: IUser) => {
        mutate(values);
    };

    useEffect(() => {
        if (form && data) {
            form.setFieldsValue(data);
        }
    }, [form, data]);

    function renderSubmitButton() {
        return (
            <Button disabled={isLoading || isSubmitLoading} className="submit-button" type="primary" htmlType="submit">
                {isSubmitLoading ? <LoadingOutlined /> : 'Submit'}
            </Button>
        );
    }

    function renderForm() {
        if (isLoading) return <GeneralInfoSkeleton />;
        return (
            <Form
                autoComplete="off"
                form={form}
                name="nest-messages"
                onFinish={onFinish}
                className="user-info-form"
                validateMessages={validateMessages}
                colon={false}
                labelAlign="left"
            >
                <Form.Item name={USER_FORM_CONTROLS.firstName} label="First Name" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={USER_FORM_CONTROLS.lastName} label="Last Name" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={USER_FORM_CONTROLS.title} label="Title">
                    <Input />
                </Form.Item>
                <Form.Item name={USER_FORM_CONTROLS.email} label="Email" rules={[{ type: 'email' }]}>
                    <Input disabled />
                </Form.Item>
                <Form.Item name={USER_FORM_CONTROLS.accecibility} label="Accecibility">
                    <Input disabled />
                </Form.Item>
                <Form.Item className="submit-button-wrapper">{renderSubmitButton()}</Form.Item>
            </Form>
        );
    }
    return <div className="user-info-form-wrapper">{renderForm()}</div>;
}

function GeneralInfoSkeleton() {
    function renderFormItem() {
        return (
            <div className="flex flex-row">
                <Skeleton.Input className="skeleton-input-label" active={true} />
                <Skeleton.Input active={true} />
            </div>
        );
    }
    return (
        <div className="user-info-form form-skeleton">
            {renderFormItem()}
            {renderFormItem()}
            {renderFormItem()}
            {renderFormItem()}
            {renderFormItem()}
            {renderFormItem()}
            <Skeleton.Input active={true} className="submit-button" />
        </div>
    );
}
