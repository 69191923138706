export const LOCAL_STORAGE_KEYS = {
    PERSISTANT_DATA: 'LOCAL_STORAGE_KEYS/PERSIST_USER_SELECTIONS',

    TENANT_INFO: 'LOCAL_STORAGE_KEYS/GOBAL_CONTEXT/TENANT_INFO',

    DRAWER: 'APP/DRAWER',

    WAREHOUSE_GLOBAL: 'LOCAL_STORAGE_KEYS/GOBAL_CONTEXT/WAREHOUSE',
    SELECTED_WAREHOUSE_GLOBAL: 'LOCAL_STORAGE_KEYS/GOBAL_CONTEXT/SELECTED_WAREHOUSE',

    MISSION_WAREHOUSE_FILTER: 'LOCAL_STORAGE_KEYS/MISSION_WAREHOUSE_FILTER',
    MISSION_DATE_FILTER: 'LOCAL_STORAGE_KEYS/MISSION_DATE_FILTER',
    MISSION_DISPLAY_FILTER: 'LOCAL_STORAGE_KEYS/MISSION_DISPLAY_FILTER',

    FACILITIES: 'INSIGHTS/FACILITIES',

    INVENTORY_AGGREGATE_FILTER: 'LOCAL_STORAGE_KEYS/INVENTORY_AGGREGATE_FILTER',
    INVENTORY_DATE_FILTER: 'LOCAL_STORAGE_KEYS/INVENTORY_DATE_FILTER',
    INVENTORY_VERIFICATION_FILTER: 'LOCAL_STORAGE_KEYS/INVENTORY_VERIFICATION_FILTER',
    INVENTORY_DISPLAY_FILTER: 'LOCAL_STORAGE_KEYS/INVENTORY_DISPLAY_FILTER',

    DRONE_WAREHOUSE_FILTER: 'LOCAL_STORAGE_KEYS/DRONE_WAREHOUSE_FILTER',
    DRONE_DATE_FILTER: 'LOCAL_STORAGE_KEYS/DRONE_DATE_FILTER',
    DRONE_DISPLAY_FILTER: 'LOCAL_STORAGE_KEYS/DRONE_DISPLAY_FILTER',

    LOCATION_DISPLAY_FILTER: 'LOCAL_STORAGE_KEYS/LOCATION_DISPLAY_FILTER',
    LOCATION_OVERLAY_FILTER: 'LOCAL_STORAGE_KEYS/LOCATION_OVERLAY_FILTER',
};
