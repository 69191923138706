import { useContext, useEffect, useState } from 'react';
import { LocationContext } from '@gather/contexts';

export function ProductsDetailsSection() {
    const { selectedProduct } = useContext(LocationContext);

    const [showEmpty, setShowEmpty] = useState(false);
    const [productDetails, setProductDetails] = useState<any[]>([]);

    useEffect(() => {
        const wmsData = selectedProduct && {
            Product: selectedProduct.productName,
            ...selectedProduct.wms_data,
        };
        if (wmsData) {
            const { productDetails: _productDetails, wmsDataCount } = getFormattedWMSData(wmsData);
            // adding values to fill the empty column
            if (wmsDataCount % 2 === 1) {
                setShowEmpty(_productDetails.length <= 1);
            } else if (_productDetails.length <= 1) {
                setShowEmpty(true);
            } else {
                setShowEmpty(false);
            }
            setProductDetails(_productDetails);
        } else {
            setProductDetails([]);
            setShowEmpty(true);
        }
    }, [selectedProduct]);

    function renderProductDetails() {
        return (
            <div className="product-details-content">
                {productDetails.map((item, index) => {
                    const { key, value } = item;
                    return (
                        <div className="product-details-item" key={index}>
                            <span className="data-label">{key}</span>
                            <span className="data-value" style={getValueStyles(key, value)}>
                                {(value || value === 0) && value !== 'nan' ? value : '-'}
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    }
    return (
        <div className="product-details-section">
            <div className="product-details-header">Product Details</div>
            {renderProductDetails()}
            {showEmpty && (
                <div className="product-details-empty-tip">
                    Other Details are not available in the WMS file used in this mission.
                </div>
            )}
        </div>
    );
}

function getFormattedWMSData(wmsData: any) {
    const dataArray = Object.keys(wmsData).map((key) => [key, wmsData[key]]);
    const productDetails = dataArray.reduce((acc, _data) => {
        acc.push({
            key: _data[0],
            value: _data[1],
        });
        return acc;
    }, []);
    return { productDetails, wmsDataCount: dataArray.length };
}

function getValueStyles(key: string, value: string) {
    if (key) {
        switch (key.toLowerCase()) {
            case 'modeling': {
                return {
                    color: value && value.toLowerCase() === 'completed' ? '#14E2AC' : '#000000',
                };
            }
            default:
                return {};
        }
    }
    return {};
}
