import { ActionMenu } from '@gather/components';
import { HistoryContext } from '@gather/contexts';
import { ILocationProductItem } from '@gather/types';
import { deleteInventory } from '@gather/utils/inventory.utils';
import { useContext } from 'react';
import { MdOutlineHistory, MdOutlineDelete } from 'react-icons/md';

interface IProps {
    product: ILocationProductItem;
}
export function ProductListAction(props: IProps) {
    const { showHistory } = useContext(HistoryContext);
    const { product } = props;

    const itemId = product.items?.[0]?.itemId;

    return (
        <ActionMenu
            options={[
                {
                    onClick: () => {
                        itemId && showHistory({ itemIds: [itemId] });
                    },
                    title: 'View History',
                    icon: <MdOutlineHistory />,
                },
                {
                    onClick: () => {
                        itemId && deleteInventory(itemId);
                    },
                    title: 'Delete Product',
                    icon: <MdOutlineDelete />,
                },
            ]}
        />
    );
}
