import { CSSObjectWithLabel, Theme } from 'react-select';

export const REACT_SELECT_CONFIGS = Object.freeze({
    styles: {
        control: (base: CSSObjectWithLabel) => ({
            ...base,
            border: 1,
            minWidth: '150px',
        }),
    },
    theme: (theme: Theme) => ({
        ...theme,
        borderRadius: 2,
        spacing: {
            ...theme.spacing,
            baseUnit: 3,
        },
        colors: {
            ...theme.colors,
            text: 'orangered',
            primary: '#48E4B7',
            primary25: '#d2f8ed',
            primary50: '#a4f2db',
            primary75: '#76ebc9',
        },
    }),
});
