import { useState } from 'react';
import {
    MdKeyboardArrowLeft,
    MdKeyboardArrowRight,
    MdKeyboardArrowUp,
    MdKeyboardArrowDown,
    MdOutlineFilterAlt,
} from 'react-icons/md';
import { Link } from 'react-router-dom';
import { IDropdownOption, SelectBox } from '@gather/components';
import { message } from 'antd';

export function BinNavigation() {
    function onClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        event.preventDefault();
        message.info("This feature is under development, stay tuned!")
    }
    return (
        <div className="bin-navigation-wrapper">
            <NavigationDropdown />
            <AisleDropdown />
            <Link className="bin-navigation-button" to={'#'} onClick={onClick}>
                <MdKeyboardArrowLeft size={25} />
            </Link>
            <Link className="bin-navigation-button" to={'#'} onClick={onClick}>
                <MdKeyboardArrowRight size={25} />
            </Link>
            <Link className="bin-navigation-button" to={'#'} onClick={onClick}>
                <MdKeyboardArrowUp size={25} />
            </Link>
            <Link className="bin-navigation-button" to={'#'} onClick={onClick}>
                <MdKeyboardArrowDown size={25} />
            </Link>
        </div>
    );
}

const navigationOptions: IDropdownOption[] = [
    {
        value: 1,
        label: 'Warehouse',
    },
    {
        value: 2,
        label: 'Mission',
    },
];

const aisleOptions: IDropdownOption[] = [
    {
        value: 1,
        label: '23A',
    },
    {
        value: 2,
        label: '23B',
    },
    {
        value: 3,
        label: '23C',
    },
    {
        value: 4,
        label: '23D',
    },
];

function NavigationDropdown() {
    const [value, setValue] = useState<IDropdownOption | null>(null);
    function onChange(_value: IDropdownOption | null) {
        setValue(_value);
    }

    return (
        <SelectBox
            onChange={onChange}
            options={navigationOptions}
            value={value || null}
            placeholder="Navigation"
            isClearable={true}
            icon={<MdOutlineFilterAlt />}
        ></SelectBox>
    );
}
function AisleDropdown() {
    const [value, setValue] = useState<IDropdownOption | null>(null);
    function onChange(_value: IDropdownOption | null) {
        setValue(_value);
    }

    return (
        <SelectBox
            onChange={onChange}
            options={aisleOptions}
            value={value || null}
            placeholder="Aisle"
            isClearable={true}
            icon={<MdOutlineFilterAlt />}
        ></SelectBox>
    );
}
