import { LOCAL_STORAGE_KEYS } from '@gather/constants';
import { IPersistantData } from '@gather/types';
import { useState, useEffect } from 'react';

// fetch data from local strage - initial loading
function getInitialState<T>(key: string, defaultValue?: T) {
    const rawData = localStorage.getItem(LOCAL_STORAGE_KEYS.PERSISTANT_DATA);
    if (rawData) {
        const initialData = {
            ...JSON.parse(rawData || ''),
        };
        if (!initialData[key]) {
            initialData[key] = defaultValue;
        }
        return initialData;
    }
    return {
        [key]: defaultValue,
    };
}

export function usePersist<T>(key: string, defaultValue?: T): [T, (data: T) => void] {
    const [persistantData, setPersistantData] = useState<IPersistantData>(getInitialState(key, defaultValue));

    useEffect(() => {
        if (persistantData) {
            localStorage.setItem(LOCAL_STORAGE_KEYS.PERSISTANT_DATA, JSON.stringify(persistantData));
        }
        // set data to local storage - sync data to local storage on every state updates
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [persistantData]);

    function setPersistantRecords(data: T) {
        if(key === LOCAL_STORAGE_KEYS.INVENTORY_AGGREGATE_FILTER) {
            console.log({key, data});
        }
        setPersistantData({
            ...persistantData,
            [key]: data,
        });
    }
    return [persistantData[key], setPersistantRecords];
}
