import './styles.less';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { MdClose } from 'react-icons/md';
import './styles.less';
import { Button, Modal } from 'antd';

interface ISlideModalProps {
    show: boolean;
    onClose: () => void;
    children: ReactNode;
    className?: string;
    footer?: ReactNode;
    prefixActions?: ReactNode[];
    postfixActions?: ReactNode[];
    title?: string;
}

export function SlideModal(props: ISlideModalProps) {
    const { show, onClose, children, prefixActions, postfixActions, title, footer, className } = props;

    const modalMaskRef = useRef<HTMLDivElement | null>();
    const containerRef = useRef<HTMLDivElement | null>();

    useEffect(() => {
        if (modalMaskRef.current && containerRef.current) {
            if (show) {
                modalMaskRef.current.classList.add('show-modal');
                containerRef.current.classList.add('show-modal');
            } else {
                modalMaskRef.current.classList.remove('show-modal');
                containerRef.current.classList.remove('show-modal');
            }
        }
    }, [show]);

    function renderActions(actions?: ReactNode[], key: 'prefix' | 'postfix' = 'prefix') {
        if (actions) {
            return (
                <div className={`${key}-header-actions`}>
                    {actions.map((action, i) => (
                        <div key={`${key}-action-item-${i}`} className="action-item">
                            {action}
                        </div>
                    ))}
                </div>
            );
        }
        return null;
    }

    return (
        <>
            <div ref={(_ref) => (modalMaskRef.current = _ref)} className="slide-modal-mask" onClick={onClose}></div>
            <div ref={(_ref) => (containerRef.current = _ref)} className={`slide-modal ${className ? className : ''}`}>
                <div className="modal-content">
                    <div className="modal-header">
                        {renderActions(prefixActions)}
                        <div className="modal-title-text flex-1">{title}</div>
                        {renderActions(postfixActions)}
                        <div className="modal-close-icon" onClick={onClose}>
                            <MdClose />
                        </div>
                    </div>
                    {children}
                    <div className="modal-footer">{footer}</div>
                </div>
            </div>
        </>
    );
}

export function ConfirmationModal() {
    const [showModal, setShowModal] = useState(false);

    function renderContent() {
        return (
            <div className="confirmation-modal-content">
                <div>Are you really sure you want to delete the mission? This cannot be undone!</div>
                <div className="confiramtion-modal-footer">
                    <Button type="primary">Cancel</Button>
                    <Button type="primary">Delete</Button>
                </div>
            </div>
        );
    }

    return (
        <Modal
            title="Vertically centered modal dialog"
            centered
            className="confirmation-modal"
            visible={showModal}
            onOk={() => setShowModal(false)}
            onCancel={() => setShowModal(false)}
            modalRender={renderContent}
        />
    );
}
