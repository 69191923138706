import { QueryClient } from 'react-query';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
            cacheTime: Infinity,
        },
    },
});
const localStoragePersistor = createWebStoragePersistor({
    storage: window.localStorage,
    key: 'persistant-records',
});

persistQueryClient({
    queryClient,
    persistor: localStoragePersistor,
    /** A unique string that can be used to forcefully
     * invalidate existing caches if they do not share the same buster string */
    buster: 'gather-persistant-storage-v1',
});
