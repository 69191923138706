import { useEffect, useState } from 'react';

export function useDebouncer<T>(
    defaultValue: T,
    callback: (value: T) => void,
    interval: number = 400
): [T, (value: T) => void] {
    const [value, setValue] = useState<T>(defaultValue);
    useEffect(() => {
        const timeout = setTimeout(() => {
            callback(value);
        }, interval);
        return () => {
            clearTimeout(timeout);
        };
    }, [value, interval, callback]);
    return [value, setValue];
}
