import { ColumnsType } from 'antd/lib/table';
import { Moment } from 'moment-timezone';
import { createContext, useEffect, useState } from 'react';
import { ActionMenu, IDropdownOption, ProductName, VerificationLabel } from '@gather/components';
import { ProductCode, ProductListAction } from '@gather/features';
import { IInventoriesParams } from '@gather/services';
import { ILocationProductItem, IOverlayItems } from '@gather/types';
import { usePersist } from '@gather/hooks';
import { LOCAL_STORAGE_KEYS } from '@gather/constants';
import { ProductHistoryProvider } from './product-history.context';

export interface ILocationInfo {
    missionId?: number;
    warehouseId?: number;
    binId?: number;
    itemId?: string;
    appMissionId?: string;
}

export interface IMissionInfo {
    id?: number;
    name?: string;
    appMissionId?: string;
    warehouseId?: number;
    loading?: boolean;
    start?: Moment;
    end?: Moment;
}

export interface ILocationData {
    locationInfo: ILocationInfo;
    selectedProduct?: ILocationProductItem;
    showOverlay: boolean;
    displayOptions: IDropdownOption[];
    params: IInventoriesParams;
    missionInfo: IMissionInfo;
    overlayItems: IOverlayItems;
    setLocationInfo: (locationInfo: ILocationInfo) => void;
    setSelectedProduct: (mission: ILocationProductItem) => void;
    setShowOverlay: (overlay: boolean) => void;
    setDisplayOptions: (displayOptions: IDropdownOption[]) => void;
    setParams: (_: IInventoriesParams) => void;
    setMissionInfo: (missionInfo: IMissionInfo) => void;
    setOverlayItems: (overlayItems: IOverlayItems) => void;
}

interface IProps {
    children?: React.ReactNode;
    locationInfo: ILocationInfo;
    showAction?: boolean;
}

function renderProductCode(value: string, product: ILocationProductItem, index: number) {
    return <ProductCode code={value} product={product} />;
}

function renderAction(value: string, product: ILocationProductItem, index: number) {
    return <ProductListAction product={product} />;
}

function renderProductName(value: string, record: ILocationProductItem, index: number) {
    return (
        <ProductName
            name={value}
            valid={record.verifiedRecord}
            exception={record.exceptionType?.toLocaleLowerCase() || record.recordType?.toLocaleLowerCase()}
        />
    );
}

function renderQuantity(value: string, record: ILocationProductItem, index: number) {
    return <div className="text-right">{value}</div>;
}

function renderVerification(value: string, record: ILocationProductItem, index: number) {
    return <VerificationLabel inventory={record} />;
}

export const productListColumns: ColumnsType<ILocationProductItem> = [
    {
        title: 'Product',
        dataIndex: 'productName',
        key: 'productName',
        render: renderProductName,
        sorter: {
            multiple: 2,
        },
        ellipsis: true,
    },
    {
        title: 'Code',
        dataIndex: 'productCode',
        key: 'productCode',
        render: renderProductCode,
        sorter: {
            multiple: 2,
        },
    },
    {
        title: 'Qty',
        dataIndex: 'quantity',
        key: 'quantity',
        className: 'quantity-cell quantity-min',
        render: renderQuantity,
        sorter: {
            multiple: 2,
        },
        ellipsis: true,
    },
    {
        title: 'Verification',
        dataIndex: 'verification',
        key: 'verification',
        render: renderVerification,
        className: 'verification-cell',
    },
    {
        title: '',
        dataIndex: 'action',
        className: 'action-item-cell',
        render: renderAction,
    },
];

const locationData: ILocationData = {
    locationInfo: {},
    displayOptions: [],
    showOverlay: true,
    params: {
        isCurrent: true,
        pagenumber: 1,
        pagesize: 50,
        sortby: 'date',
        sortdirection: 'descend',
        aggregateBy: 'code',
        missionId: 0,
        warehouses: 0,
        search: '',
    },
    missionInfo: {},
    overlayItems: {},
    setSelectedProduct: (_: ILocationProductItem) => {},
    setShowOverlay: (_: boolean) => {},
    setDisplayOptions: (_: IDropdownOption[]) => {},
    setLocationInfo: (_: ILocationInfo) => {},
    setParams: (_: IInventoriesParams) => {},
    setMissionInfo: (_: ILocationInfo) => {},
    setOverlayItems: (_: IOverlayItems) => {},
};

export const LocationContext = createContext<ILocationData>(locationData);

export function LocationProvider(props: IProps) {
    const { children, locationInfo: _locationInfo, showAction } = props;

    const [locationInfo, setLocationInfo] = useState<ILocationInfo>(_locationInfo);

    const [selectedProduct, setSelectedProduct] = useState<ILocationProductItem>();

    const [showOverlay, setShowOverlay] = usePersist<boolean>(LOCAL_STORAGE_KEYS.LOCATION_OVERLAY_FILTER, true);

    const [displayOptions, setDisplayOptions] = useState<IDropdownOption[]>(
        // LOCAL_STORAGE_KEYS.LOCATION_DISPLAY_FILTER,
        productListColumns
            .filter((opt: any) => (opt.dataIndex === 'action' ? showAction : true))
            .map<IDropdownOption>((_inv, index) => ({
                label: `${_inv.title || ''}`,
                value: _inv.key,
            })) || []
    );

    const [params, setParams] = useState<IInventoriesParams>({
        isCurrent: true,
        pagenumber: 1,
        pagesize: 50,
        sortby: 'createdOn',
        sortdirection: 'descend',
        aggregateBy: 'code',
        binId: locationInfo.binId || 0,
        missionId: locationInfo.missionId || 0,
        warehouses: locationInfo.warehouseId || 0,
        search: '',
    });

    const [missionInfo, setMissionInfo] = useState<IMissionInfo>({});

    const [overlayItems, setOverlayItems] = useState<IOverlayItems>({});

    useEffect(() => {
        setSelectedProduct(undefined);
        setLocationInfo(_locationInfo);
        if (_locationInfo.binId) {
            setParams({
                ...params,
                binId: _locationInfo.binId,
                missionId: _locationInfo.missionId || 0,
                warehouses: locationInfo.warehouseId || 0,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_locationInfo]);

    return (
        <LocationContext.Provider
            value={{
                locationInfo,
                setLocationInfo,
                selectedProduct,
                setSelectedProduct,
                showOverlay,
                setShowOverlay,
                displayOptions,
                setDisplayOptions,
                params,
                setParams,
                missionInfo,
                setMissionInfo,
                overlayItems,
                setOverlayItems,
            }}
        >
            <ProductHistoryProvider>{children}</ProductHistoryProvider>
        </LocationContext.Provider>
    );
}
